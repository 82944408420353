<template>
  <article class="privacy-policy-wrapper">
    <h3 class="h3 privacy-policy-content-center">Data Protection Notice</h3>

    <section class="privacy-policy-content-section">
      <h4 class="h4">We Are</h4>
      <p class="p">
        We are Talkie Limited, Phoenix Square, 4 Midland Street, Leicester, LE1
        1TG, United Kingdom. Our email address is
        <a href="mailto:privacy@talkietheapp.com" target="_blank">
          privacy@talkietheapp.com
        </a>
        . We provide the Talkie App, which is a language learning mobile
        application, specialising in developing speaking and listening skills.
      </p>
    </section>

    <section class="privacy-policy-content-section">
      <h4 class="h4">The type of Personal Data we collect</h4>
      <p class="p">We collect and use the following information:</p>
      <ul class="privacy-policy-content-list-disc">
        <li>
          <p class="p">
            Personal details: Name, Date of Birth, Email Address, School and
            Educational Institution.
          </p>
        </li>
        <li>
          <p class="p">Voice Recordings</p>
        </li>
      </ul>
    </section>

    <section class="privacy-policy-content-section">
      <h4 class="h4">How we collect Personal Data and why we have it</h4>
      <p class="p">
        Most of the personal data we process is provided to us directly by you
        for one of the following reasons:
      </p>
      <ul class="privacy-policy-content-list-disc">
        <li>
          <p class="p">To register in the Talkie App</p>
        </li>
        <li>
          <p class="p">To create a user profile in the Talkie App</p>
        </li>
        <li>
          <p class="p">
            To gather and organise your work so that your teacher or instructor
            has access to your work, can assess your work and can contact you
            using the in-App messaging system
          </p>
        </li>
        <li>
          <p class="p">
            To record and keep your work, output, progress and your voice
            recordings for ongoing use.
          </p>
        </li>
      </ul>
      <p class="p">
        We use the personal data that you give to us to create a user profile
        for you in the Talkie App, allow you to save your work and record your
        speaking exercises. The personal data is used to manage your progress,
        provide access to your teacher or instructor, give you access to quizzes
        and assessments and provide you with scores.
      </p>

      <p class="p">
        We may share this information with your teacher or instructor who you
        have agreed can have access to your work and voice recordings. To
        receive your information, your teacher or instructor must be registered
        in the Talkie App.
      </p>

      <p class="p">
        Under the General Data Protection Regulation (GDPR) and the UK Data
        Protection Act 2018, the lawful reasons we rely on for processing your
        personal data are
      </p>

      <ol class="privacy-policy-content-list-numbered">
        <li>
          <p class="p">
            Your consent. You can remove your consent at any time. You can do
            this by deleting your profile from Talkie App and removing Talkie
            App from your phone, mobile device or computer.
          </p>
        </li>
        <li>
          <p class="p">
            We have a contractual obligation. By registering with Talkie App,
            all users agree to Talkie Ltd’s Terms and Conditions of Use.
          </p>
        </li>
        <li>
          <p class="p">
            We have a legitimate interest in providing online language learning
            software.
          </p>
        </li>
      </ol>
    </section>

    <section class="privacy-policy-content-section">
      <h4 class="h4">How we store your Personal Data</h4>
      <p class="p">
        Your personal data are securely stored electronically in secure computer
        services based in the United Kingdom and the European Union.
      </p>
      <p class="p">
        We keep your profile information, work, scores and voice recordings for
        as long as you have an active user profile or your teacher or instructor
        requires access to your information, with your consent. When you delete
        or deactivate your profile or the media you have created, we will put
        your personal data beyond use and access. From time to time we may
        delete user profiles and media that have been abandoned, deleted or
        deactivated by users.
      </p>
      <p class="p">
        We do not transfer your personal data outside the European Economic
        Area. Where we are required to do so from time to time by one of our
        service providers such as our payment services provider or email
        marketing services provider, we will ensure that we comply with the GDPR
        and UK Data Protection Act 2018.
      </p>
    </section>

    <section class="privacy-policy-content-section">
      <h4 class="h4">Our Website and Cookies</h4>
      <p class="p">
        When users visit www.talkietheapp.com we collect standard internet log
        information and visitor behaviour patterns. We do this to find out the
        number of visitors and how they react to various parts of the website
        and App. We collect this information in a way which does not identify
        anyone. We do not try to find out the specific identities of those
        visiting our website.
      </p>
      <p class="p">
        Cookies are text files holding small amounts of information which are
        downloaded to your computer or mobile device when you visit a website.
        Cookies are used to recognise your computer for future visits, help
        websites to work and stay secure.
      </p>
      <p class="p">
        Users can choose whether to accept cookies. You can adjust your cookie
        preferences by changing your browser settings so that cookies from this
        website cannot be placed on your computer or mobile device. Follow the
        instructions provided by your browser. This tool is often found in the
        “Help”, “Tools” or “Edit” function. Please be aware that if you choose
        to refuse cookies you may not be able to benefit from the full
        functionality and security features of our website. For more information
        about cookies visit
        <a href="www.allaboutcookies.org">www.allaboutcookies.org</a> and
        <a href="www.youronlinechoices.eu">www.youronlinechoices.eu</a>.
      </p>
    </section>

    <section class="privacy-policy-content-section">
      <h4 class="h4">Your Data Protection Rights</h4>
      <p class="p">Under data protection law, you have rights including:</p>

      <p class="p">
        <span class="privacy-policy-content-section-highlight">
          Your right of access
        </span>
        - You can ask us for copies of your personal data.
      </p>
      <p class="p">
        <span class="privacy-policy-content-section-highlight">
          Your right to rectification
        </span>
        - You can ask us to rectify (correct) personal data you think is
        inaccurate. You also have the right to ask us to complete information
        you think is incomplete. You can also easily update your own information
        by editing your profile in the Talkie App.
      </p>
      <p class="p">
        <span class="privacy-policy-content-section-highlight">
          Your right to erasure
        </span>
        – You can ask us to erase your personal data in certain circumstances.
      </p>
      <p class="p">
        <span class="privacy-policy-content-section-highlight">
          Your right to restrict data processing
        </span>
        - You can ask us to restrict the processing of your personal data in
        certain circumstances.
      </p>
      <p class="p">
        <span class="privacy-policy-content-section-highlight">
          Your right to object to processing
        </span>
        - You can object to the processing of your personal data in certain
        circumstances.
      </p>
      <p class="p">
        <span class="privacy-policy-content-section-highlight">
          Your right to data portability
        </span>
        – You can ask that we transfer the personal data you gave us to another
        organisation, or to you, in certain circumstances.
      </p>

      <p class="p">
        You are not required to pay any charge for exercising your rights. If
        you make a request in writing to us, we have one month to respond to
        you.
      </p>
    </section>

    <section class="privacy-policy-content-section">
      <h4 class="h4">How to Contact Us and How to Complain</h4>
      <p class="p">
        If you have any concerns about our use of your personal data, you can
        make a complaint to us at privacy@talkietheapp.com. You can write to
        Talkie App Data Protection, Talkie Limited, Phoenix Square, 4 Midland
        Street, Leicester, LE1 1TG, United Kingdom.
      </p>
      <p class="p">
        You can also complain to the ICO if you are unhappy with how we have
        used your personal data.
      </p>
      <p class="p">Information Commissioner’s Office (ICO)</p>
      <p class="p">
        Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF, UK
      </p>
      <p class="p">ICO’s Helpline Number: 0303 123 1113</p>
      <p class="p">
        ICO website: <a href="https://www.ico.org.uk">https://www.ico.org.uk</a>
      </p>
    </section>

    <section class="privacy-policy-content-section">
      <h4 class="h4">Changes to this Data Protection Notice</h4>
      <p class="p">
        This Data Protection Notice is kept under review and can be updated at
        any time. This Notice was last updated in January 2022 (Version 1).
      </p>
    </section>
  </article>
</template>

<script>
export default {
  name: "ServicesPrivacyPolicy",
};
</script>

<style scoped>
.privacy-policy-wrapper,
.privacy-policy-content-section {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
}
.privacy-policy-wrapper {
  background-color: var(--t-white);
}
.privacy-policy-content-section {
  line-height: 1.5;
}
.privacy-policy-content-center {
  margin: auto;
}
.privacy-policy-content-list-disc,
.privacy-policy-content-list-numbered {
  padding: var(--t-space-12) 0;
}
.privacy-policy-content-list-disc {
  list-style-type: disc;
}
.privacy-policy-content-list-numbered {
  list-style-type: lower-alpha;
}
.privacy-policy-content-section-highlight {
  font-family: var(--t-ff-bold);
  color: var(--c-black);
}

/* Responsive variants */
@media (max-width: 599px) {
  .privacy-policy-wrapper {
    max-width: 100%;
    gap: var(--t-space-12);
    padding: var(--t-space-32);
    margin-top: var(--t-space-50);
    margin-bottom: var(--t-space-50);
    border-radius: var(--t-br-medium);
  }
  .privacy-policy-content-section {
    gap: var(--t-space-8);
  }
}
@media (min-width: 600px) {
  .privacy-policy-wrapper {
    max-width: 65%;
    gap: var(--t-space-36);
    padding: var(--t-space-48);
    margin-top: var(--t-space-70);
    margin-bottom: var(--t-space-70);
    border-radius: var(--t-br-medium);
  }
  .privacy-policy-content-section {
    gap: var(--t-space-16);
  }
}
@media (min-width: 1200px) {
  .privacy-policy-wrapper {
    max-width: 90%;
    padding: var(--t-space-48);
    border-radius: var(--t-br-large);
  }
  .privacy-policy-content-section {
    gap: var(--t-space-20);
  }
}
</style>
