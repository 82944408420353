<template>
  <svg
    stroke="currentColor"
    fill="currentColor"
    viewBox="0 0 1024 1024"
    height="30"
    width="30"
    :class="[customClass.toString()]"
  >
    <path
      d="M854.6 288.7c6 6 9.4 14.1 9.4 22.6V928c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32h424.7c8.5 0 16.7 3.4 22.7 9.4l215.2 215.3zM790.2 326L602 137.8V326h188.2z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "IconFile",
  components: {},
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>
