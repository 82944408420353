<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 500 500"
    fill="#000000"
    :class="[customClass.toString()]"
  >
    <path
      id="XMLID_23_"
      d="M250.5,500c-5.8,0-11.6,0-17.5,0c-39.5,0-40.2-0.6-49.4-40c-8-34.3-53.7-58.2-87.3-45.7
	c-28,10.4-35.3,7.8-50.4-18.3c-8.3-14.3-15.3-29.5-24.8-43.1c-14.8-21.3-7.9-36.8,9.7-51.5c28.9-24.1,28.8-77.9-0.2-102.2
	c-17.7-14.9-24.1-30.5-9.4-51.6c10.4-14.9,19.1-31.1,27.3-47.3C58.4,80.9,72.4,77.5,92,84.6c41.5,15,83.4-8,93.1-51.5
	c5.1-23,15.4-35.3,40.8-32.7c17.3,1.8,35,1.3,52.3,0.1c20.5-1.5,34.4,7.8,34.5,26.9c0.2,42.5,37.4,48,62,65.6
	c4.5,3.2,15.7-1.6,23.2-4.4c36.1-13.2,39.4-12.2,59.2,21.7c7.1,12.2,13,25.2,21.2,36.5c15.9,21.9,8.8,38-10,53.4
	c-27.5,22.3-27.4,78.3,0.2,100.7c18.9,15.4,25.3,31.5,9.7,53.4c-9.6,13.4-17.2,28.4-24.5,43.2c-11,22.1-26.3,26.8-49.1,17.5
	c-34.3-14-80,10.9-88.5,46.6C306.9,500,306.9,500,250.5,500z M250.8,350.3C305,350,350,304.7,350,250.2c0-54.5-45.1-99.7-99.5-99.8
	c-55.9-0.1-100.8,45.2-100.4,101.3C150.5,305.9,196.1,350.5,250.8,350.3z"
    />
  </svg>
</template>

<script>
export default {
  name: "IconSetting",
  components: {},
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>
