<template>
  <svg
    width="49"
    height="54"
    viewBox="0 0 49 54"
    :class="[customClass.toString()]"
  >
    <path
      d="M13.344 42.625h13.978c.569 0 1.039-.186 1.41-.558.373-.372.559-.842.559-1.41 0-.57-.186-1.04-.558-1.412-.372-.372-.842-.557-1.411-.557H13.344c-.569 0-1.04.185-1.411.557-.372.372-.558.843-.558 1.411 0 .569.186 1.04.558 1.411.372.372.842.558 1.41.558Zm0-11.156h22.312c.569 0 1.04-.186 1.411-.558.372-.372.558-.842.558-1.411 0-.569-.186-1.04-.558-1.41-.372-.373-.842-.559-1.41-.559H13.343c-.569 0-1.04.186-1.411.558-.372.372-.558.842-.558 1.411 0 .569.186 1.04.558 1.41.372.373.842.559 1.41.559Zm0-11.157h22.312c.569 0 1.04-.185 1.411-.557.372-.372.558-.842.558-1.411 0-.569-.186-1.04-.558-1.411-.372-.372-.842-.558-1.41-.558H13.343c-.569 0-1.04.186-1.411.558-.372.372-.558.842-.558 1.41 0 .57.186 1.04.558 1.412.372.372.842.558 1.41.558ZM4.813 49.188h39.374V9.813H4.813v39.374Zm0-39.374v39.374V9.813Zm0 43.312c-1.094 0-2.024-.383-2.79-1.148-.765-.766-1.148-1.696-1.148-2.79V9.813c0-1.094.383-2.024 1.148-2.79.766-.765 1.696-1.148 2.79-1.148h13.453c.218-1.531.918-2.79 2.1-3.773C21.546 1.117 22.925.625 24.5.625c1.575 0 2.953.492 4.134 1.477 1.182.984 1.882 2.242 2.1 3.773h13.453c1.094 0 2.024.383 2.79 1.148.765.766 1.148 1.696 1.148 2.79v39.374c0 1.094-.383 2.024-1.148 2.79-.766.765-1.696 1.148-2.79 1.148H4.813ZM24.5 8.697c.613 0 1.148-.23 1.608-.69.46-.459.689-.994.689-1.607s-.23-1.148-.69-1.608c-.459-.46-.994-.689-1.607-.689s-1.148.23-1.608.69c-.46.459-.689.994-.689 1.607 0 .612.23 1.148.69 1.608.459.46.994.689 1.607.689Z"
      fill="#32393F"
    />
  </svg>
</template>

<script>
export default {
  name: "IconList",
  components: {},
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>
