<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 500 500"
    fill="#000000"
    :class="[customClass.toString()]"
  >
    <g id="XMLID_1_">
      <path
        id="XMLID_36_"
        d="M155.7,314.4c-15.6,3.5-29.7,11-40.5,7.7c-7.7-2.3-10.6-19.4-16.2-29.6c-17.9-32.7-37.5-64.5-53.7-97.9
		C29.2,161.2,13.2,127.1,3,91.6C-8.3,52.5,16.3,25.1,57.3,25.1c128.4-0.2,256.7-0.2,385.1,0c41,0.1,66.7,28.1,54,66.5
		c-14.4,43.6-35.2,85.6-56.8,126.3c-15.5,29.2-37,54.5-41.3,89.5c-2.8,22.6-28.5,23.3-51.8,1.2c-19.1,42.2-42,69.2-97.8,69.2
		C193.1,377.9,172.7,348.7,155.7,314.4z M382.1,201.4c31.4-36.1,58-74.8,64.5-125c-18.3,0-34.5,0-50,0
		C391.7,119.1,386.9,160.3,382.1,201.4z M52.9,75.9c6.6,50.7,33.1,89.6,64.6,126.3c-4.9-42.1-9.8-84.2-14.6-126.3
		C86.1,75.9,70.5,75.9,52.9,75.9z"
      />
      <path
        id="XMLID_35_"
        d="M249.9,425.2c31.6,0,63.3,0.3,94.9-0.1c17.6-0.2,29.7,5.6,29.7,25.1c0.1,19.6-12.4,25-29.8,25
		c-63.3-0.2-126.5-0.2-189.8,0c-17.3,0-29.9-5.3-29.8-25c0.1-19.5,12.4-25.2,29.9-25C186.7,425.4,218.3,425.2,249.9,425.2z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconTrophy",
  components: {},
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>
