<template>
  <svg
    width="32"
    height="13"
    viewBox="0 0 32 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="[customClass.toString()]"
  >
    <path
      d="M16.7585 0.866577C12.7393 0.866577 9.09933 2.31033 6.2935 4.65825L3.427 1.90199C2.4715 0.983244 0.833496 1.62491 0.833496 2.92283V11.0749C0.833496 11.877 1.516 12.5332 2.35016 12.5332H10.8283C12.1782 12.5332 12.8607 10.9582 11.9052 10.0395L9.00833 7.25408C11.1165 5.56241 13.801 4.51241 16.7737 4.51241C21.5663 4.51241 25.7068 7.19575 27.6785 11.0749C28.088 11.8916 29.0587 12.2999 29.9535 12.0082C31.0303 11.6728 31.5763 10.4916 31.091 9.49991C28.4823 4.39574 23.0527 0.866577 16.7585 0.866577Z"
      fill="#FFA15F"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArrowRoundedLeft",
  components: {},
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>
