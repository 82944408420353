<template>
  <svg
    width="40"
    height="53"
    viewBox="0 0 40 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="[customClass.toString()]"
  >
    <path
      d="M19.997 33.3954C24.5594 33.3954 28.2423 29.6798 28.2423 25.077V8.4403C28.2423 3.83748 24.5594 0.121948 19.997 0.121948C15.4347 0.121948 11.7518 3.83748 11.7518 8.4403V25.077C11.7518 29.6798 15.4347 33.3954 19.997 33.3954ZM36.2402 25.077C34.8935 25.077 33.7666 26.0752 33.5467 27.4339C32.4199 33.9499 26.7856 38.9409 19.997 38.9409C13.2085 38.9409 7.57421 33.9499 6.44736 27.4339C6.22749 26.0752 5.10064 25.077 3.75392 25.077C2.07738 25.077 0.758145 26.5743 1.0055 28.238C2.35223 36.5563 8.94842 43.0724 17.2486 44.2647V50.0321C17.2486 51.5571 18.4854 52.8049 19.997 52.8049C21.5087 52.8049 22.7455 51.5571 22.7455 50.0321V44.2647C31.0457 43.0724 37.6419 36.5563 38.9886 28.238C39.2634 26.5743 37.9167 25.077 36.2402 25.077Z"
      fill="#FFA15F"
    />
  </svg>
</template>

<script>
export default {
  name: "IconMikeUnmuted",
  components: {},
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>
