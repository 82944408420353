<template>
  <svg
    width="99"
    height="33"
    viewBox="0 0 102 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="[customClass.toString()]"
  >
    <path
      d="M13.5369 9.18182H9.23295V3.95455H4.09091V9.18182H0.994318V13.1591H4.09091V25.2898C4.0625 29.3949 7.04545 31.4119 10.9091 31.2983C12.3722 31.2557 13.3807 30.9716 13.9347 30.7869L13.0682 26.767C12.7841 26.8381 12.2017 26.9659 11.5625 26.9659C10.2699 26.9659 9.23295 26.5114 9.23295 24.4375V13.1591H13.5369V9.18182ZM23.7558 31.4403C27.1791 31.4403 29.2245 29.8352 30.162 28.0028H30.3325V31H35.2757V16.3977C35.2757 10.6307 30.5739 8.89773 26.412 8.89773C21.8239 8.89773 18.3012 10.9432 17.1649 14.9205L21.966 15.6023C22.4774 14.1108 23.9262 12.8324 26.4404 12.8324C28.8268 12.8324 30.1336 14.054 30.1336 16.1989V16.2841C30.1336 17.7614 28.5853 17.8324 24.7359 18.2443C20.5029 18.6989 16.4546 19.9631 16.4546 24.8778C16.4546 29.1676 19.5938 31.4403 23.7558 31.4403ZM25.091 27.6619C22.9461 27.6619 21.412 26.6818 21.412 24.7926C21.412 22.8182 23.1308 21.9943 25.4319 21.6676C26.7813 21.483 29.4802 21.142 30.1478 20.6023V23.1733C30.1478 25.6023 28.1876 27.6619 25.091 27.6619ZM45.2282 1.90909H40.0861V31H45.2282V1.90909ZM50.1558 31H55.2978V23.6705L57.1728 21.6676L63.849 31H69.9995L61.0507 18.5994L69.5024 9.18182H63.4939L55.653 17.946H55.2978V1.90909H50.1558V31ZM72.5692 31H77.7113V9.18182H72.5692V31ZM75.1545 6.08523C76.788 6.08523 78.1232 4.83523 78.1232 3.30114C78.1232 1.75284 76.788 0.50284 75.1545 0.50284C73.5067 0.50284 72.1715 1.75284 72.1715 3.30114C72.1715 4.83523 73.5067 6.08523 75.1545 6.08523ZM92.298 31.4261C97.3832 31.4261 100.878 28.9403 101.787 25.1477L96.9855 24.608C96.2895 26.4545 94.5849 27.4205 92.369 27.4205C89.0452 27.4205 86.8435 25.233 86.8008 21.4972H102V19.9205C102 12.2642 97.3974 8.89773 92.0281 8.89773C85.7781 8.89773 81.7014 13.4858 81.7014 20.2188C81.7014 27.0653 85.7213 31.4261 92.298 31.4261ZM86.8151 18.0312C86.9713 15.2472 89.031 12.9034 92.0991 12.9034C95.0537 12.9034 97.0423 15.0625 97.0707 18.0312H86.8151Z"
      fill="#F7D41E"
    />
  </svg>
</template>

<script>
export default {
  name: "LogoTalkie",
  components: {},
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>
