export default {
  activity: {
    ["1st-place-medal.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/1st-place-medal.png",
    ["2nd-place-medal.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/2nd-place-medal.png",
    ["3rd-place-medal.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/3rd-place-medal.png",
    ["accordion.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/accordion.png",
    ["admission-tickets.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/admission-tickets.png",
    ["american-football.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/american-football.png",
    ["artist-palette.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/artist-palette.png",
    ["badminton.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/badminton.png",
    ["ballet-shoes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/ballet-shoes.png",
    ["banjo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/banjo.png",
    ["baseball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/baseball.png",
    ["basketball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/basketball.png",
    ["boomerang.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/boomerang.png",
    ["bow-and-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/bow-and-arrow.png",
    ["bowling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/bowling.png",
    ["boxing-glove.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/boxing-glove.png",
    ["chess-pawn.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/chess-pawn.png",
    ["circus-tent.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/circus-tent.png",
    ["clapper-board.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/clapper-board.png",
    ["cricket-game.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/cricket-game.png",
    ["curling-stone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/curling-stone.png",
    ["direct-hit.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/direct-hit.png",
    ["diving-mask.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/diving-mask.png",
    ["drum.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/drum.png",
    ["field-hockey.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/field-hockey.png",
    ["fishing-pole.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/fishing-pole.png",
    ["flag-in-hole.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/flag-in-hole.png",
    ["flying-disc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/flying-disc.png",
    ["game-die.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/game-die.png",
    ["goal-net.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/goal-net.png",
    ["guitar.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/guitar.png",
    ["headphone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/headphone.png",
    ["horse-racing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/horse-racing.png",
    ["ice-hockey.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/ice-hockey.png",
    ["ice-skate.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/ice-skate.png",
    ["kite.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/kite.png",
    ["lacrosse.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/lacrosse.png",
    ["long-drum.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/long-drum.png",
    ["man-biking.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/man-biking.png",
    ["man-bouncing-ball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/man-bouncing-ball.png",
    ["man-cartwheeling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/man-cartwheeling.png",
    ["man-climbing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/man-climbing.png",
    ["man-golfing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/man-golfing.png",
    ["man-in-lotus-position.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/man-in-lotus-position.png",
    ["man-juggling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/man-juggling.png",
    ["man-lifting-weights.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/man-lifting-weights.png",
    ["man-mountain-biking.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/man-mountain-biking.png",
    ["man-playing-handball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/man-playing-handball.png",
    ["man-playing-water-polo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/man-playing-water-polo.png",
    ["man-rowing-boat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/man-rowing-boat.png",
    ["man-surfing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/man-surfing.png",
    ["man-swimming.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/man-swimming.png",
    ["martial-arts-uniform.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/martial-arts-uniform.png",
    ["men-wrestling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/men-wrestling.png",
    ["microphone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/microphone.png",
    ["military-medal.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/military-medal.png",
    ["musical-keyboard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/musical-keyboard.png",
    ["musical-score.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/musical-score.png",
    ["parachute.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/parachute.png",
    ["people-wrestling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/people-wrestling.png",
    ["performing-arts.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/performing-arts.png",
    ["person-biking.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-biking.png",
    ["person-bouncing-ball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-bouncing-ball.png",
    ["person-cartwheeling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-cartwheeling.png",
    ["person-climbing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-climbing.png",
    ["person-fencing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-fencing.png",
    ["person-golfing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-golfing.png",
    ["person-in-lotus-position.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-in-lotus-position.png",
    ["person-juggling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-juggling.png",
    ["person-lifting-weights.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-lifting-weights.png",
    ["person-mountain-biking.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-mountain-biking.png",
    ["person-playing-handball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-playing-handball.png",
    ["person-playing-water-polo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-playing-water-polo.png",
    ["person-rowing-boat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-rowing-boat.png",
    ["person-surfing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-surfing.png",
    ["person-swimming.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/person-swimming.png",
    ["ping-pong.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/ping-pong.png",
    ["pool-8-ball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/pool-8-ball.png",
    ["puzzle-piece.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/puzzle-piece.png",
    ["reminder-ribbon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/reminder-ribbon.png",
    ["roller-skate.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/roller-skate.png",
    ["rosette.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/rosette.png",
    ["rugby-football.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/rugby-football.png",
    ["running-shirt.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/running-shirt.png",
    ["saxophone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/saxophone.png",
    ["skateboard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/skateboard.png",
    ["skier.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/skier.png",
    ["skis.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/skis.png",
    ["sled.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/sled.png",
    ["slot-machine.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/slot-machine.png",
    ["snowboarder.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/snowboarder.png",
    ["soccer-ball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/soccer-ball.png",
    ["softball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/softball.png",
    ["sports-medal.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/sports-medal.png",
    ["tennis.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/tennis.png",
    ["ticket.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/ticket.png",
    ["trophy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/trophy.png",
    ["trumpet.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/trumpet.png",
    ["video-game.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/video-game.png",
    ["violin.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/violin.png",
    ["volleyball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/volleyball.png",
    ["woman-biking.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/woman-biking.png",
    ["woman-bouncing-ball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/woman-bouncing-ball.png",
    ["woman-cartwheeling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/woman-cartwheeling.png",
    ["woman-climbing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/woman-climbing.png",
    ["woman-golfing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/woman-golfing.png",
    ["woman-in-lotus-position.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/woman-in-lotus-position.png",
    ["woman-juggling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/woman-juggling.png",
    ["woman-lifting-weights.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/woman-lifting-weights.png",
    ["woman-mountain-biking.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/woman-mountain-biking.png",
    ["woman-playing-handball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/woman-playing-handball.png",
    ["woman-playing-water-polo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/woman-playing-water-polo.png",
    ["woman-rowing-boat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/woman-rowing-boat.png",
    ["woman-surfing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/woman-surfing.png",
    ["woman-swimming.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/woman-swimming.png",
    ["women-wrestling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/women-wrestling.png",
    ["yo-yo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/activity/yo-yo.png",
  },
  diversity: {
    ["artist-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/artist-dark-skin-tone.png",
    ["artist-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/artist-light-skin-tone.png",
    ["artist-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/artist-medium-dark-skin-tone.png",
    ["artist-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/artist-medium-light-skin-tone.png",
    ["artist-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/artist-medium-skin-tone.png",
    ["astronaut-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/astronaut-dark-skin-tone.png",
    ["astronaut-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/astronaut-light-skin-tone.png",
    ["astronaut-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/astronaut-medium-dark-skin-tone.png",
    ["astronaut-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/astronaut-medium-light-skin-tone.png",
    ["astronaut-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/astronaut-medium-skin-tone.png",
    ["baby-angel-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/baby-angel-dark-skin-tone.png",
    ["baby-angel-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/baby-angel-light-skin-tone.png",
    ["baby-angel-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/baby-angel-medium-dark-skin-tone.png",
    ["baby-angel-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/baby-angel-medium-light-skin-tone.png",
    ["baby-angel-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/baby-angel-medium-skin-tone.png",
    ["baby-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/baby-dark-skin-tone.png",
    ["baby-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/baby-light-skin-tone.png",
    ["baby-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/baby-medium-dark-skin-tone.png",
    ["baby-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/baby-medium-light-skin-tone.png",
    ["baby-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/baby-medium-skin-tone.png",
    ["backhand-index-pointing-down-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-down-dark-skin-tone.png",
    ["backhand-index-pointing-down-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-down-light-skin-tone.png",
    ["backhand-index-pointing-down-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-down-medium-dark-skin-tone.png",
    ["backhand-index-pointing-down-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-down-medium-light-skin-tone.png",
    ["backhand-index-pointing-down-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-down-medium-skin-tone.png",
    ["backhand-index-pointing-left-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-left-dark-skin-tone.png",
    ["backhand-index-pointing-left-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-left-light-skin-tone.png",
    ["backhand-index-pointing-left-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-left-medium-dark-skin-tone.png",
    ["backhand-index-pointing-left-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-left-medium-light-skin-tone.png",
    ["backhand-index-pointing-left-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-left-medium-skin-tone.png",
    ["backhand-index-pointing-right-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-right-dark-skin-tone.png",
    ["backhand-index-pointing-right-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-right-light-skin-tone.png",
    ["backhand-index-pointing-right-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-right-medium-dark-skin-tone.png",
    ["backhand-index-pointing-right-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-right-medium-light-skin-tone.png",
    ["backhand-index-pointing-right-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-right-medium-skin-tone.png",
    ["backhand-index-pointing-up-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-up-dark-skin-tone.png",
    ["backhand-index-pointing-up-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-up-light-skin-tone.png",
    ["backhand-index-pointing-up-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-up-medium-dark-skin-tone.png",
    ["backhand-index-pointing-up-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-up-medium-light-skin-tone.png",
    ["backhand-index-pointing-up-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/backhand-index-pointing-up-medium-skin-tone.png",
    ["bearded-person-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/bearded-person-dark-skin-tone.png",
    ["bearded-person-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/bearded-person-light-skin-tone.png",
    ["bearded-person-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/bearded-person-medium-dark-skin-tone.png",
    ["bearded-person-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/bearded-person-medium-light-skin-tone.png",
    ["bearded-person-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/bearded-person-medium-skin-tone.png",
    ["blond-haired-man-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-man-dark-skin-tone.png",
    ["blond-haired-man-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-man-light-skin-tone.png",
    ["blond-haired-man-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-man-medium-dark-skin-tone.png",
    ["blond-haired-man-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-man-medium-light-skin-tone.png",
    ["blond-haired-man-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-man-medium-skin-tone.png",
    ["blond-haired-person-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-person-dark-skin-tone.png",
    ["blond-haired-person-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-person-light-skin-tone.png",
    ["blond-haired-person-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-person-medium-dark-skin-tone.png",
    ["blond-haired-person-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-person-medium-light-skin-tone.png",
    ["blond-haired-person-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-person-medium-skin-tone.png",
    ["blond-haired-woman-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-woman-dark-skin-tone.png",
    ["blond-haired-woman-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-woman-light-skin-tone.png",
    ["blond-haired-woman-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-woman-medium-dark-skin-tone.png",
    ["blond-haired-woman-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-woman-medium-light-skin-tone.png",
    ["blond-haired-woman-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/blond-haired-woman-medium-skin-tone.png",
    ["boy-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/boy-dark-skin-tone.png",
    ["boy-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/boy-light-skin-tone.png",
    ["boy-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/boy-medium-dark-skin-tone.png",
    ["boy-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/boy-medium-light-skin-tone.png",
    ["boy-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/boy-medium-skin-tone.png",
    ["breast-feeding-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/breast-feeding-dark-skin-tone.png",
    ["breast-feeding-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/breast-feeding-light-skin-tone.png",
    ["breast-feeding-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/breast-feeding-medium-dark-skin-tone.png",
    ["breast-feeding-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/breast-feeding-medium-light-skin-tone.png",
    ["breast-feeding-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/breast-feeding-medium-skin-tone.png",
    ["call-me-hand-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/call-me-hand-dark-skin-tone.png",
    ["call-me-hand-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/call-me-hand-light-skin-tone.png",
    ["call-me-hand-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/call-me-hand-medium-dark-skin-tone.png",
    ["call-me-hand-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/call-me-hand-medium-light-skin-tone.png",
    ["call-me-hand-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/call-me-hand-medium-skin-tone.png",
    ["child-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/child-dark-skin-tone.png",
    ["child-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/child-light-skin-tone.png",
    ["child-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/child-medium-dark-skin-tone.png",
    ["child-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/child-medium-light-skin-tone.png",
    ["child-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/child-medium-skin-tone.png",
    ["clapping-hands-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/clapping-hands-dark-skin-tone.png",
    ["clapping-hands-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/clapping-hands-light-skin-tone.png",
    ["clapping-hands-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/clapping-hands-medium-dark-skin-tone.png",
    ["clapping-hands-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/clapping-hands-medium-light-skin-tone.png",
    ["clapping-hands-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/clapping-hands-medium-skin-tone.png",
    ["construction-worker-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/construction-worker-dark-skin-tone.png",
    ["construction-worker-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/construction-worker-light-skin-tone.png",
    ["construction-worker-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/construction-worker-medium-dark-skin-tone.png",
    ["construction-worker-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/construction-worker-medium-light-skin-tone.png",
    ["construction-worker-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/construction-worker-medium-skin-tone.png",
    ["cook-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/cook-dark-skin-tone.png",
    ["cook-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/cook-light-skin-tone.png",
    ["cook-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/cook-medium-dark-skin-tone.png",
    ["cook-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/cook-medium-light-skin-tone.png",
    ["cook-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/cook-medium-skin-tone.png",
    ["couple-with-heart-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-dark-skin-tone.png",
    ["couple-with-heart-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-light-skin-tone.png",
    ["couple-with-heart-man-man-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-dark-skin-tone-light-skin-tone.png",
    ["couple-with-heart-man-man-dark-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-dark-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-man-man-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-dark-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-man-man-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-dark-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-man-man-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-dark-skin-tone.png",
    ["couple-with-heart-man-man-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-light-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-man-man-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-light-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-man-man-light-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-light-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-man-man-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-light-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-man-man-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-light-skin-tone.png",
    ["couple-with-heart-man-man-medium-dark-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-dark-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-man-man-medium-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-dark-skin-tone-light-skin-tone.png",
    ["couple-with-heart-man-man-medium-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-dark-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-man-man-medium-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-dark-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-man-man-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-dark-skin-tone.png",
    ["couple-with-heart-man-man-medium-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-light-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-man-man-medium-light-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-light-skin-tone-light-skin-tone.png",
    ["couple-with-heart-man-man-medium-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-light-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-man-man-medium-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-light-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-man-man-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-light-skin-tone.png",
    ["couple-with-heart-man-man-medium-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-man-man-medium-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-skin-tone-light-skin-tone.png",
    ["couple-with-heart-man-man-medium-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-man-man-medium-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-man-man-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-man-man-medium-skin-tone.png",
    ["couple-with-heart-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-medium-dark-skin-tone.png",
    ["couple-with-heart-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-medium-light-skin-tone.png",
    ["couple-with-heart-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-medium-skin-tone.png",
    ["couple-with-heart-person-person-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-dark-skin-tone-light-skin-tone.png",
    ["couple-with-heart-person-person-dark-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-dark-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-person-person-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-dark-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-person-person-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-dark-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-person-person-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-light-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-person-person-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-light-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-person-person-light-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-light-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-person-person-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-light-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-person-person-medium-dark-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-medium-dark-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-person-person-medium-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-medium-dark-skin-tone-light-skin-tone.png",
    ["couple-with-heart-person-person-medium-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-medium-dark-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-person-person-medium-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-medium-dark-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-person-person-medium-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-medium-light-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-person-person-medium-light-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-medium-light-skin-tone-light-skin-tone.png",
    ["couple-with-heart-person-person-medium-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-medium-light-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-person-person-medium-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-medium-light-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-person-person-medium-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-medium-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-person-person-medium-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-medium-skin-tone-light-skin-tone.png",
    ["couple-with-heart-person-person-medium-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-medium-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-person-person-medium-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-person-person-medium-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-woman-man-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-dark-skin-tone-light-skin-tone.png",
    ["couple-with-heart-woman-man-dark-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-dark-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-woman-man-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-dark-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-woman-man-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-dark-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-woman-man-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-dark-skin-tone.png",
    ["couple-with-heart-woman-man-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-light-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-woman-man-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-light-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-woman-man-light-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-light-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-woman-man-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-light-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-woman-man-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-light-skin-tone.png",
    ["couple-with-heart-woman-man-medium-dark-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-dark-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-woman-man-medium-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-dark-skin-tone-light-skin-tone.png",
    ["couple-with-heart-woman-man-medium-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-dark-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-woman-man-medium-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-dark-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-woman-man-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-dark-skin-tone.png",
    ["couple-with-heart-woman-man-medium-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-light-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-woman-man-medium-light-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-light-skin-tone-light-skin-tone.png",
    ["couple-with-heart-woman-man-medium-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-light-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-woman-man-medium-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-light-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-woman-man-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-light-skin-tone.png",
    ["couple-with-heart-woman-man-medium-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-woman-man-medium-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-skin-tone-light-skin-tone.png",
    ["couple-with-heart-woman-man-medium-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-woman-man-medium-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-woman-man-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-man-medium-skin-tone.png",
    ["couple-with-heart-woman-woman-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-dark-skin-tone-light-skin-tone.png",
    ["couple-with-heart-woman-woman-dark-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-dark-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-woman-woman-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-dark-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-woman-woman-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-dark-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-woman-woman-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-dark-skin-tone.png",
    ["couple-with-heart-woman-woman-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-light-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-woman-woman-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-light-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-woman-woman-light-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-light-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-woman-woman-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-light-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-woman-woman-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-light-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-dark-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-dark-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-dark-skin-tone-light-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-dark-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-dark-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-dark-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-light-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-light-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-light-skin-tone-light-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-light-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-light-skin-tone-medium-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-light-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-skin-tone-dark-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-skin-tone-light-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-skin-tone-medium-dark-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-skin-tone-medium-light-skin-tone.png",
    ["couple-with-heart-woman-woman-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/couple-with-heart-woman-woman-medium-skin-tone.png",
    ["crossed-fingers-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/crossed-fingers-dark-skin-tone.png",
    ["crossed-fingers-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/crossed-fingers-light-skin-tone.png",
    ["crossed-fingers-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/crossed-fingers-medium-dark-skin-tone.png",
    ["crossed-fingers-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/crossed-fingers-medium-light-skin-tone.png",
    ["crossed-fingers-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/crossed-fingers-medium-skin-tone.png",
    ["dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/dark-skin-tone.png",
    ["deaf-man-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-man-dark-skin-tone.png",
    ["deaf-man-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-man-light-skin-tone.png",
    ["deaf-man-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-man-medium-dark-skin-tone.png",
    ["deaf-man-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-man-medium-light-skin-tone.png",
    ["deaf-man-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-man-medium-skin-tone.png",
    ["deaf-person-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-person-dark-skin-tone.png",
    ["deaf-person-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-person-light-skin-tone.png",
    ["deaf-person-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-person-medium-dark-skin-tone.png",
    ["deaf-person-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-person-medium-light-skin-tone.png",
    ["deaf-person-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-person-medium-skin-tone.png",
    ["deaf-woman-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-woman-dark-skin-tone.png",
    ["deaf-woman-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-woman-light-skin-tone.png",
    ["deaf-woman-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-woman-medium-dark-skin-tone.png",
    ["deaf-woman-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-woman-medium-light-skin-tone.png",
    ["deaf-woman-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/deaf-woman-medium-skin-tone.png",
    ["detective-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/detective-dark-skin-tone.png",
    ["detective-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/detective-light-skin-tone.png",
    ["detective-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/detective-medium-dark-skin-tone.png",
    ["detective-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/detective-medium-light-skin-tone.png",
    ["detective-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/detective-medium-skin-tone.png",
    ["ear-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ear-dark-skin-tone.png",
    ["ear-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ear-light-skin-tone.png",
    ["ear-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ear-medium-dark-skin-tone.png",
    ["ear-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ear-medium-light-skin-tone.png",
    ["ear-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ear-medium-skin-tone.png",
    ["ear-with-hearing-aid-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ear-with-hearing-aid-dark-skin-tone.png",
    ["ear-with-hearing-aid-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ear-with-hearing-aid-light-skin-tone.png",
    ["ear-with-hearing-aid-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ear-with-hearing-aid-medium-dark-skin-tone.png",
    ["ear-with-hearing-aid-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ear-with-hearing-aid-medium-light-skin-tone.png",
    ["ear-with-hearing-aid-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ear-with-hearing-aid-medium-skin-tone.png",
    ["elf-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/elf-dark-skin-tone.png",
    ["elf-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/elf-light-skin-tone.png",
    ["elf-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/elf-medium-dark-skin-tone.png",
    ["elf-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/elf-medium-light-skin-tone.png",
    ["elf-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/elf-medium-skin-tone.png",
    ["factory-worker-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/factory-worker-dark-skin-tone.png",
    ["factory-worker-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/factory-worker-light-skin-tone.png",
    ["factory-worker-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/factory-worker-medium-dark-skin-tone.png",
    ["factory-worker-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/factory-worker-medium-light-skin-tone.png",
    ["factory-worker-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/factory-worker-medium-skin-tone.png",
    ["fairy-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/fairy-dark-skin-tone.png",
    ["fairy-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/fairy-light-skin-tone.png",
    ["fairy-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/fairy-medium-dark-skin-tone.png",
    ["fairy-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/fairy-medium-light-skin-tone.png",
    ["fairy-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/fairy-medium-skin-tone.png",
    ["farmer-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/farmer-dark-skin-tone.png",
    ["farmer-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/farmer-light-skin-tone.png",
    ["farmer-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/farmer-medium-dark-skin-tone.png",
    ["farmer-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/farmer-medium-light-skin-tone.png",
    ["farmer-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/farmer-medium-skin-tone.png",
    ["firefighter-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/firefighter-dark-skin-tone.png",
    ["firefighter-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/firefighter-light-skin-tone.png",
    ["firefighter-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/firefighter-medium-dark-skin-tone.png",
    ["firefighter-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/firefighter-medium-light-skin-tone.png",
    ["firefighter-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/firefighter-medium-skin-tone.png",
    ["flexed-biceps-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/flexed-biceps-dark-skin-tone.png",
    ["flexed-biceps-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/flexed-biceps-light-skin-tone.png",
    ["flexed-biceps-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/flexed-biceps-medium-dark-skin-tone.png",
    ["flexed-biceps-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/flexed-biceps-medium-light-skin-tone.png",
    ["flexed-biceps-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/flexed-biceps-medium-skin-tone.png",
    ["folded-hands-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/folded-hands-dark-skin-tone.png",
    ["folded-hands-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/folded-hands-light-skin-tone.png",
    ["folded-hands-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/folded-hands-medium-dark-skin-tone.png",
    ["folded-hands-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/folded-hands-medium-light-skin-tone.png",
    ["folded-hands-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/folded-hands-medium-skin-tone.png",
    ["foot-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/foot-dark-skin-tone.png",
    ["foot-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/foot-light-skin-tone.png",
    ["foot-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/foot-medium-dark-skin-tone.png",
    ["foot-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/foot-medium-light-skin-tone.png",
    ["foot-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/foot-medium-skin-tone.png",
    ["girl-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/girl-dark-skin-tone.png",
    ["girl-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/girl-light-skin-tone.png",
    ["girl-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/girl-medium-dark-skin-tone.png",
    ["girl-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/girl-medium-light-skin-tone.png",
    ["girl-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/girl-medium-skin-tone.png",
    ["guard-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/guard-dark-skin-tone.png",
    ["guard-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/guard-light-skin-tone.png",
    ["guard-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/guard-medium-dark-skin-tone.png",
    ["guard-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/guard-medium-light-skin-tone.png",
    ["guard-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/guard-medium-skin-tone.png",
    ["hand-with-fingers-splayed-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/hand-with-fingers-splayed-dark-skin-tone.png",
    ["hand-with-fingers-splayed-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/hand-with-fingers-splayed-light-skin-tone.png",
    ["hand-with-fingers-splayed-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/hand-with-fingers-splayed-medium-dark-skin-tone.png",
    ["hand-with-fingers-splayed-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/hand-with-fingers-splayed-medium-light-skin-tone.png",
    ["hand-with-fingers-splayed-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/hand-with-fingers-splayed-medium-skin-tone.png",
    ["health-worker-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/health-worker-dark-skin-tone.png",
    ["health-worker-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/health-worker-light-skin-tone.png",
    ["health-worker-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/health-worker-medium-dark-skin-tone.png",
    ["health-worker-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/health-worker-medium-light-skin-tone.png",
    ["health-worker-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/health-worker-medium-skin-tone.png",
    ["horse-racing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/horse-racing-dark-skin-tone.png",
    ["horse-racing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/horse-racing-light-skin-tone.png",
    ["horse-racing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/horse-racing-medium-dark-skin-tone.png",
    ["horse-racing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/horse-racing-medium-light-skin-tone.png",
    ["horse-racing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/horse-racing-medium-skin-tone.png",
    ["index-pointing-up-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/index-pointing-up-dark-skin-tone.png",
    ["index-pointing-up-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/index-pointing-up-light-skin-tone.png",
    ["index-pointing-up-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/index-pointing-up-medium-dark-skin-tone.png",
    ["index-pointing-up-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/index-pointing-up-medium-light-skin-tone.png",
    ["index-pointing-up-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/index-pointing-up-medium-skin-tone.png",
    ["judge-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/judge-dark-skin-tone.png",
    ["judge-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/judge-light-skin-tone.png",
    ["judge-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/judge-medium-dark-skin-tone.png",
    ["judge-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/judge-medium-light-skin-tone.png",
    ["judge-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/judge-medium-skin-tone.png",
    ["kiss-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-dark-skin-tone.png",
    ["kiss-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-light-skin-tone.png",
    ["kiss-man-man-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-dark-skin-tone-light-skin-tone.png",
    ["kiss-man-man-dark-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-dark-skin-tone-medium-dark-skin-tone.png",
    ["kiss-man-man-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-dark-skin-tone-medium-light-skin-tone.png",
    ["kiss-man-man-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-dark-skin-tone-medium-skin-tone.png",
    ["kiss-man-man-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-dark-skin-tone.png",
    ["kiss-man-man-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-light-skin-tone-dark-skin-tone.png",
    ["kiss-man-man-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-light-skin-tone-medium-dark-skin-tone.png",
    ["kiss-man-man-light-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-light-skin-tone-medium-light-skin-tone.png",
    ["kiss-man-man-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-light-skin-tone-medium-skin-tone.png",
    ["kiss-man-man-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-light-skin-tone.png",
    ["kiss-man-man-medium-dark-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-dark-skin-tone-dark-skin-tone.png",
    ["kiss-man-man-medium-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-dark-skin-tone-light-skin-tone.png",
    ["kiss-man-man-medium-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-dark-skin-tone-medium-light-skin-tone.png",
    ["kiss-man-man-medium-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-dark-skin-tone-medium-skin-tone.png",
    ["kiss-man-man-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-dark-skin-tone.png",
    ["kiss-man-man-medium-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-light-skin-tone-dark-skin-tone.png",
    ["kiss-man-man-medium-light-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-light-skin-tone-light-skin-tone.png",
    ["kiss-man-man-medium-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-light-skin-tone-medium-dark-skin-tone.png",
    ["kiss-man-man-medium-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-light-skin-tone-medium-skin-tone.png",
    ["kiss-man-man-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-light-skin-tone.png",
    ["kiss-man-man-medium-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-skin-tone-dark-skin-tone.png",
    ["kiss-man-man-medium-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-skin-tone-light-skin-tone.png",
    ["kiss-man-man-medium-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-skin-tone-medium-dark-skin-tone.png",
    ["kiss-man-man-medium-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-skin-tone-medium-light-skin-tone.png",
    ["kiss-man-man-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-man-man-medium-skin-tone.png",
    ["kiss-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-medium-dark-skin-tone.png",
    ["kiss-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-medium-light-skin-tone.png",
    ["kiss-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-medium-skin-tone.png",
    ["kiss-person-person-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-dark-skin-tone-light-skin-tone.png",
    ["kiss-person-person-dark-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-dark-skin-tone-medium-dark-skin-tone.png",
    ["kiss-person-person-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-dark-skin-tone-medium-light-skin-tone.png",
    ["kiss-person-person-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-dark-skin-tone-medium-skin-tone.png",
    ["kiss-person-person-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-light-skin-tone-dark-skin-tone.png",
    ["kiss-person-person-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-light-skin-tone-medium-dark-skin-tone.png",
    ["kiss-person-person-light-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-light-skin-tone-medium-light-skin-tone.png",
    ["kiss-person-person-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-light-skin-tone-medium-skin-tone.png",
    ["kiss-person-person-medium-dark-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-medium-dark-skin-tone-dark-skin-tone.png",
    ["kiss-person-person-medium-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-medium-dark-skin-tone-light-skin-tone.png",
    ["kiss-person-person-medium-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-medium-dark-skin-tone-medium-light-skin-tone.png",
    ["kiss-person-person-medium-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-medium-dark-skin-tone-medium-skin-tone.png",
    ["kiss-person-person-medium-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-medium-light-skin-tone-dark-skin-tone.png",
    ["kiss-person-person-medium-light-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-medium-light-skin-tone-light-skin-tone.png",
    ["kiss-person-person-medium-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-medium-light-skin-tone-medium-dark-skin-tone.png",
    ["kiss-person-person-medium-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-medium-light-skin-tone-medium-skin-tone.png",
    ["kiss-person-person-medium-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-medium-skin-tone-dark-skin-tone.png",
    ["kiss-person-person-medium-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-medium-skin-tone-light-skin-tone.png",
    ["kiss-person-person-medium-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-medium-skin-tone-medium-dark-skin-tone.png",
    ["kiss-person-person-medium-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-person-person-medium-skin-tone-medium-light-skin-tone.png",
    ["kiss-woman-man-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-dark-skin-tone-light-skin-tone.png",
    ["kiss-woman-man-dark-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-dark-skin-tone-medium-dark-skin-tone.png",
    ["kiss-woman-man-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-dark-skin-tone-medium-light-skin-tone.png",
    ["kiss-woman-man-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-dark-skin-tone-medium-skin-tone.png",
    ["kiss-woman-man-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-dark-skin-tone.png",
    ["kiss-woman-man-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-light-skin-tone-dark-skin-tone.png",
    ["kiss-woman-man-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-light-skin-tone-medium-dark-skin-tone.png",
    ["kiss-woman-man-light-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-light-skin-tone-medium-light-skin-tone.png",
    ["kiss-woman-man-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-light-skin-tone-medium-skin-tone.png",
    ["kiss-woman-man-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-light-skin-tone.png",
    ["kiss-woman-man-medium-dark-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-dark-skin-tone-dark-skin-tone.png",
    ["kiss-woman-man-medium-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-dark-skin-tone-light-skin-tone.png",
    ["kiss-woman-man-medium-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-dark-skin-tone-medium-light-skin-tone.png",
    ["kiss-woman-man-medium-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-dark-skin-tone-medium-skin-tone.png",
    ["kiss-woman-man-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-dark-skin-tone.png",
    ["kiss-woman-man-medium-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-light-skin-tone-dark-skin-tone.png",
    ["kiss-woman-man-medium-light-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-light-skin-tone-light-skin-tone.png",
    ["kiss-woman-man-medium-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-light-skin-tone-medium-dark-skin-tone.png",
    ["kiss-woman-man-medium-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-light-skin-tone-medium-skin-tone.png",
    ["kiss-woman-man-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-light-skin-tone.png",
    ["kiss-woman-man-medium-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-skin-tone-dark-skin-tone.png",
    ["kiss-woman-man-medium-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-skin-tone-light-skin-tone.png",
    ["kiss-woman-man-medium-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-skin-tone-medium-dark-skin-tone.png",
    ["kiss-woman-man-medium-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-skin-tone-medium-light-skin-tone.png",
    ["kiss-woman-man-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-man-medium-skin-tone.png",
    ["kiss-woman-woman-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-dark-skin-tone-light-skin-tone.png",
    ["kiss-woman-woman-dark-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-dark-skin-tone-medium-dark-skin-tone.png",
    ["kiss-woman-woman-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-dark-skin-tone-medium-light-skin-tone.png",
    ["kiss-woman-woman-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-dark-skin-tone-medium-skin-tone.png",
    ["kiss-woman-woman-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-dark-skin-tone.png",
    ["kiss-woman-woman-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-light-skin-tone-dark-skin-tone.png",
    ["kiss-woman-woman-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-light-skin-tone-medium-dark-skin-tone.png",
    ["kiss-woman-woman-light-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-light-skin-tone-medium-light-skin-tone.png",
    ["kiss-woman-woman-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-light-skin-tone-medium-skin-tone.png",
    ["kiss-woman-woman-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-light-skin-tone.png",
    ["kiss-woman-woman-medium-dark-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-dark-skin-tone-dark-skin-tone.png",
    ["kiss-woman-woman-medium-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-dark-skin-tone-light-skin-tone.png",
    ["kiss-woman-woman-medium-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-dark-skin-tone-medium-light-skin-tone.png",
    ["kiss-woman-woman-medium-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-dark-skin-tone-medium-skin-tone.png",
    ["kiss-woman-woman-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-dark-skin-tone.png",
    ["kiss-woman-woman-medium-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-light-skin-tone-dark-skin-tone.png",
    ["kiss-woman-woman-medium-light-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-light-skin-tone-light-skin-tone.png",
    ["kiss-woman-woman-medium-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-light-skin-tone-medium-dark-skin-tone.png",
    ["kiss-woman-woman-medium-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-light-skin-tone-medium-skin-tone.png",
    ["kiss-woman-woman-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-light-skin-tone.png",
    ["kiss-woman-woman-medium-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-skin-tone-dark-skin-tone.png",
    ["kiss-woman-woman-medium-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-skin-tone-light-skin-tone.png",
    ["kiss-woman-woman-medium-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-skin-tone-medium-dark-skin-tone.png",
    ["kiss-woman-woman-medium-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-skin-tone-medium-light-skin-tone.png",
    ["kiss-woman-woman-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/kiss-woman-woman-medium-skin-tone.png",
    ["left-facing-fist-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/left-facing-fist-dark-skin-tone.png",
    ["left-facing-fist-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/left-facing-fist-light-skin-tone.png",
    ["left-facing-fist-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/left-facing-fist-medium-dark-skin-tone.png",
    ["left-facing-fist-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/left-facing-fist-medium-light-skin-tone.png",
    ["left-facing-fist-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/left-facing-fist-medium-skin-tone.png",
    ["leg-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/leg-dark-skin-tone.png",
    ["leg-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/leg-light-skin-tone.png",
    ["leg-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/leg-medium-dark-skin-tone.png",
    ["leg-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/leg-medium-light-skin-tone.png",
    ["leg-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/leg-medium-skin-tone.png",
    ["light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/light-skin-tone.png",
    ["love-you-gesture-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/love-you-gesture-dark-skin-tone.png",
    ["love-you-gesture-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/love-you-gesture-light-skin-tone.png",
    ["love-you-gesture-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/love-you-gesture-medium-dark-skin-tone.png",
    ["love-you-gesture-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/love-you-gesture-medium-light-skin-tone.png",
    ["love-you-gesture-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/love-you-gesture-medium-skin-tone.png",
    ["mage-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mage-dark-skin-tone.png",
    ["mage-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mage-light-skin-tone.png",
    ["mage-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mage-medium-dark-skin-tone.png",
    ["mage-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mage-medium-light-skin-tone.png",
    ["mage-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mage-medium-skin-tone.png",
    ["man-artist-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-artist-dark-skin-tone.png",
    ["man-artist-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-artist-light-skin-tone.png",
    ["man-artist-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-artist-medium-dark-skin-tone.png",
    ["man-artist-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-artist-medium-light-skin-tone.png",
    ["man-artist-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-artist-medium-skin-tone.png",
    ["man-astronaut-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-astronaut-dark-skin-tone.png",
    ["man-astronaut-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-astronaut-light-skin-tone.png",
    ["man-astronaut-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-astronaut-medium-dark-skin-tone.png",
    ["man-astronaut-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-astronaut-medium-light-skin-tone.png",
    ["man-astronaut-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-astronaut-medium-skin-tone.png",
    ["man-bald-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bald-dark-skin-tone.png",
    ["man-bald-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bald-light-skin-tone.png",
    ["man-bald-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bald-medium-dark-skin-tone.png",
    ["man-bald-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bald-medium-light-skin-tone.png",
    ["man-bald-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bald-medium-skin-tone.png",
    ["man-biking-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-biking-dark-skin-tone.png",
    ["man-biking-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-biking-light-skin-tone.png",
    ["man-biking-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-biking-medium-dark-skin-tone.png",
    ["man-biking-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-biking-medium-light-skin-tone.png",
    ["man-biking-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-biking-medium-skin-tone.png",
    ["man-bouncing-ball-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bouncing-ball-dark-skin-tone.png",
    ["man-bouncing-ball-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bouncing-ball-light-skin-tone.png",
    ["man-bouncing-ball-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bouncing-ball-medium-dark-skin-tone.png",
    ["man-bouncing-ball-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bouncing-ball-medium-light-skin-tone.png",
    ["man-bouncing-ball-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bouncing-ball-medium-skin-tone.png",
    ["man-bowing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bowing-dark-skin-tone.png",
    ["man-bowing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bowing-light-skin-tone.png",
    ["man-bowing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bowing-medium-dark-skin-tone.png",
    ["man-bowing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bowing-medium-light-skin-tone.png",
    ["man-bowing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-bowing-medium-skin-tone.png",
    ["man-cartwheeling-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-cartwheeling-dark-skin-tone.png",
    ["man-cartwheeling-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-cartwheeling-light-skin-tone.png",
    ["man-cartwheeling-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-cartwheeling-medium-dark-skin-tone.png",
    ["man-cartwheeling-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-cartwheeling-medium-light-skin-tone.png",
    ["man-cartwheeling-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-cartwheeling-medium-skin-tone.png",
    ["man-climbing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-climbing-dark-skin-tone.png",
    ["man-climbing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-climbing-light-skin-tone.png",
    ["man-climbing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-climbing-medium-dark-skin-tone.png",
    ["man-climbing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-climbing-medium-light-skin-tone.png",
    ["man-climbing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-climbing-medium-skin-tone.png",
    ["man-construction-worker-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-construction-worker-dark-skin-tone.png",
    ["man-construction-worker-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-construction-worker-light-skin-tone.png",
    ["man-construction-worker-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-construction-worker-medium-dark-skin-tone.png",
    ["man-construction-worker-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-construction-worker-medium-light-skin-tone.png",
    ["man-construction-worker-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-construction-worker-medium-skin-tone.png",
    ["man-cook-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-cook-dark-skin-tone.png",
    ["man-cook-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-cook-light-skin-tone.png",
    ["man-cook-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-cook-medium-dark-skin-tone.png",
    ["man-cook-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-cook-medium-light-skin-tone.png",
    ["man-cook-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-cook-medium-skin-tone.png",
    ["man-curly-haired-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-curly-haired-dark-skin-tone.png",
    ["man-curly-haired-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-curly-haired-light-skin-tone.png",
    ["man-curly-haired-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-curly-haired-medium-dark-skin-tone.png",
    ["man-curly-haired-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-curly-haired-medium-light-skin-tone.png",
    ["man-curly-haired-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-curly-haired-medium-skin-tone.png",
    ["man-dancing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-dancing-dark-skin-tone.png",
    ["man-dancing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-dancing-light-skin-tone.png",
    ["man-dancing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-dancing-medium-dark-skin-tone.png",
    ["man-dancing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-dancing-medium-light-skin-tone.png",
    ["man-dancing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-dancing-medium-skin-tone.png",
    ["man-dark-skin-tone-beard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-dark-skin-tone-beard.png",
    ["man-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-dark-skin-tone.png",
    ["man-detective-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-detective-dark-skin-tone.png",
    ["man-detective-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-detective-light-skin-tone.png",
    ["man-detective-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-detective-medium-dark-skin-tone.png",
    ["man-detective-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-detective-medium-light-skin-tone.png",
    ["man-detective-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-detective-medium-skin-tone.png",
    ["man-elf-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-elf-dark-skin-tone.png",
    ["man-elf-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-elf-light-skin-tone.png",
    ["man-elf-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-elf-medium-dark-skin-tone.png",
    ["man-elf-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-elf-medium-light-skin-tone.png",
    ["man-elf-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-elf-medium-skin-tone.png",
    ["man-facepalming-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-facepalming-dark-skin-tone.png",
    ["man-facepalming-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-facepalming-light-skin-tone.png",
    ["man-facepalming-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-facepalming-medium-dark-skin-tone.png",
    ["man-facepalming-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-facepalming-medium-light-skin-tone.png",
    ["man-facepalming-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-facepalming-medium-skin-tone.png",
    ["man-factory-worker-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-factory-worker-dark-skin-tone.png",
    ["man-factory-worker-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-factory-worker-light-skin-tone.png",
    ["man-factory-worker-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-factory-worker-medium-dark-skin-tone.png",
    ["man-factory-worker-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-factory-worker-medium-light-skin-tone.png",
    ["man-factory-worker-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-factory-worker-medium-skin-tone.png",
    ["man-fairy-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-fairy-dark-skin-tone.png",
    ["man-fairy-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-fairy-light-skin-tone.png",
    ["man-fairy-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-fairy-medium-dark-skin-tone.png",
    ["man-fairy-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-fairy-medium-light-skin-tone.png",
    ["man-fairy-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-fairy-medium-skin-tone.png",
    ["man-farmer-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-farmer-dark-skin-tone.png",
    ["man-farmer-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-farmer-light-skin-tone.png",
    ["man-farmer-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-farmer-medium-dark-skin-tone.png",
    ["man-farmer-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-farmer-medium-light-skin-tone.png",
    ["man-farmer-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-farmer-medium-skin-tone.png",
    ["man-feeding-baby-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-feeding-baby-dark-skin-tone.png",
    ["man-feeding-baby-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-feeding-baby-light-skin-tone.png",
    ["man-feeding-baby-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-feeding-baby-medium-dark-skin-tone.png",
    ["man-feeding-baby-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-feeding-baby-medium-light-skin-tone.png",
    ["man-feeding-baby-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-feeding-baby-medium-skin-tone.png",
    ["man-firefighter-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-firefighter-dark-skin-tone.png",
    ["man-firefighter-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-firefighter-light-skin-tone.png",
    ["man-firefighter-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-firefighter-medium-dark-skin-tone.png",
    ["man-firefighter-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-firefighter-medium-light-skin-tone.png",
    ["man-firefighter-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-firefighter-medium-skin-tone.png",
    ["man-frowning-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-frowning-dark-skin-tone.png",
    ["man-frowning-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-frowning-light-skin-tone.png",
    ["man-frowning-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-frowning-medium-dark-skin-tone.png",
    ["man-frowning-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-frowning-medium-light-skin-tone.png",
    ["man-frowning-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-frowning-medium-skin-tone.png",
    ["man-gesturing-no-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-gesturing-no-dark-skin-tone.png",
    ["man-gesturing-no-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-gesturing-no-light-skin-tone.png",
    ["man-gesturing-no-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-gesturing-no-medium-dark-skin-tone.png",
    ["man-gesturing-no-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-gesturing-no-medium-light-skin-tone.png",
    ["man-gesturing-no-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-gesturing-no-medium-skin-tone.png",
    ["man-gesturing-ok-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-gesturing-ok-dark-skin-tone.png",
    ["man-gesturing-ok-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-gesturing-ok-light-skin-tone.png",
    ["man-gesturing-ok-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-gesturing-ok-medium-dark-skin-tone.png",
    ["man-gesturing-ok-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-gesturing-ok-medium-light-skin-tone.png",
    ["man-gesturing-ok-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-gesturing-ok-medium-skin-tone.png",
    ["man-getting-haircut-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-getting-haircut-dark-skin-tone.png",
    ["man-getting-haircut-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-getting-haircut-light-skin-tone.png",
    ["man-getting-haircut-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-getting-haircut-medium-dark-skin-tone.png",
    ["man-getting-haircut-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-getting-haircut-medium-light-skin-tone.png",
    ["man-getting-haircut-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-getting-haircut-medium-skin-tone.png",
    ["man-getting-massage-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-getting-massage-dark-skin-tone.png",
    ["man-getting-massage-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-getting-massage-light-skin-tone.png",
    ["man-getting-massage-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-getting-massage-medium-dark-skin-tone.png",
    ["man-getting-massage-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-getting-massage-medium-light-skin-tone.png",
    ["man-getting-massage-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-getting-massage-medium-skin-tone.png",
    ["man-golfing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-golfing-dark-skin-tone.png",
    ["man-golfing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-golfing-light-skin-tone.png",
    ["man-golfing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-golfing-medium-dark-skin-tone.png",
    ["man-golfing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-golfing-medium-light-skin-tone.png",
    ["man-golfing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-golfing-medium-skin-tone.png",
    ["man-guard-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-guard-dark-skin-tone.png",
    ["man-guard-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-guard-light-skin-tone.png",
    ["man-guard-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-guard-medium-dark-skin-tone.png",
    ["man-guard-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-guard-medium-light-skin-tone.png",
    ["man-guard-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-guard-medium-skin-tone.png",
    ["man-health-worker-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-health-worker-dark-skin-tone.png",
    ["man-health-worker-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-health-worker-light-skin-tone.png",
    ["man-health-worker-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-health-worker-medium-dark-skin-tone.png",
    ["man-health-worker-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-health-worker-medium-light-skin-tone.png",
    ["man-health-worker-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-health-worker-medium-skin-tone.png",
    ["man-in-lotus-position-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-lotus-position-dark-skin-tone.png",
    ["man-in-lotus-position-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-lotus-position-light-skin-tone.png",
    ["man-in-lotus-position-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-lotus-position-medium-dark-skin-tone.png",
    ["man-in-lotus-position-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-lotus-position-medium-light-skin-tone.png",
    ["man-in-lotus-position-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-lotus-position-medium-skin-tone.png",
    ["man-in-manual-wheelchair-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-manual-wheelchair-dark-skin-tone.png",
    ["man-in-manual-wheelchair-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-manual-wheelchair-light-skin-tone.png",
    ["man-in-manual-wheelchair-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-manual-wheelchair-medium-dark-skin-tone.png",
    ["man-in-manual-wheelchair-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-manual-wheelchair-medium-light-skin-tone.png",
    ["man-in-manual-wheelchair-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-manual-wheelchair-medium-skin-tone.png",
    ["man-in-motorized-wheelchair-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-motorized-wheelchair-dark-skin-tone.png",
    ["man-in-motorized-wheelchair-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-motorized-wheelchair-light-skin-tone.png",
    ["man-in-motorized-wheelchair-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-motorized-wheelchair-medium-dark-skin-tone.png",
    ["man-in-motorized-wheelchair-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-motorized-wheelchair-medium-light-skin-tone.png",
    ["man-in-motorized-wheelchair-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-motorized-wheelchair-medium-skin-tone.png",
    ["man-in-steamy-room-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-steamy-room-dark-skin-tone.png",
    ["man-in-steamy-room-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-steamy-room-light-skin-tone.png",
    ["man-in-steamy-room-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-steamy-room-medium-dark-skin-tone.png",
    ["man-in-steamy-room-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-steamy-room-medium-light-skin-tone.png",
    ["man-in-steamy-room-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-steamy-room-medium-skin-tone.png",
    ["man-in-suit-levitating-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-suit-levitating-dark-skin-tone.png",
    ["man-in-suit-levitating-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-suit-levitating-light-skin-tone.png",
    ["man-in-suit-levitating-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-suit-levitating-medium-dark-skin-tone.png",
    ["man-in-suit-levitating-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-suit-levitating-medium-light-skin-tone.png",
    ["man-in-suit-levitating-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-suit-levitating-medium-skin-tone.png",
    ["man-in-tuxedo-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-tuxedo-dark-skin-tone.png",
    ["man-in-tuxedo-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-tuxedo-light-skin-tone.png",
    ["man-in-tuxedo-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-tuxedo-medium-dark-skin-tone.png",
    ["man-in-tuxedo-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-tuxedo-medium-light-skin-tone.png",
    ["man-in-tuxedo-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-in-tuxedo-medium-skin-tone.png",
    ["man-judge-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-judge-dark-skin-tone.png",
    ["man-judge-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-judge-light-skin-tone.png",
    ["man-judge-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-judge-medium-dark-skin-tone.png",
    ["man-judge-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-judge-medium-light-skin-tone.png",
    ["man-judge-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-judge-medium-skin-tone.png",
    ["man-juggling-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-juggling-dark-skin-tone.png",
    ["man-juggling-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-juggling-light-skin-tone.png",
    ["man-juggling-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-juggling-medium-dark-skin-tone.png",
    ["man-juggling-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-juggling-medium-light-skin-tone.png",
    ["man-juggling-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-juggling-medium-skin-tone.png",
    ["man-kneeling-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-kneeling-dark-skin-tone.png",
    ["man-kneeling-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-kneeling-light-skin-tone.png",
    ["man-kneeling-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-kneeling-medium-dark-skin-tone.png",
    ["man-kneeling-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-kneeling-medium-light-skin-tone.png",
    ["man-kneeling-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-kneeling-medium-skin-tone.png",
    ["man-lifting-weights-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-lifting-weights-dark-skin-tone.png",
    ["man-lifting-weights-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-lifting-weights-light-skin-tone.png",
    ["man-lifting-weights-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-lifting-weights-medium-dark-skin-tone.png",
    ["man-lifting-weights-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-lifting-weights-medium-light-skin-tone.png",
    ["man-lifting-weights-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-lifting-weights-medium-skin-tone.png",
    ["man-light-skin-tone-beard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-light-skin-tone-beard.png",
    ["man-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-light-skin-tone.png",
    ["man-mage-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mage-dark-skin-tone.png",
    ["man-mage-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mage-light-skin-tone.png",
    ["man-mage-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mage-medium-dark-skin-tone.png",
    ["man-mage-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mage-medium-light-skin-tone.png",
    ["man-mage-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mage-medium-skin-tone.png",
    ["man-mechanic-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mechanic-dark-skin-tone.png",
    ["man-mechanic-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mechanic-light-skin-tone.png",
    ["man-mechanic-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mechanic-medium-dark-skin-tone.png",
    ["man-mechanic-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mechanic-medium-light-skin-tone.png",
    ["man-mechanic-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mechanic-medium-skin-tone.png",
    ["man-medium-dark-skin-tone-beard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-medium-dark-skin-tone-beard.png",
    ["man-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-medium-dark-skin-tone.png",
    ["man-medium-light-skin-tone-beard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-medium-light-skin-tone-beard.png",
    ["man-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-medium-light-skin-tone.png",
    ["man-medium-skin-tone-beard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-medium-skin-tone-beard.png",
    ["man-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-medium-skin-tone.png",
    ["man-mountain-biking-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mountain-biking-dark-skin-tone.png",
    ["man-mountain-biking-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mountain-biking-light-skin-tone.png",
    ["man-mountain-biking-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mountain-biking-medium-dark-skin-tone.png",
    ["man-mountain-biking-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mountain-biking-medium-light-skin-tone.png",
    ["man-mountain-biking-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-mountain-biking-medium-skin-tone.png",
    ["man-office-worker-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-office-worker-dark-skin-tone.png",
    ["man-office-worker-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-office-worker-light-skin-tone.png",
    ["man-office-worker-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-office-worker-medium-dark-skin-tone.png",
    ["man-office-worker-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-office-worker-medium-light-skin-tone.png",
    ["man-office-worker-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-office-worker-medium-skin-tone.png",
    ["man-pilot-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-pilot-dark-skin-tone.png",
    ["man-pilot-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-pilot-light-skin-tone.png",
    ["man-pilot-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-pilot-medium-dark-skin-tone.png",
    ["man-pilot-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-pilot-medium-light-skin-tone.png",
    ["man-pilot-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-pilot-medium-skin-tone.png",
    ["man-playing-handball-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-playing-handball-dark-skin-tone.png",
    ["man-playing-handball-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-playing-handball-light-skin-tone.png",
    ["man-playing-handball-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-playing-handball-medium-dark-skin-tone.png",
    ["man-playing-handball-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-playing-handball-medium-light-skin-tone.png",
    ["man-playing-handball-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-playing-handball-medium-skin-tone.png",
    ["man-playing-water-polo-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-playing-water-polo-dark-skin-tone.png",
    ["man-playing-water-polo-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-playing-water-polo-light-skin-tone.png",
    ["man-playing-water-polo-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-playing-water-polo-medium-dark-skin-tone.png",
    ["man-playing-water-polo-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-playing-water-polo-medium-light-skin-tone.png",
    ["man-playing-water-polo-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-playing-water-polo-medium-skin-tone.png",
    ["man-police-officer-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-police-officer-dark-skin-tone.png",
    ["man-police-officer-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-police-officer-light-skin-tone.png",
    ["man-police-officer-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-police-officer-medium-dark-skin-tone.png",
    ["man-police-officer-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-police-officer-medium-light-skin-tone.png",
    ["man-police-officer-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-police-officer-medium-skin-tone.png",
    ["man-pouting-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-pouting-dark-skin-tone.png",
    ["man-pouting-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-pouting-light-skin-tone.png",
    ["man-pouting-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-pouting-medium-dark-skin-tone.png",
    ["man-pouting-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-pouting-medium-light-skin-tone.png",
    ["man-pouting-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-pouting-medium-skin-tone.png",
    ["man-raising-hand-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-raising-hand-dark-skin-tone.png",
    ["man-raising-hand-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-raising-hand-light-skin-tone.png",
    ["man-raising-hand-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-raising-hand-medium-dark-skin-tone.png",
    ["man-raising-hand-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-raising-hand-medium-light-skin-tone.png",
    ["man-raising-hand-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-raising-hand-medium-skin-tone.png",
    ["man-red-haired-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-red-haired-dark-skin-tone.png",
    ["man-red-haired-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-red-haired-light-skin-tone.png",
    ["man-red-haired-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-red-haired-medium-dark-skin-tone.png",
    ["man-red-haired-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-red-haired-medium-light-skin-tone.png",
    ["man-red-haired-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-red-haired-medium-skin-tone.png",
    ["man-rowing-boat-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-rowing-boat-dark-skin-tone.png",
    ["man-rowing-boat-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-rowing-boat-light-skin-tone.png",
    ["man-rowing-boat-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-rowing-boat-medium-dark-skin-tone.png",
    ["man-rowing-boat-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-rowing-boat-medium-light-skin-tone.png",
    ["man-rowing-boat-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-rowing-boat-medium-skin-tone.png",
    ["man-running-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-running-dark-skin-tone.png",
    ["man-running-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-running-light-skin-tone.png",
    ["man-running-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-running-medium-dark-skin-tone.png",
    ["man-running-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-running-medium-light-skin-tone.png",
    ["man-running-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-running-medium-skin-tone.png",
    ["man-scientist-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-scientist-dark-skin-tone.png",
    ["man-scientist-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-scientist-light-skin-tone.png",
    ["man-scientist-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-scientist-medium-dark-skin-tone.png",
    ["man-scientist-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-scientist-medium-light-skin-tone.png",
    ["man-scientist-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-scientist-medium-skin-tone.png",
    ["man-shrugging-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-shrugging-dark-skin-tone.png",
    ["man-shrugging-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-shrugging-light-skin-tone.png",
    ["man-shrugging-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-shrugging-medium-dark-skin-tone.png",
    ["man-shrugging-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-shrugging-medium-light-skin-tone.png",
    ["man-shrugging-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-shrugging-medium-skin-tone.png",
    ["man-singer-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-singer-dark-skin-tone.png",
    ["man-singer-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-singer-light-skin-tone.png",
    ["man-singer-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-singer-medium-dark-skin-tone.png",
    ["man-singer-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-singer-medium-light-skin-tone.png",
    ["man-singer-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-singer-medium-skin-tone.png",
    ["man-standing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-standing-dark-skin-tone.png",
    ["man-standing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-standing-light-skin-tone.png",
    ["man-standing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-standing-medium-dark-skin-tone.png",
    ["man-standing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-standing-medium-light-skin-tone.png",
    ["man-standing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-standing-medium-skin-tone.png",
    ["man-student-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-student-dark-skin-tone.png",
    ["man-student-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-student-light-skin-tone.png",
    ["man-student-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-student-medium-dark-skin-tone.png",
    ["man-student-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-student-medium-light-skin-tone.png",
    ["man-student-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-student-medium-skin-tone.png",
    ["man-superhero-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-superhero-dark-skin-tone.png",
    ["man-superhero-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-superhero-light-skin-tone.png",
    ["man-superhero-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-superhero-medium-dark-skin-tone.png",
    ["man-superhero-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-superhero-medium-light-skin-tone.png",
    ["man-superhero-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-superhero-medium-skin-tone.png",
    ["man-supervillain-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-supervillain-dark-skin-tone.png",
    ["man-supervillain-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-supervillain-light-skin-tone.png",
    ["man-supervillain-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-supervillain-medium-dark-skin-tone.png",
    ["man-supervillain-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-supervillain-medium-light-skin-tone.png",
    ["man-supervillain-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-supervillain-medium-skin-tone.png",
    ["man-surfing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-surfing-dark-skin-tone.png",
    ["man-surfing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-surfing-light-skin-tone.png",
    ["man-surfing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-surfing-medium-dark-skin-tone.png",
    ["man-surfing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-surfing-medium-light-skin-tone.png",
    ["man-surfing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-surfing-medium-skin-tone.png",
    ["man-swimming-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-swimming-dark-skin-tone.png",
    ["man-swimming-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-swimming-light-skin-tone.png",
    ["man-swimming-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-swimming-medium-dark-skin-tone.png",
    ["man-swimming-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-swimming-medium-light-skin-tone.png",
    ["man-swimming-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-swimming-medium-skin-tone.png",
    ["man-teacher-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-teacher-dark-skin-tone.png",
    ["man-teacher-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-teacher-light-skin-tone.png",
    ["man-teacher-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-teacher-medium-dark-skin-tone.png",
    ["man-teacher-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-teacher-medium-light-skin-tone.png",
    ["man-teacher-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-teacher-medium-skin-tone.png",
    ["man-technologist-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-technologist-dark-skin-tone.png",
    ["man-technologist-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-technologist-light-skin-tone.png",
    ["man-technologist-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-technologist-medium-dark-skin-tone.png",
    ["man-technologist-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-technologist-medium-light-skin-tone.png",
    ["man-technologist-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-technologist-medium-skin-tone.png",
    ["man-tipping-hand-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-tipping-hand-dark-skin-tone.png",
    ["man-tipping-hand-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-tipping-hand-light-skin-tone.png",
    ["man-tipping-hand-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-tipping-hand-medium-dark-skin-tone.png",
    ["man-tipping-hand-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-tipping-hand-medium-light-skin-tone.png",
    ["man-tipping-hand-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-tipping-hand-medium-skin-tone.png",
    ["man-vampire-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-vampire-dark-skin-tone.png",
    ["man-vampire-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-vampire-light-skin-tone.png",
    ["man-vampire-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-vampire-medium-dark-skin-tone.png",
    ["man-vampire-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-vampire-medium-light-skin-tone.png",
    ["man-vampire-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-vampire-medium-skin-tone.png",
    ["man-walking-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-walking-dark-skin-tone.png",
    ["man-walking-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-walking-light-skin-tone.png",
    ["man-walking-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-walking-medium-dark-skin-tone.png",
    ["man-walking-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-walking-medium-light-skin-tone.png",
    ["man-walking-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-walking-medium-skin-tone.png",
    ["man-wearing-turban-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-wearing-turban-dark-skin-tone.png",
    ["man-wearing-turban-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-wearing-turban-light-skin-tone.png",
    ["man-wearing-turban-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-wearing-turban-medium-dark-skin-tone.png",
    ["man-wearing-turban-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-wearing-turban-medium-light-skin-tone.png",
    ["man-wearing-turban-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-wearing-turban-medium-skin-tone.png",
    ["man-white-haired-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-white-haired-dark-skin-tone.png",
    ["man-white-haired-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-white-haired-light-skin-tone.png",
    ["man-white-haired-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-white-haired-medium-dark-skin-tone.png",
    ["man-white-haired-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-white-haired-medium-light-skin-tone.png",
    ["man-white-haired-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-white-haired-medium-skin-tone.png",
    ["man-with-chinese-cap-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-chinese-cap-dark-skin-tone.png",
    ["man-with-chinese-cap-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-chinese-cap-light-skin-tone.png",
    ["man-with-chinese-cap-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-chinese-cap-medium-dark-skin-tone.png",
    ["man-with-chinese-cap-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-chinese-cap-medium-light-skin-tone.png",
    ["man-with-chinese-cap-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-chinese-cap-medium-skin-tone.png",
    ["man-with-probing-cane-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-probing-cane-dark-skin-tone.png",
    ["man-with-probing-cane-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-probing-cane-light-skin-tone.png",
    ["man-with-probing-cane-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-probing-cane-medium-dark-skin-tone.png",
    ["man-with-probing-cane-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-probing-cane-medium-light-skin-tone.png",
    ["man-with-probing-cane-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-probing-cane-medium-skin-tone.png",
    ["man-with-veil-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-veil-dark-skin-tone.png",
    ["man-with-veil-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-veil-light-skin-tone.png",
    ["man-with-veil-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-veil-medium-dark-skin-tone.png",
    ["man-with-veil-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-veil-medium-light-skin-tone.png",
    ["man-with-veil-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/man-with-veil-medium-skin-tone.png",
    ["mechanic-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mechanic-dark-skin-tone.png",
    ["mechanic-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mechanic-light-skin-tone.png",
    ["mechanic-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mechanic-medium-dark-skin-tone.png",
    ["mechanic-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mechanic-medium-light-skin-tone.png",
    ["mechanic-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mechanic-medium-skin-tone.png",
    ["medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/medium-dark-skin-tone.png",
    ["medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/medium-light-skin-tone.png",
    ["medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/medium-skin-tone.png",
    ["men-holding-hands-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-dark-skin-tone-light-skin-tone.png",
    ["men-holding-hands-dark-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-dark-skin-tone-medium-dark-skin-tone.png",
    ["men-holding-hands-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-dark-skin-tone-medium-light-skin-tone.png",
    ["men-holding-hands-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-dark-skin-tone-medium-skin-tone.png",
    ["men-holding-hands-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-dark-skin-tone.png",
    ["men-holding-hands-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-light-skin-tone-dark-skin-tone.png",
    ["men-holding-hands-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-light-skin-tone-medium-dark-skin-tone.png",
    ["men-holding-hands-light-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-light-skin-tone-medium-light-skin-tone.png",
    ["men-holding-hands-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-light-skin-tone-medium-skin-tone.png",
    ["men-holding-hands-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-light-skin-tone.png",
    ["men-holding-hands-medium-dark-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-dark-skin-tone-dark-skin-tone.png",
    ["men-holding-hands-medium-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-dark-skin-tone-light-skin-tone.png",
    ["men-holding-hands-medium-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-dark-skin-tone-medium-light-skin-tone.png",
    ["men-holding-hands-medium-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-dark-skin-tone-medium-skin-tone.png",
    ["men-holding-hands-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-dark-skin-tone.png",
    ["men-holding-hands-medium-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-light-skin-tone-dark-skin-tone.png",
    ["men-holding-hands-medium-light-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-light-skin-tone-light-skin-tone.png",
    ["men-holding-hands-medium-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-light-skin-tone-medium-dark-skin-tone.png",
    ["men-holding-hands-medium-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-light-skin-tone-medium-skin-tone.png",
    ["men-holding-hands-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-light-skin-tone.png",
    ["men-holding-hands-medium-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-skin-tone-dark-skin-tone.png",
    ["men-holding-hands-medium-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-skin-tone-light-skin-tone.png",
    ["men-holding-hands-medium-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-skin-tone-medium-dark-skin-tone.png",
    ["men-holding-hands-medium-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-skin-tone-medium-light-skin-tone.png",
    ["men-holding-hands-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/men-holding-hands-medium-skin-tone.png",
    ["mermaid-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mermaid-dark-skin-tone.png",
    ["mermaid-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mermaid-light-skin-tone.png",
    ["mermaid-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mermaid-medium-dark-skin-tone.png",
    ["mermaid-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mermaid-medium-light-skin-tone.png",
    ["mermaid-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mermaid-medium-skin-tone.png",
    ["merman-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/merman-dark-skin-tone.png",
    ["merman-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/merman-light-skin-tone.png",
    ["merman-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/merman-medium-dark-skin-tone.png",
    ["merman-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/merman-medium-light-skin-tone.png",
    ["merman-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/merman-medium-skin-tone.png",
    ["merperson-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/merperson-dark-skin-tone.png",
    ["merperson-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/merperson-light-skin-tone.png",
    ["merperson-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/merperson-medium-dark-skin-tone.png",
    ["merperson-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/merperson-medium-light-skin-tone.png",
    ["merperson-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/merperson-medium-skin-tone.png",
    ["middle-finger-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/middle-finger-dark-skin-tone.png",
    ["middle-finger-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/middle-finger-light-skin-tone.png",
    ["middle-finger-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/middle-finger-medium-dark-skin-tone.png",
    ["middle-finger-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/middle-finger-medium-light-skin-tone.png",
    ["middle-finger-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/middle-finger-medium-skin-tone.png",
    ["mrs-claus-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mrs-claus-dark-skin-tone.png",
    ["mrs-claus-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mrs-claus-light-skin-tone.png",
    ["mrs-claus-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mrs-claus-medium-dark-skin-tone.png",
    ["mrs-claus-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mrs-claus-medium-light-skin-tone.png",
    ["mrs-claus-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mrs-claus-medium-skin-tone.png",
    ["mx-claus-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mx-claus-dark-skin-tone.png",
    ["mx-claus-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mx-claus-light-skin-tone.png",
    ["mx-claus-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mx-claus-medium-dark-skin-tone.png",
    ["mx-claus-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mx-claus-medium-light-skin-tone.png",
    ["mx-claus-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/mx-claus-medium-skin-tone.png",
    ["nail-polish-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/nail-polish-dark-skin-tone.png",
    ["nail-polish-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/nail-polish-light-skin-tone.png",
    ["nail-polish-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/nail-polish-medium-dark-skin-tone.png",
    ["nail-polish-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/nail-polish-medium-light-skin-tone.png",
    ["nail-polish-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/nail-polish-medium-skin-tone.png",
    ["ninja-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ninja-dark-skin-tone.png",
    ["ninja-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ninja-light-skin-tone.png",
    ["ninja-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ninja-medium-dark-skin-tone.png",
    ["ninja-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ninja-medium-light-skin-tone.png",
    ["ninja-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ninja-medium-skin-tone.png",
    ["nose-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/nose-dark-skin-tone.png",
    ["nose-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/nose-light-skin-tone.png",
    ["nose-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/nose-medium-dark-skin-tone.png",
    ["nose-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/nose-medium-light-skin-tone.png",
    ["nose-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/nose-medium-skin-tone.png",
    ["office-worker-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/office-worker-dark-skin-tone.png",
    ["office-worker-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/office-worker-light-skin-tone.png",
    ["office-worker-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/office-worker-medium-dark-skin-tone.png",
    ["office-worker-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/office-worker-medium-light-skin-tone.png",
    ["office-worker-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/office-worker-medium-skin-tone.png",
    ["ok-hand-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ok-hand-dark-skin-tone.png",
    ["ok-hand-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ok-hand-light-skin-tone.png",
    ["ok-hand-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ok-hand-medium-dark-skin-tone.png",
    ["ok-hand-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ok-hand-medium-light-skin-tone.png",
    ["ok-hand-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/ok-hand-medium-skin-tone.png",
    ["old-man-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/old-man-dark-skin-tone.png",
    ["old-man-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/old-man-light-skin-tone.png",
    ["old-man-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/old-man-medium-dark-skin-tone.png",
    ["old-man-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/old-man-medium-light-skin-tone.png",
    ["old-man-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/old-man-medium-skin-tone.png",
    ["old-woman-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/old-woman-dark-skin-tone.png",
    ["old-woman-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/old-woman-light-skin-tone.png",
    ["old-woman-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/old-woman-medium-dark-skin-tone.png",
    ["old-woman-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/old-woman-medium-light-skin-tone.png",
    ["old-woman-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/old-woman-medium-skin-tone.png",
    ["older-person-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/older-person-dark-skin-tone.png",
    ["older-person-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/older-person-light-skin-tone.png",
    ["older-person-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/older-person-medium-dark-skin-tone.png",
    ["older-person-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/older-person-medium-light-skin-tone.png",
    ["older-person-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/older-person-medium-skin-tone.png",
    ["oncoming-fist-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/oncoming-fist-dark-skin-tone.png",
    ["oncoming-fist-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/oncoming-fist-light-skin-tone.png",
    ["oncoming-fist-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/oncoming-fist-medium-dark-skin-tone.png",
    ["oncoming-fist-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/oncoming-fist-medium-light-skin-tone.png",
    ["oncoming-fist-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/oncoming-fist-medium-skin-tone.png",
    ["open-hands-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/open-hands-dark-skin-tone.png",
    ["open-hands-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/open-hands-light-skin-tone.png",
    ["open-hands-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/open-hands-medium-dark-skin-tone.png",
    ["open-hands-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/open-hands-medium-light-skin-tone.png",
    ["open-hands-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/open-hands-medium-skin-tone.png",
    ["palms-up-together-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/palms-up-together-dark-skin-tone.png",
    ["palms-up-together-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/palms-up-together-light-skin-tone.png",
    ["palms-up-together-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/palms-up-together-medium-dark-skin-tone.png",
    ["palms-up-together-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/palms-up-together-medium-light-skin-tone.png",
    ["palms-up-together-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/palms-up-together-medium-skin-tone.png",
    ["people-holding-hands-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-dark-skin-tone-light-skin-tone.png",
    ["people-holding-hands-dark-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-dark-skin-tone-medium-dark-skin-tone.png",
    ["people-holding-hands-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-dark-skin-tone-medium-light-skin-tone.png",
    ["people-holding-hands-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-dark-skin-tone-medium-skin-tone.png",
    ["people-holding-hands-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-dark-skin-tone.png",
    ["people-holding-hands-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-light-skin-tone-dark-skin-tone.png",
    ["people-holding-hands-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-light-skin-tone-medium-dark-skin-tone.png",
    ["people-holding-hands-light-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-light-skin-tone-medium-light-skin-tone.png",
    ["people-holding-hands-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-light-skin-tone-medium-skin-tone.png",
    ["people-holding-hands-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-light-skin-tone.png",
    ["people-holding-hands-medium-dark-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-dark-skin-tone-dark-skin-tone.png",
    ["people-holding-hands-medium-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-dark-skin-tone-light-skin-tone.png",
    ["people-holding-hands-medium-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-dark-skin-tone-medium-light-skin-tone.png",
    ["people-holding-hands-medium-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-dark-skin-tone-medium-skin-tone.png",
    ["people-holding-hands-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-dark-skin-tone.png",
    ["people-holding-hands-medium-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-light-skin-tone-dark-skin-tone.png",
    ["people-holding-hands-medium-light-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-light-skin-tone-light-skin-tone.png",
    ["people-holding-hands-medium-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-light-skin-tone-medium-dark-skin-tone.png",
    ["people-holding-hands-medium-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-light-skin-tone-medium-skin-tone.png",
    ["people-holding-hands-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-light-skin-tone.png",
    ["people-holding-hands-medium-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-skin-tone-dark-skin-tone.png",
    ["people-holding-hands-medium-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-skin-tone-light-skin-tone.png",
    ["people-holding-hands-medium-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-skin-tone-medium-dark-skin-tone.png",
    ["people-holding-hands-medium-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-skin-tone-medium-light-skin-tone.png",
    ["people-holding-hands-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/people-holding-hands-medium-skin-tone.png",
    ["person-biking-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-biking-dark-skin-tone.png",
    ["person-biking-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-biking-light-skin-tone.png",
    ["person-biking-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-biking-medium-dark-skin-tone.png",
    ["person-biking-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-biking-medium-light-skin-tone.png",
    ["person-biking-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-biking-medium-skin-tone.png",
    ["person-bouncing-ball-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-bouncing-ball-dark-skin-tone.png",
    ["person-bouncing-ball-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-bouncing-ball-light-skin-tone.png",
    ["person-bouncing-ball-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-bouncing-ball-medium-dark-skin-tone.png",
    ["person-bouncing-ball-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-bouncing-ball-medium-light-skin-tone.png",
    ["person-bouncing-ball-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-bouncing-ball-medium-skin-tone.png",
    ["person-bowing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-bowing-dark-skin-tone.png",
    ["person-bowing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-bowing-light-skin-tone.png",
    ["person-bowing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-bowing-medium-dark-skin-tone.png",
    ["person-bowing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-bowing-medium-light-skin-tone.png",
    ["person-bowing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-bowing-medium-skin-tone.png",
    ["person-cartwheeling-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-cartwheeling-dark-skin-tone.png",
    ["person-cartwheeling-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-cartwheeling-light-skin-tone.png",
    ["person-cartwheeling-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-cartwheeling-medium-dark-skin-tone.png",
    ["person-cartwheeling-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-cartwheeling-medium-light-skin-tone.png",
    ["person-cartwheeling-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-cartwheeling-medium-skin-tone.png",
    ["person-climbing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-climbing-dark-skin-tone.png",
    ["person-climbing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-climbing-light-skin-tone.png",
    ["person-climbing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-climbing-medium-dark-skin-tone.png",
    ["person-climbing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-climbing-medium-light-skin-tone.png",
    ["person-climbing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-climbing-medium-skin-tone.png",
    ["person-dark-skin-tone-bald.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-dark-skin-tone-bald.png",
    ["person-dark-skin-tone-curly-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-dark-skin-tone-curly-hair.png",
    ["person-dark-skin-tone-red-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-dark-skin-tone-red-hair.png",
    ["person-dark-skin-tone-white-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-dark-skin-tone-white-hair.png",
    ["person-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-dark-skin-tone.png",
    ["person-facepalming-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-facepalming-dark-skin-tone.png",
    ["person-facepalming-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-facepalming-light-skin-tone.png",
    ["person-facepalming-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-facepalming-medium-dark-skin-tone.png",
    ["person-facepalming-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-facepalming-medium-light-skin-tone.png",
    ["person-facepalming-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-facepalming-medium-skin-tone.png",
    ["person-feeding-baby-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-feeding-baby-dark-skin-tone.png",
    ["person-feeding-baby-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-feeding-baby-light-skin-tone.png",
    ["person-feeding-baby-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-feeding-baby-medium-dark-skin-tone.png",
    ["person-feeding-baby-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-feeding-baby-medium-light-skin-tone.png",
    ["person-feeding-baby-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-feeding-baby-medium-skin-tone.png",
    ["person-frowning-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-frowning-dark-skin-tone.png",
    ["person-frowning-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-frowning-light-skin-tone.png",
    ["person-frowning-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-frowning-medium-dark-skin-tone.png",
    ["person-frowning-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-frowning-medium-light-skin-tone.png",
    ["person-frowning-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-frowning-medium-skin-tone.png",
    ["person-gesturing-no-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-gesturing-no-dark-skin-tone.png",
    ["person-gesturing-no-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-gesturing-no-light-skin-tone.png",
    ["person-gesturing-no-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-gesturing-no-medium-dark-skin-tone.png",
    ["person-gesturing-no-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-gesturing-no-medium-light-skin-tone.png",
    ["person-gesturing-no-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-gesturing-no-medium-skin-tone.png",
    ["person-gesturing-ok-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-gesturing-ok-dark-skin-tone.png",
    ["person-gesturing-ok-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-gesturing-ok-light-skin-tone.png",
    ["person-gesturing-ok-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-gesturing-ok-medium-dark-skin-tone.png",
    ["person-gesturing-ok-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-gesturing-ok-medium-light-skin-tone.png",
    ["person-gesturing-ok-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-gesturing-ok-medium-skin-tone.png",
    ["person-getting-haircut-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-getting-haircut-dark-skin-tone.png",
    ["person-getting-haircut-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-getting-haircut-light-skin-tone.png",
    ["person-getting-haircut-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-getting-haircut-medium-dark-skin-tone.png",
    ["person-getting-haircut-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-getting-haircut-medium-light-skin-tone.png",
    ["person-getting-haircut-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-getting-haircut-medium-skin-tone.png",
    ["person-getting-massage-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-getting-massage-dark-skin-tone.png",
    ["person-getting-massage-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-getting-massage-light-skin-tone.png",
    ["person-getting-massage-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-getting-massage-medium-dark-skin-tone.png",
    ["person-getting-massage-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-getting-massage-medium-light-skin-tone.png",
    ["person-getting-massage-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-getting-massage-medium-skin-tone.png",
    ["person-golfing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-golfing-dark-skin-tone.png",
    ["person-golfing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-golfing-light-skin-tone.png",
    ["person-golfing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-golfing-medium-dark-skin-tone.png",
    ["person-golfing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-golfing-medium-light-skin-tone.png",
    ["person-golfing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-golfing-medium-skin-tone.png",
    ["person-in-bed-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-bed-dark-skin-tone.png",
    ["person-in-bed-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-bed-light-skin-tone.png",
    ["person-in-bed-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-bed-medium-dark-skin-tone.png",
    ["person-in-bed-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-bed-medium-light-skin-tone.png",
    ["person-in-bed-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-bed-medium-skin-tone.png",
    ["person-in-lotus-position-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-lotus-position-dark-skin-tone.png",
    ["person-in-lotus-position-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-lotus-position-light-skin-tone.png",
    ["person-in-lotus-position-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-lotus-position-medium-dark-skin-tone.png",
    ["person-in-lotus-position-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-lotus-position-medium-light-skin-tone.png",
    ["person-in-lotus-position-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-lotus-position-medium-skin-tone.png",
    ["person-in-manual-wheelchair-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-manual-wheelchair-dark-skin-tone.png",
    ["person-in-manual-wheelchair-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-manual-wheelchair-light-skin-tone.png",
    ["person-in-manual-wheelchair-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-manual-wheelchair-medium-dark-skin-tone.png",
    ["person-in-manual-wheelchair-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-manual-wheelchair-medium-light-skin-tone.png",
    ["person-in-manual-wheelchair-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-manual-wheelchair-medium-skin-tone.png",
    ["person-in-motorized-wheelchair-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-motorized-wheelchair-dark-skin-tone.png",
    ["person-in-motorized-wheelchair-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-motorized-wheelchair-light-skin-tone.png",
    ["person-in-motorized-wheelchair-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-motorized-wheelchair-medium-dark-skin-tone.png",
    ["person-in-motorized-wheelchair-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-motorized-wheelchair-medium-light-skin-tone.png",
    ["person-in-motorized-wheelchair-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-motorized-wheelchair-medium-skin-tone.png",
    ["person-in-steamy-room-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-steamy-room-dark-skin-tone.png",
    ["person-in-steamy-room-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-steamy-room-light-skin-tone.png",
    ["person-in-steamy-room-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-steamy-room-medium-dark-skin-tone.png",
    ["person-in-steamy-room-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-steamy-room-medium-light-skin-tone.png",
    ["person-in-steamy-room-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-steamy-room-medium-skin-tone.png",
    ["person-in-tuxedo-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-tuxedo-dark-skin-tone.png",
    ["person-in-tuxedo-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-tuxedo-light-skin-tone.png",
    ["person-in-tuxedo-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-tuxedo-medium-dark-skin-tone.png",
    ["person-in-tuxedo-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-tuxedo-medium-light-skin-tone.png",
    ["person-in-tuxedo-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-in-tuxedo-medium-skin-tone.png",
    ["person-juggling-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-juggling-dark-skin-tone.png",
    ["person-juggling-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-juggling-light-skin-tone.png",
    ["person-juggling-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-juggling-medium-dark-skin-tone.png",
    ["person-juggling-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-juggling-medium-light-skin-tone.png",
    ["person-juggling-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-juggling-medium-skin-tone.png",
    ["person-kneeling-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-kneeling-dark-skin-tone.png",
    ["person-kneeling-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-kneeling-light-skin-tone.png",
    ["person-kneeling-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-kneeling-medium-dark-skin-tone.png",
    ["person-kneeling-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-kneeling-medium-light-skin-tone.png",
    ["person-kneeling-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-kneeling-medium-skin-tone.png",
    ["person-lifting-weights-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-lifting-weights-dark-skin-tone.png",
    ["person-lifting-weights-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-lifting-weights-light-skin-tone.png",
    ["person-lifting-weights-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-lifting-weights-medium-dark-skin-tone.png",
    ["person-lifting-weights-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-lifting-weights-medium-light-skin-tone.png",
    ["person-lifting-weights-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-lifting-weights-medium-skin-tone.png",
    ["person-light-skin-tone-bald.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-light-skin-tone-bald.png",
    ["person-light-skin-tone-curly-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-light-skin-tone-curly-hair.png",
    ["person-light-skin-tone-red-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-light-skin-tone-red-hair.png",
    ["person-light-skin-tone-white-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-light-skin-tone-white-hair.png",
    ["person-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-light-skin-tone.png",
    ["person-medium-dark-skin-tone-bald.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-dark-skin-tone-bald.png",
    ["person-medium-dark-skin-tone-curly-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-dark-skin-tone-curly-hair.png",
    ["person-medium-dark-skin-tone-red-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-dark-skin-tone-red-hair.png",
    ["person-medium-dark-skin-tone-white-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-dark-skin-tone-white-hair.png",
    ["person-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-dark-skin-tone.png",
    ["person-medium-light-skin-tone-bald.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-light-skin-tone-bald.png",
    ["person-medium-light-skin-tone-curly-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-light-skin-tone-curly-hair.png",
    ["person-medium-light-skin-tone-red-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-light-skin-tone-red-hair.png",
    ["person-medium-light-skin-tone-white-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-light-skin-tone-white-hair.png",
    ["person-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-light-skin-tone.png",
    ["person-medium-skin-tone-bald.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-skin-tone-bald.png",
    ["person-medium-skin-tone-curly-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-skin-tone-curly-hair.png",
    ["person-medium-skin-tone-red-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-skin-tone-red-hair.png",
    ["person-medium-skin-tone-white-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-skin-tone-white-hair.png",
    ["person-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-medium-skin-tone.png",
    ["person-mountain-biking-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-mountain-biking-dark-skin-tone.png",
    ["person-mountain-biking-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-mountain-biking-light-skin-tone.png",
    ["person-mountain-biking-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-mountain-biking-medium-dark-skin-tone.png",
    ["person-mountain-biking-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-mountain-biking-medium-light-skin-tone.png",
    ["person-mountain-biking-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-mountain-biking-medium-skin-tone.png",
    ["person-playing-handball-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-playing-handball-dark-skin-tone.png",
    ["person-playing-handball-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-playing-handball-light-skin-tone.png",
    ["person-playing-handball-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-playing-handball-medium-dark-skin-tone.png",
    ["person-playing-handball-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-playing-handball-medium-light-skin-tone.png",
    ["person-playing-handball-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-playing-handball-medium-skin-tone.png",
    ["person-playing-water-polo-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-playing-water-polo-dark-skin-tone.png",
    ["person-playing-water-polo-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-playing-water-polo-light-skin-tone.png",
    ["person-playing-water-polo-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-playing-water-polo-medium-dark-skin-tone.png",
    ["person-playing-water-polo-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-playing-water-polo-medium-light-skin-tone.png",
    ["person-playing-water-polo-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-playing-water-polo-medium-skin-tone.png",
    ["person-pouting-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-pouting-dark-skin-tone.png",
    ["person-pouting-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-pouting-light-skin-tone.png",
    ["person-pouting-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-pouting-medium-dark-skin-tone.png",
    ["person-pouting-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-pouting-medium-light-skin-tone.png",
    ["person-pouting-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-pouting-medium-skin-tone.png",
    ["person-raising-hand-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-raising-hand-dark-skin-tone.png",
    ["person-raising-hand-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-raising-hand-light-skin-tone.png",
    ["person-raising-hand-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-raising-hand-medium-dark-skin-tone.png",
    ["person-raising-hand-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-raising-hand-medium-light-skin-tone.png",
    ["person-raising-hand-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-raising-hand-medium-skin-tone.png",
    ["person-rowing-boat-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-rowing-boat-dark-skin-tone.png",
    ["person-rowing-boat-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-rowing-boat-light-skin-tone.png",
    ["person-rowing-boat-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-rowing-boat-medium-dark-skin-tone.png",
    ["person-rowing-boat-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-rowing-boat-medium-light-skin-tone.png",
    ["person-rowing-boat-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-rowing-boat-medium-skin-tone.png",
    ["person-running-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-running-dark-skin-tone.png",
    ["person-running-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-running-light-skin-tone.png",
    ["person-running-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-running-medium-dark-skin-tone.png",
    ["person-running-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-running-medium-light-skin-tone.png",
    ["person-running-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-running-medium-skin-tone.png",
    ["person-shrugging-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-shrugging-dark-skin-tone.png",
    ["person-shrugging-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-shrugging-light-skin-tone.png",
    ["person-shrugging-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-shrugging-medium-dark-skin-tone.png",
    ["person-shrugging-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-shrugging-medium-light-skin-tone.png",
    ["person-shrugging-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-shrugging-medium-skin-tone.png",
    ["person-standing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-standing-dark-skin-tone.png",
    ["person-standing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-standing-light-skin-tone.png",
    ["person-standing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-standing-medium-dark-skin-tone.png",
    ["person-standing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-standing-medium-light-skin-tone.png",
    ["person-standing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-standing-medium-skin-tone.png",
    ["person-surfing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-surfing-dark-skin-tone.png",
    ["person-surfing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-surfing-light-skin-tone.png",
    ["person-surfing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-surfing-medium-dark-skin-tone.png",
    ["person-surfing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-surfing-medium-light-skin-tone.png",
    ["person-surfing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-surfing-medium-skin-tone.png",
    ["person-swimming-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-swimming-dark-skin-tone.png",
    ["person-swimming-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-swimming-light-skin-tone.png",
    ["person-swimming-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-swimming-medium-dark-skin-tone.png",
    ["person-swimming-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-swimming-medium-light-skin-tone.png",
    ["person-swimming-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-swimming-medium-skin-tone.png",
    ["person-taking-bath-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-taking-bath-dark-skin-tone.png",
    ["person-taking-bath-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-taking-bath-light-skin-tone.png",
    ["person-taking-bath-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-taking-bath-medium-dark-skin-tone.png",
    ["person-taking-bath-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-taking-bath-medium-light-skin-tone.png",
    ["person-taking-bath-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-taking-bath-medium-skin-tone.png",
    ["person-tipping-hand-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-tipping-hand-dark-skin-tone.png",
    ["person-tipping-hand-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-tipping-hand-light-skin-tone.png",
    ["person-tipping-hand-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-tipping-hand-medium-dark-skin-tone.png",
    ["person-tipping-hand-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-tipping-hand-medium-light-skin-tone.png",
    ["person-tipping-hand-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-tipping-hand-medium-skin-tone.png",
    ["person-walking-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-walking-dark-skin-tone.png",
    ["person-walking-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-walking-light-skin-tone.png",
    ["person-walking-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-walking-medium-dark-skin-tone.png",
    ["person-walking-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-walking-medium-light-skin-tone.png",
    ["person-walking-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-walking-medium-skin-tone.png",
    ["person-wearing-turban-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-wearing-turban-dark-skin-tone.png",
    ["person-wearing-turban-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-wearing-turban-light-skin-tone.png",
    ["person-wearing-turban-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-wearing-turban-medium-dark-skin-tone.png",
    ["person-wearing-turban-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-wearing-turban-medium-light-skin-tone.png",
    ["person-wearing-turban-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-wearing-turban-medium-skin-tone.png",
    ["person-with-probing-cane-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-with-probing-cane-dark-skin-tone.png",
    ["person-with-probing-cane-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-with-probing-cane-light-skin-tone.png",
    ["person-with-probing-cane-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-with-probing-cane-medium-dark-skin-tone.png",
    ["person-with-probing-cane-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-with-probing-cane-medium-light-skin-tone.png",
    ["person-with-probing-cane-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-with-probing-cane-medium-skin-tone.png",
    ["person-with-veil-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-with-veil-dark-skin-tone.png",
    ["person-with-veil-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-with-veil-light-skin-tone.png",
    ["person-with-veil-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-with-veil-medium-dark-skin-tone.png",
    ["person-with-veil-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-with-veil-medium-light-skin-tone.png",
    ["person-with-veil-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/person-with-veil-medium-skin-tone.png",
    ["pilot-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pilot-dark-skin-tone.png",
    ["pilot-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pilot-light-skin-tone.png",
    ["pilot-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pilot-medium-dark-skin-tone.png",
    ["pilot-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pilot-medium-light-skin-tone.png",
    ["pilot-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pilot-medium-skin-tone.png",
    ["pinched-fingers-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pinched-fingers-dark-skin-tone.png",
    ["pinched-fingers-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pinched-fingers-light-skin-tone.png",
    ["pinched-fingers-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pinched-fingers-medium-dark-skin-tone.png",
    ["pinched-fingers-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pinched-fingers-medium-light-skin-tone.png",
    ["pinched-fingers-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pinched-fingers-medium-skin-tone.png",
    ["pinching-hand-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pinching-hand-dark-skin-tone.png",
    ["pinching-hand-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pinching-hand-light-skin-tone.png",
    ["pinching-hand-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pinching-hand-medium-dark-skin-tone.png",
    ["pinching-hand-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pinching-hand-medium-light-skin-tone.png",
    ["pinching-hand-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pinching-hand-medium-skin-tone.png",
    ["police-officer-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/police-officer-dark-skin-tone.png",
    ["police-officer-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/police-officer-light-skin-tone.png",
    ["police-officer-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/police-officer-medium-dark-skin-tone.png",
    ["police-officer-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/police-officer-medium-light-skin-tone.png",
    ["police-officer-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/police-officer-medium-skin-tone.png",
    ["pregnant-woman-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pregnant-woman-dark-skin-tone.png",
    ["pregnant-woman-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pregnant-woman-light-skin-tone.png",
    ["pregnant-woman-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pregnant-woman-medium-dark-skin-tone.png",
    ["pregnant-woman-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pregnant-woman-medium-light-skin-tone.png",
    ["pregnant-woman-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/pregnant-woman-medium-skin-tone.png",
    ["prince-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/prince-dark-skin-tone.png",
    ["prince-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/prince-light-skin-tone.png",
    ["prince-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/prince-medium-dark-skin-tone.png",
    ["prince-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/prince-medium-light-skin-tone.png",
    ["prince-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/prince-medium-skin-tone.png",
    ["princess-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/princess-dark-skin-tone.png",
    ["princess-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/princess-light-skin-tone.png",
    ["princess-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/princess-medium-dark-skin-tone.png",
    ["princess-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/princess-medium-light-skin-tone.png",
    ["princess-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/princess-medium-skin-tone.png",
    ["raised-back-of-hand-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-back-of-hand-dark-skin-tone.png",
    ["raised-back-of-hand-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-back-of-hand-light-skin-tone.png",
    ["raised-back-of-hand-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-back-of-hand-medium-dark-skin-tone.png",
    ["raised-back-of-hand-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-back-of-hand-medium-light-skin-tone.png",
    ["raised-back-of-hand-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-back-of-hand-medium-skin-tone.png",
    ["raised-fist-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-fist-dark-skin-tone.png",
    ["raised-fist-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-fist-light-skin-tone.png",
    ["raised-fist-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-fist-medium-dark-skin-tone.png",
    ["raised-fist-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-fist-medium-light-skin-tone.png",
    ["raised-fist-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-fist-medium-skin-tone.png",
    ["raised-hand-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-hand-dark-skin-tone.png",
    ["raised-hand-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-hand-light-skin-tone.png",
    ["raised-hand-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-hand-medium-dark-skin-tone.png",
    ["raised-hand-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-hand-medium-light-skin-tone.png",
    ["raised-hand-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raised-hand-medium-skin-tone.png",
    ["raising-hands-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raising-hands-dark-skin-tone.png",
    ["raising-hands-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raising-hands-light-skin-tone.png",
    ["raising-hands-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raising-hands-medium-dark-skin-tone.png",
    ["raising-hands-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raising-hands-medium-light-skin-tone.png",
    ["raising-hands-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/raising-hands-medium-skin-tone.png",
    ["right-facing-fist-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/right-facing-fist-dark-skin-tone.png",
    ["right-facing-fist-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/right-facing-fist-light-skin-tone.png",
    ["right-facing-fist-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/right-facing-fist-medium-dark-skin-tone.png",
    ["right-facing-fist-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/right-facing-fist-medium-light-skin-tone.png",
    ["right-facing-fist-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/right-facing-fist-medium-skin-tone.png",
    ["santa-claus-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/santa-claus-dark-skin-tone.png",
    ["santa-claus-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/santa-claus-light-skin-tone.png",
    ["santa-claus-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/santa-claus-medium-dark-skin-tone.png",
    ["santa-claus-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/santa-claus-medium-light-skin-tone.png",
    ["santa-claus-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/santa-claus-medium-skin-tone.png",
    ["scientist-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/scientist-dark-skin-tone.png",
    ["scientist-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/scientist-light-skin-tone.png",
    ["scientist-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/scientist-medium-dark-skin-tone.png",
    ["scientist-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/scientist-medium-light-skin-tone.png",
    ["scientist-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/scientist-medium-skin-tone.png",
    ["selfie-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/selfie-dark-skin-tone.png",
    ["selfie-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/selfie-light-skin-tone.png",
    ["selfie-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/selfie-medium-dark-skin-tone.png",
    ["selfie-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/selfie-medium-light-skin-tone.png",
    ["selfie-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/selfie-medium-skin-tone.png",
    ["sign-of-the-horns-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/sign-of-the-horns-dark-skin-tone.png",
    ["sign-of-the-horns-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/sign-of-the-horns-light-skin-tone.png",
    ["sign-of-the-horns-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/sign-of-the-horns-medium-dark-skin-tone.png",
    ["sign-of-the-horns-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/sign-of-the-horns-medium-light-skin-tone.png",
    ["sign-of-the-horns-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/sign-of-the-horns-medium-skin-tone.png",
    ["singer-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/singer-dark-skin-tone.png",
    ["singer-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/singer-light-skin-tone.png",
    ["singer-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/singer-medium-dark-skin-tone.png",
    ["singer-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/singer-medium-light-skin-tone.png",
    ["singer-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/singer-medium-skin-tone.png",
    ["snowboarder-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/snowboarder-dark-skin-tone.png",
    ["snowboarder-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/snowboarder-light-skin-tone.png",
    ["snowboarder-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/snowboarder-medium-dark-skin-tone.png",
    ["snowboarder-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/snowboarder-medium-light-skin-tone.png",
    ["snowboarder-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/snowboarder-medium-skin-tone.png",
    ["student-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/student-dark-skin-tone.png",
    ["student-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/student-light-skin-tone.png",
    ["student-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/student-medium-dark-skin-tone.png",
    ["student-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/student-medium-light-skin-tone.png",
    ["student-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/student-medium-skin-tone.png",
    ["superhero-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/superhero-dark-skin-tone.png",
    ["superhero-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/superhero-light-skin-tone.png",
    ["superhero-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/superhero-medium-dark-skin-tone.png",
    ["superhero-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/superhero-medium-light-skin-tone.png",
    ["superhero-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/superhero-medium-skin-tone.png",
    ["supervillain-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/supervillain-dark-skin-tone.png",
    ["supervillain-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/supervillain-light-skin-tone.png",
    ["supervillain-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/supervillain-medium-dark-skin-tone.png",
    ["supervillain-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/supervillain-medium-light-skin-tone.png",
    ["supervillain-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/supervillain-medium-skin-tone.png",
    ["teacher-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/teacher-dark-skin-tone.png",
    ["teacher-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/teacher-light-skin-tone.png",
    ["teacher-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/teacher-medium-dark-skin-tone.png",
    ["teacher-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/teacher-medium-light-skin-tone.png",
    ["teacher-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/teacher-medium-skin-tone.png",
    ["technologist-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/technologist-dark-skin-tone.png",
    ["technologist-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/technologist-light-skin-tone.png",
    ["technologist-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/technologist-medium-dark-skin-tone.png",
    ["technologist-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/technologist-medium-light-skin-tone.png",
    ["technologist-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/technologist-medium-skin-tone.png",
    ["thumbs-down-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/thumbs-down-dark-skin-tone.png",
    ["thumbs-down-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/thumbs-down-light-skin-tone.png",
    ["thumbs-down-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/thumbs-down-medium-dark-skin-tone.png",
    ["thumbs-down-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/thumbs-down-medium-light-skin-tone.png",
    ["thumbs-down-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/thumbs-down-medium-skin-tone.png",
    ["thumbs-up-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/thumbs-up-dark-skin-tone.png",
    ["thumbs-up-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/thumbs-up-light-skin-tone.png",
    ["thumbs-up-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/thumbs-up-medium-dark-skin-tone.png",
    ["thumbs-up-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/thumbs-up-medium-light-skin-tone.png",
    ["thumbs-up-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/thumbs-up-medium-skin-tone.png",
    ["vampire-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/vampire-dark-skin-tone.png",
    ["vampire-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/vampire-light-skin-tone.png",
    ["vampire-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/vampire-medium-dark-skin-tone.png",
    ["vampire-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/vampire-medium-light-skin-tone.png",
    ["vampire-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/vampire-medium-skin-tone.png",
    ["victory-hand-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/victory-hand-dark-skin-tone.png",
    ["victory-hand-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/victory-hand-light-skin-tone.png",
    ["victory-hand-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/victory-hand-medium-dark-skin-tone.png",
    ["victory-hand-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/victory-hand-medium-light-skin-tone.png",
    ["victory-hand-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/victory-hand-medium-skin-tone.png",
    ["vulcan-salute-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/vulcan-salute-dark-skin-tone.png",
    ["vulcan-salute-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/vulcan-salute-light-skin-tone.png",
    ["vulcan-salute-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/vulcan-salute-medium-dark-skin-tone.png",
    ["vulcan-salute-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/vulcan-salute-medium-light-skin-tone.png",
    ["vulcan-salute-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/vulcan-salute-medium-skin-tone.png",
    ["waving-hand-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/waving-hand-dark-skin-tone.png",
    ["waving-hand-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/waving-hand-light-skin-tone.png",
    ["waving-hand-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/waving-hand-medium-dark-skin-tone.png",
    ["waving-hand-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/waving-hand-medium-light-skin-tone.png",
    ["waving-hand-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/waving-hand-medium-skin-tone.png",
    ["woman-and-man-holding-hands-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-dark-skin-tone-light-skin-tone.png",
    ["woman-and-man-holding-hands-dark-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-dark-skin-tone-medium-dark-skin-tone.png",
    ["woman-and-man-holding-hands-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-dark-skin-tone-medium-light-skin-tone.png",
    ["woman-and-man-holding-hands-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-dark-skin-tone-medium-skin-tone.png",
    ["woman-and-man-holding-hands-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-dark-skin-tone.png",
    ["woman-and-man-holding-hands-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-light-skin-tone-dark-skin-tone.png",
    ["woman-and-man-holding-hands-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-light-skin-tone-medium-dark-skin-tone.png",
    ["woman-and-man-holding-hands-light-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-light-skin-tone-medium-light-skin-tone.png",
    ["woman-and-man-holding-hands-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-light-skin-tone-medium-skin-tone.png",
    ["woman-and-man-holding-hands-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-light-skin-tone.png",
    ["woman-and-man-holding-hands-medium-dark-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-dark-skin-tone-dark-skin-tone.png",
    ["woman-and-man-holding-hands-medium-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-dark-skin-tone-light-skin-tone.png",
    ["woman-and-man-holding-hands-medium-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-dark-skin-tone-medium-light-skin-tone.png",
    ["woman-and-man-holding-hands-medium-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-dark-skin-tone-medium-skin-tone.png",
    ["woman-and-man-holding-hands-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-dark-skin-tone.png",
    ["woman-and-man-holding-hands-medium-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-light-skin-tone-dark-skin-tone.png",
    ["woman-and-man-holding-hands-medium-light-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-light-skin-tone-light-skin-tone.png",
    ["woman-and-man-holding-hands-medium-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-light-skin-tone-medium-dark-skin-tone.png",
    ["woman-and-man-holding-hands-medium-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-light-skin-tone-medium-skin-tone.png",
    ["woman-and-man-holding-hands-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-light-skin-tone.png",
    ["woman-and-man-holding-hands-medium-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-skin-tone-dark-skin-tone.png",
    ["woman-and-man-holding-hands-medium-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-skin-tone-light-skin-tone.png",
    ["woman-and-man-holding-hands-medium-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-skin-tone-medium-dark-skin-tone.png",
    ["woman-and-man-holding-hands-medium-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-skin-tone-medium-light-skin-tone.png",
    ["woman-and-man-holding-hands-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-and-man-holding-hands-medium-skin-tone.png",
    ["woman-artist-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-artist-dark-skin-tone.png",
    ["woman-artist-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-artist-light-skin-tone.png",
    ["woman-artist-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-artist-medium-dark-skin-tone.png",
    ["woman-artist-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-artist-medium-light-skin-tone.png",
    ["woman-artist-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-artist-medium-skin-tone.png",
    ["woman-astronaut-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-astronaut-dark-skin-tone.png",
    ["woman-astronaut-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-astronaut-light-skin-tone.png",
    ["woman-astronaut-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-astronaut-medium-dark-skin-tone.png",
    ["woman-astronaut-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-astronaut-medium-light-skin-tone.png",
    ["woman-astronaut-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-astronaut-medium-skin-tone.png",
    ["woman-bald-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bald-dark-skin-tone.png",
    ["woman-bald-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bald-light-skin-tone.png",
    ["woman-bald-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bald-medium-dark-skin-tone.png",
    ["woman-bald-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bald-medium-light-skin-tone.png",
    ["woman-bald-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bald-medium-skin-tone.png",
    ["woman-biking-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-biking-dark-skin-tone.png",
    ["woman-biking-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-biking-light-skin-tone.png",
    ["woman-biking-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-biking-medium-dark-skin-tone.png",
    ["woman-biking-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-biking-medium-light-skin-tone.png",
    ["woman-biking-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-biking-medium-skin-tone.png",
    ["woman-bouncing-ball-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bouncing-ball-dark-skin-tone.png",
    ["woman-bouncing-ball-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bouncing-ball-light-skin-tone.png",
    ["woman-bouncing-ball-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bouncing-ball-medium-dark-skin-tone.png",
    ["woman-bouncing-ball-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bouncing-ball-medium-light-skin-tone.png",
    ["woman-bouncing-ball-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bouncing-ball-medium-skin-tone.png",
    ["woman-bowing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bowing-dark-skin-tone.png",
    ["woman-bowing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bowing-light-skin-tone.png",
    ["woman-bowing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bowing-medium-dark-skin-tone.png",
    ["woman-bowing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bowing-medium-light-skin-tone.png",
    ["woman-bowing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-bowing-medium-skin-tone.png",
    ["woman-cartwheeling-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-cartwheeling-dark-skin-tone.png",
    ["woman-cartwheeling-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-cartwheeling-light-skin-tone.png",
    ["woman-cartwheeling-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-cartwheeling-medium-dark-skin-tone.png",
    ["woman-cartwheeling-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-cartwheeling-medium-light-skin-tone.png",
    ["woman-cartwheeling-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-cartwheeling-medium-skin-tone.png",
    ["woman-climbing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-climbing-dark-skin-tone.png",
    ["woman-climbing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-climbing-light-skin-tone.png",
    ["woman-climbing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-climbing-medium-dark-skin-tone.png",
    ["woman-climbing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-climbing-medium-light-skin-tone.png",
    ["woman-climbing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-climbing-medium-skin-tone.png",
    ["woman-construction-worker-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-construction-worker-dark-skin-tone.png",
    ["woman-construction-worker-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-construction-worker-light-skin-tone.png",
    ["woman-construction-worker-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-construction-worker-medium-dark-skin-tone.png",
    ["woman-construction-worker-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-construction-worker-medium-light-skin-tone.png",
    ["woman-construction-worker-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-construction-worker-medium-skin-tone.png",
    ["woman-cook-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-cook-dark-skin-tone.png",
    ["woman-cook-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-cook-light-skin-tone.png",
    ["woman-cook-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-cook-medium-dark-skin-tone.png",
    ["woman-cook-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-cook-medium-light-skin-tone.png",
    ["woman-cook-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-cook-medium-skin-tone.png",
    ["woman-curly-haired-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-curly-haired-dark-skin-tone.png",
    ["woman-curly-haired-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-curly-haired-light-skin-tone.png",
    ["woman-curly-haired-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-curly-haired-medium-dark-skin-tone.png",
    ["woman-curly-haired-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-curly-haired-medium-light-skin-tone.png",
    ["woman-curly-haired-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-curly-haired-medium-skin-tone.png",
    ["woman-dancing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-dancing-dark-skin-tone.png",
    ["woman-dancing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-dancing-light-skin-tone.png",
    ["woman-dancing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-dancing-medium-dark-skin-tone.png",
    ["woman-dancing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-dancing-medium-light-skin-tone.png",
    ["woman-dancing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-dancing-medium-skin-tone.png",
    ["woman-dark-skin-tone-beard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-dark-skin-tone-beard.png",
    ["woman-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-dark-skin-tone.png",
    ["woman-detective-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-detective-dark-skin-tone.png",
    ["woman-detective-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-detective-light-skin-tone.png",
    ["woman-detective-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-detective-medium-dark-skin-tone.png",
    ["woman-detective-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-detective-medium-light-skin-tone.png",
    ["woman-detective-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-detective-medium-skin-tone.png",
    ["woman-elf-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-elf-dark-skin-tone.png",
    ["woman-elf-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-elf-light-skin-tone.png",
    ["woman-elf-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-elf-medium-dark-skin-tone.png",
    ["woman-elf-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-elf-medium-light-skin-tone.png",
    ["woman-elf-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-elf-medium-skin-tone.png",
    ["woman-facepalming-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-facepalming-dark-skin-tone.png",
    ["woman-facepalming-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-facepalming-light-skin-tone.png",
    ["woman-facepalming-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-facepalming-medium-dark-skin-tone.png",
    ["woman-facepalming-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-facepalming-medium-light-skin-tone.png",
    ["woman-facepalming-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-facepalming-medium-skin-tone.png",
    ["woman-factory-worker-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-factory-worker-dark-skin-tone.png",
    ["woman-factory-worker-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-factory-worker-light-skin-tone.png",
    ["woman-factory-worker-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-factory-worker-medium-dark-skin-tone.png",
    ["woman-factory-worker-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-factory-worker-medium-light-skin-tone.png",
    ["woman-factory-worker-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-factory-worker-medium-skin-tone.png",
    ["woman-fairy-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-fairy-dark-skin-tone.png",
    ["woman-fairy-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-fairy-light-skin-tone.png",
    ["woman-fairy-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-fairy-medium-dark-skin-tone.png",
    ["woman-fairy-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-fairy-medium-light-skin-tone.png",
    ["woman-fairy-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-fairy-medium-skin-tone.png",
    ["woman-farmer-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-farmer-dark-skin-tone.png",
    ["woman-farmer-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-farmer-light-skin-tone.png",
    ["woman-farmer-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-farmer-medium-dark-skin-tone.png",
    ["woman-farmer-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-farmer-medium-light-skin-tone.png",
    ["woman-farmer-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-farmer-medium-skin-tone.png",
    ["woman-feeding-baby-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-feeding-baby-dark-skin-tone.png",
    ["woman-feeding-baby-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-feeding-baby-light-skin-tone.png",
    ["woman-feeding-baby-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-feeding-baby-medium-dark-skin-tone.png",
    ["woman-feeding-baby-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-feeding-baby-medium-light-skin-tone.png",
    ["woman-feeding-baby-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-feeding-baby-medium-skin-tone.png",
    ["woman-firefighter-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-firefighter-dark-skin-tone.png",
    ["woman-firefighter-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-firefighter-light-skin-tone.png",
    ["woman-firefighter-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-firefighter-medium-dark-skin-tone.png",
    ["woman-firefighter-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-firefighter-medium-light-skin-tone.png",
    ["woman-firefighter-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-firefighter-medium-skin-tone.png",
    ["woman-frowning-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-frowning-dark-skin-tone.png",
    ["woman-frowning-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-frowning-light-skin-tone.png",
    ["woman-frowning-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-frowning-medium-dark-skin-tone.png",
    ["woman-frowning-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-frowning-medium-light-skin-tone.png",
    ["woman-frowning-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-frowning-medium-skin-tone.png",
    ["woman-gesturing-no-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-gesturing-no-dark-skin-tone.png",
    ["woman-gesturing-no-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-gesturing-no-light-skin-tone.png",
    ["woman-gesturing-no-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-gesturing-no-medium-dark-skin-tone.png",
    ["woman-gesturing-no-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-gesturing-no-medium-light-skin-tone.png",
    ["woman-gesturing-no-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-gesturing-no-medium-skin-tone.png",
    ["woman-gesturing-ok-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-gesturing-ok-dark-skin-tone.png",
    ["woman-gesturing-ok-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-gesturing-ok-light-skin-tone.png",
    ["woman-gesturing-ok-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-gesturing-ok-medium-dark-skin-tone.png",
    ["woman-gesturing-ok-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-gesturing-ok-medium-light-skin-tone.png",
    ["woman-gesturing-ok-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-gesturing-ok-medium-skin-tone.png",
    ["woman-getting-haircut-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-getting-haircut-dark-skin-tone.png",
    ["woman-getting-haircut-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-getting-haircut-light-skin-tone.png",
    ["woman-getting-haircut-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-getting-haircut-medium-dark-skin-tone.png",
    ["woman-getting-haircut-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-getting-haircut-medium-light-skin-tone.png",
    ["woman-getting-haircut-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-getting-haircut-medium-skin-tone.png",
    ["woman-getting-massage-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-getting-massage-dark-skin-tone.png",
    ["woman-getting-massage-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-getting-massage-light-skin-tone.png",
    ["woman-getting-massage-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-getting-massage-medium-dark-skin-tone.png",
    ["woman-getting-massage-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-getting-massage-medium-light-skin-tone.png",
    ["woman-getting-massage-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-getting-massage-medium-skin-tone.png",
    ["woman-golfing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-golfing-dark-skin-tone.png",
    ["woman-golfing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-golfing-light-skin-tone.png",
    ["woman-golfing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-golfing-medium-dark-skin-tone.png",
    ["woman-golfing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-golfing-medium-light-skin-tone.png",
    ["woman-golfing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-golfing-medium-skin-tone.png",
    ["woman-guard-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-guard-dark-skin-tone.png",
    ["woman-guard-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-guard-light-skin-tone.png",
    ["woman-guard-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-guard-medium-dark-skin-tone.png",
    ["woman-guard-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-guard-medium-light-skin-tone.png",
    ["woman-guard-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-guard-medium-skin-tone.png",
    ["woman-health-worker-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-health-worker-dark-skin-tone.png",
    ["woman-health-worker-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-health-worker-light-skin-tone.png",
    ["woman-health-worker-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-health-worker-medium-dark-skin-tone.png",
    ["woman-health-worker-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-health-worker-medium-light-skin-tone.png",
    ["woman-health-worker-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-health-worker-medium-skin-tone.png",
    ["woman-in-lotus-position-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-lotus-position-dark-skin-tone.png",
    ["woman-in-lotus-position-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-lotus-position-light-skin-tone.png",
    ["woman-in-lotus-position-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-lotus-position-medium-dark-skin-tone.png",
    ["woman-in-lotus-position-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-lotus-position-medium-light-skin-tone.png",
    ["woman-in-lotus-position-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-lotus-position-medium-skin-tone.png",
    ["woman-in-manual-wheelchair-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-manual-wheelchair-dark-skin-tone.png",
    ["woman-in-manual-wheelchair-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-manual-wheelchair-light-skin-tone.png",
    ["woman-in-manual-wheelchair-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-manual-wheelchair-medium-dark-skin-tone.png",
    ["woman-in-manual-wheelchair-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-manual-wheelchair-medium-light-skin-tone.png",
    ["woman-in-manual-wheelchair-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-manual-wheelchair-medium-skin-tone.png",
    ["woman-in-motorized-wheelchair-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-motorized-wheelchair-dark-skin-tone.png",
    ["woman-in-motorized-wheelchair-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-motorized-wheelchair-light-skin-tone.png",
    ["woman-in-motorized-wheelchair-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-motorized-wheelchair-medium-dark-skin-tone.png",
    ["woman-in-motorized-wheelchair-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-motorized-wheelchair-medium-light-skin-tone.png",
    ["woman-in-motorized-wheelchair-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-motorized-wheelchair-medium-skin-tone.png",
    ["woman-in-steamy-room-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-steamy-room-dark-skin-tone.png",
    ["woman-in-steamy-room-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-steamy-room-light-skin-tone.png",
    ["woman-in-steamy-room-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-steamy-room-medium-dark-skin-tone.png",
    ["woman-in-steamy-room-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-steamy-room-medium-light-skin-tone.png",
    ["woman-in-steamy-room-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-steamy-room-medium-skin-tone.png",
    ["woman-in-tuxedo-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-tuxedo-dark-skin-tone.png",
    ["woman-in-tuxedo-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-tuxedo-light-skin-tone.png",
    ["woman-in-tuxedo-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-tuxedo-medium-dark-skin-tone.png",
    ["woman-in-tuxedo-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-tuxedo-medium-light-skin-tone.png",
    ["woman-in-tuxedo-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-in-tuxedo-medium-skin-tone.png",
    ["woman-judge-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-judge-dark-skin-tone.png",
    ["woman-judge-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-judge-light-skin-tone.png",
    ["woman-judge-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-judge-medium-dark-skin-tone.png",
    ["woman-judge-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-judge-medium-light-skin-tone.png",
    ["woman-judge-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-judge-medium-skin-tone.png",
    ["woman-juggling-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-juggling-dark-skin-tone.png",
    ["woman-juggling-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-juggling-light-skin-tone.png",
    ["woman-juggling-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-juggling-medium-dark-skin-tone.png",
    ["woman-juggling-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-juggling-medium-light-skin-tone.png",
    ["woman-juggling-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-juggling-medium-skin-tone.png",
    ["woman-kneeling-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-kneeling-dark-skin-tone.png",
    ["woman-kneeling-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-kneeling-light-skin-tone.png",
    ["woman-kneeling-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-kneeling-medium-dark-skin-tone.png",
    ["woman-kneeling-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-kneeling-medium-light-skin-tone.png",
    ["woman-kneeling-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-kneeling-medium-skin-tone.png",
    ["woman-lifting-weights-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-lifting-weights-dark-skin-tone.png",
    ["woman-lifting-weights-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-lifting-weights-light-skin-tone.png",
    ["woman-lifting-weights-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-lifting-weights-medium-dark-skin-tone.png",
    ["woman-lifting-weights-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-lifting-weights-medium-light-skin-tone.png",
    ["woman-lifting-weights-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-lifting-weights-medium-skin-tone.png",
    ["woman-light-skin-tone-beard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-light-skin-tone-beard.png",
    ["woman-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-light-skin-tone.png",
    ["woman-mage-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mage-dark-skin-tone.png",
    ["woman-mage-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mage-light-skin-tone.png",
    ["woman-mage-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mage-medium-dark-skin-tone.png",
    ["woman-mage-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mage-medium-light-skin-tone.png",
    ["woman-mage-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mage-medium-skin-tone.png",
    ["woman-mechanic-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mechanic-dark-skin-tone.png",
    ["woman-mechanic-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mechanic-light-skin-tone.png",
    ["woman-mechanic-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mechanic-medium-dark-skin-tone.png",
    ["woman-mechanic-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mechanic-medium-light-skin-tone.png",
    ["woman-mechanic-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mechanic-medium-skin-tone.png",
    ["woman-medium-dark-skin-tone-beard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-medium-dark-skin-tone-beard.png",
    ["woman-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-medium-dark-skin-tone.png",
    ["woman-medium-light-skin-tone-beard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-medium-light-skin-tone-beard.png",
    ["woman-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-medium-light-skin-tone.png",
    ["woman-medium-skin-tone-beard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-medium-skin-tone-beard.png",
    ["woman-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-medium-skin-tone.png",
    ["woman-mountain-biking-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mountain-biking-dark-skin-tone.png",
    ["woman-mountain-biking-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mountain-biking-light-skin-tone.png",
    ["woman-mountain-biking-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mountain-biking-medium-dark-skin-tone.png",
    ["woman-mountain-biking-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mountain-biking-medium-light-skin-tone.png",
    ["woman-mountain-biking-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-mountain-biking-medium-skin-tone.png",
    ["woman-office-worker-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-office-worker-dark-skin-tone.png",
    ["woman-office-worker-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-office-worker-light-skin-tone.png",
    ["woman-office-worker-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-office-worker-medium-dark-skin-tone.png",
    ["woman-office-worker-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-office-worker-medium-light-skin-tone.png",
    ["woman-office-worker-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-office-worker-medium-skin-tone.png",
    ["woman-pilot-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-pilot-dark-skin-tone.png",
    ["woman-pilot-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-pilot-light-skin-tone.png",
    ["woman-pilot-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-pilot-medium-dark-skin-tone.png",
    ["woman-pilot-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-pilot-medium-light-skin-tone.png",
    ["woman-pilot-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-pilot-medium-skin-tone.png",
    ["woman-playing-handball-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-playing-handball-dark-skin-tone.png",
    ["woman-playing-handball-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-playing-handball-light-skin-tone.png",
    ["woman-playing-handball-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-playing-handball-medium-dark-skin-tone.png",
    ["woman-playing-handball-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-playing-handball-medium-light-skin-tone.png",
    ["woman-playing-handball-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-playing-handball-medium-skin-tone.png",
    ["woman-playing-water-polo-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-playing-water-polo-dark-skin-tone.png",
    ["woman-playing-water-polo-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-playing-water-polo-light-skin-tone.png",
    ["woman-playing-water-polo-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-playing-water-polo-medium-dark-skin-tone.png",
    ["woman-playing-water-polo-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-playing-water-polo-medium-light-skin-tone.png",
    ["woman-playing-water-polo-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-playing-water-polo-medium-skin-tone.png",
    ["woman-police-officer-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-police-officer-dark-skin-tone.png",
    ["woman-police-officer-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-police-officer-light-skin-tone.png",
    ["woman-police-officer-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-police-officer-medium-dark-skin-tone.png",
    ["woman-police-officer-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-police-officer-medium-light-skin-tone.png",
    ["woman-police-officer-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-police-officer-medium-skin-tone.png",
    ["woman-pouting-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-pouting-dark-skin-tone.png",
    ["woman-pouting-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-pouting-light-skin-tone.png",
    ["woman-pouting-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-pouting-medium-dark-skin-tone.png",
    ["woman-pouting-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-pouting-medium-light-skin-tone.png",
    ["woman-pouting-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-pouting-medium-skin-tone.png",
    ["woman-raising-hand-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-raising-hand-dark-skin-tone.png",
    ["woman-raising-hand-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-raising-hand-light-skin-tone.png",
    ["woman-raising-hand-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-raising-hand-medium-dark-skin-tone.png",
    ["woman-raising-hand-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-raising-hand-medium-light-skin-tone.png",
    ["woman-raising-hand-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-raising-hand-medium-skin-tone.png",
    ["woman-red-haired-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-red-haired-dark-skin-tone.png",
    ["woman-red-haired-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-red-haired-light-skin-tone.png",
    ["woman-red-haired-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-red-haired-medium-dark-skin-tone.png",
    ["woman-red-haired-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-red-haired-medium-light-skin-tone.png",
    ["woman-red-haired-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-red-haired-medium-skin-tone.png",
    ["woman-rowing-boat-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-rowing-boat-dark-skin-tone.png",
    ["woman-rowing-boat-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-rowing-boat-light-skin-tone.png",
    ["woman-rowing-boat-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-rowing-boat-medium-dark-skin-tone.png",
    ["woman-rowing-boat-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-rowing-boat-medium-light-skin-tone.png",
    ["woman-rowing-boat-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-rowing-boat-medium-skin-tone.png",
    ["woman-running-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-running-dark-skin-tone.png",
    ["woman-running-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-running-light-skin-tone.png",
    ["woman-running-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-running-medium-dark-skin-tone.png",
    ["woman-running-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-running-medium-light-skin-tone.png",
    ["woman-running-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-running-medium-skin-tone.png",
    ["woman-scientist-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-scientist-dark-skin-tone.png",
    ["woman-scientist-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-scientist-light-skin-tone.png",
    ["woman-scientist-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-scientist-medium-dark-skin-tone.png",
    ["woman-scientist-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-scientist-medium-light-skin-tone.png",
    ["woman-scientist-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-scientist-medium-skin-tone.png",
    ["woman-shrugging-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-shrugging-dark-skin-tone.png",
    ["woman-shrugging-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-shrugging-light-skin-tone.png",
    ["woman-shrugging-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-shrugging-medium-dark-skin-tone.png",
    ["woman-shrugging-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-shrugging-medium-light-skin-tone.png",
    ["woman-shrugging-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-shrugging-medium-skin-tone.png",
    ["woman-singer-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-singer-dark-skin-tone.png",
    ["woman-singer-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-singer-light-skin-tone.png",
    ["woman-singer-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-singer-medium-dark-skin-tone.png",
    ["woman-singer-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-singer-medium-light-skin-tone.png",
    ["woman-singer-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-singer-medium-skin-tone.png",
    ["woman-standing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-standing-dark-skin-tone.png",
    ["woman-standing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-standing-light-skin-tone.png",
    ["woman-standing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-standing-medium-dark-skin-tone.png",
    ["woman-standing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-standing-medium-light-skin-tone.png",
    ["woman-standing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-standing-medium-skin-tone.png",
    ["woman-student-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-student-dark-skin-tone.png",
    ["woman-student-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-student-light-skin-tone.png",
    ["woman-student-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-student-medium-dark-skin-tone.png",
    ["woman-student-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-student-medium-light-skin-tone.png",
    ["woman-student-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-student-medium-skin-tone.png",
    ["woman-superhero-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-superhero-dark-skin-tone.png",
    ["woman-superhero-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-superhero-light-skin-tone.png",
    ["woman-superhero-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-superhero-medium-dark-skin-tone.png",
    ["woman-superhero-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-superhero-medium-light-skin-tone.png",
    ["woman-superhero-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-superhero-medium-skin-tone.png",
    ["woman-supervillain-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-supervillain-dark-skin-tone.png",
    ["woman-supervillain-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-supervillain-light-skin-tone.png",
    ["woman-supervillain-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-supervillain-medium-dark-skin-tone.png",
    ["woman-supervillain-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-supervillain-medium-light-skin-tone.png",
    ["woman-supervillain-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-supervillain-medium-skin-tone.png",
    ["woman-surfing-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-surfing-dark-skin-tone.png",
    ["woman-surfing-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-surfing-light-skin-tone.png",
    ["woman-surfing-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-surfing-medium-dark-skin-tone.png",
    ["woman-surfing-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-surfing-medium-light-skin-tone.png",
    ["woman-surfing-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-surfing-medium-skin-tone.png",
    ["woman-swimming-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-swimming-dark-skin-tone.png",
    ["woman-swimming-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-swimming-light-skin-tone.png",
    ["woman-swimming-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-swimming-medium-dark-skin-tone.png",
    ["woman-swimming-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-swimming-medium-light-skin-tone.png",
    ["woman-swimming-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-swimming-medium-skin-tone.png",
    ["woman-teacher-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-teacher-dark-skin-tone.png",
    ["woman-teacher-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-teacher-light-skin-tone.png",
    ["woman-teacher-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-teacher-medium-dark-skin-tone.png",
    ["woman-teacher-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-teacher-medium-light-skin-tone.png",
    ["woman-teacher-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-teacher-medium-skin-tone.png",
    ["woman-technologist-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-technologist-dark-skin-tone.png",
    ["woman-technologist-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-technologist-light-skin-tone.png",
    ["woman-technologist-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-technologist-medium-dark-skin-tone.png",
    ["woman-technologist-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-technologist-medium-light-skin-tone.png",
    ["woman-technologist-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-technologist-medium-skin-tone.png",
    ["woman-tipping-hand-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-tipping-hand-dark-skin-tone.png",
    ["woman-tipping-hand-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-tipping-hand-light-skin-tone.png",
    ["woman-tipping-hand-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-tipping-hand-medium-dark-skin-tone.png",
    ["woman-tipping-hand-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-tipping-hand-medium-light-skin-tone.png",
    ["woman-tipping-hand-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-tipping-hand-medium-skin-tone.png",
    ["woman-vampire-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-vampire-dark-skin-tone.png",
    ["woman-vampire-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-vampire-light-skin-tone.png",
    ["woman-vampire-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-vampire-medium-dark-skin-tone.png",
    ["woman-vampire-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-vampire-medium-light-skin-tone.png",
    ["woman-vampire-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-vampire-medium-skin-tone.png",
    ["woman-walking-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-walking-dark-skin-tone.png",
    ["woman-walking-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-walking-light-skin-tone.png",
    ["woman-walking-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-walking-medium-dark-skin-tone.png",
    ["woman-walking-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-walking-medium-light-skin-tone.png",
    ["woman-walking-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-walking-medium-skin-tone.png",
    ["woman-wearing-turban-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-wearing-turban-dark-skin-tone.png",
    ["woman-wearing-turban-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-wearing-turban-light-skin-tone.png",
    ["woman-wearing-turban-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-wearing-turban-medium-dark-skin-tone.png",
    ["woman-wearing-turban-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-wearing-turban-medium-light-skin-tone.png",
    ["woman-wearing-turban-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-wearing-turban-medium-skin-tone.png",
    ["woman-white-haired-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-white-haired-dark-skin-tone.png",
    ["woman-white-haired-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-white-haired-light-skin-tone.png",
    ["woman-white-haired-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-white-haired-medium-dark-skin-tone.png",
    ["woman-white-haired-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-white-haired-medium-light-skin-tone.png",
    ["woman-white-haired-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-white-haired-medium-skin-tone.png",
    ["woman-with-headscarf-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-headscarf-dark-skin-tone.png",
    ["woman-with-headscarf-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-headscarf-light-skin-tone.png",
    ["woman-with-headscarf-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-headscarf-medium-dark-skin-tone.png",
    ["woman-with-headscarf-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-headscarf-medium-light-skin-tone.png",
    ["woman-with-headscarf-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-headscarf-medium-skin-tone.png",
    ["woman-with-probing-cane-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-probing-cane-dark-skin-tone.png",
    ["woman-with-probing-cane-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-probing-cane-light-skin-tone.png",
    ["woman-with-probing-cane-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-probing-cane-medium-dark-skin-tone.png",
    ["woman-with-probing-cane-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-probing-cane-medium-light-skin-tone.png",
    ["woman-with-probing-cane-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-probing-cane-medium-skin-tone.png",
    ["woman-with-veil-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-veil-dark-skin-tone.png",
    ["woman-with-veil-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-veil-light-skin-tone.png",
    ["woman-with-veil-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-veil-medium-dark-skin-tone.png",
    ["woman-with-veil-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-veil-medium-light-skin-tone.png",
    ["woman-with-veil-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/woman-with-veil-medium-skin-tone.png",
    ["women-holding-hands-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-dark-skin-tone-light-skin-tone.png",
    ["women-holding-hands-dark-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-dark-skin-tone-medium-dark-skin-tone.png",
    ["women-holding-hands-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-dark-skin-tone-medium-light-skin-tone.png",
    ["women-holding-hands-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-dark-skin-tone-medium-skin-tone.png",
    ["women-holding-hands-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-dark-skin-tone.png",
    ["women-holding-hands-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-light-skin-tone-dark-skin-tone.png",
    ["women-holding-hands-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-light-skin-tone-medium-dark-skin-tone.png",
    ["women-holding-hands-light-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-light-skin-tone-medium-light-skin-tone.png",
    ["women-holding-hands-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-light-skin-tone-medium-skin-tone.png",
    ["women-holding-hands-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-light-skin-tone.png",
    ["women-holding-hands-medium-dark-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-dark-skin-tone-dark-skin-tone.png",
    ["women-holding-hands-medium-dark-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-dark-skin-tone-light-skin-tone.png",
    ["women-holding-hands-medium-dark-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-dark-skin-tone-medium-light-skin-tone.png",
    ["women-holding-hands-medium-dark-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-dark-skin-tone-medium-skin-tone.png",
    ["women-holding-hands-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-dark-skin-tone.png",
    ["women-holding-hands-medium-light-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-light-skin-tone-dark-skin-tone.png",
    ["women-holding-hands-medium-light-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-light-skin-tone-light-skin-tone.png",
    ["women-holding-hands-medium-light-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-light-skin-tone-medium-dark-skin-tone.png",
    ["women-holding-hands-medium-light-skin-tone-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-light-skin-tone-medium-skin-tone.png",
    ["women-holding-hands-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-light-skin-tone.png",
    ["women-holding-hands-medium-skin-tone-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-skin-tone-dark-skin-tone.png",
    ["women-holding-hands-medium-skin-tone-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-skin-tone-light-skin-tone.png",
    ["women-holding-hands-medium-skin-tone-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-skin-tone-medium-dark-skin-tone.png",
    ["women-holding-hands-medium-skin-tone-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-skin-tone-medium-light-skin-tone.png",
    ["women-holding-hands-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/women-holding-hands-medium-skin-tone.png",
    ["writing-hand-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/writing-hand-dark-skin-tone.png",
    ["writing-hand-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/writing-hand-light-skin-tone.png",
    ["writing-hand-medium-dark-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/writing-hand-medium-dark-skin-tone.png",
    ["writing-hand-medium-light-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/writing-hand-medium-light-skin-tone.png",
    ["writing-hand-medium-skin-tone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/diversity/writing-hand-medium-skin-tone.png",
  },
  flags: {
    ["black-flag.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/black-flag.png",
    ["chequered-flag.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/chequered-flag.png",
    ["crossed-flags.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/crossed-flags.png",
    ["flag-afghanistan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-afghanistan.png",
    ["flag-aland-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-aland-islands.png",
    ["flag-albania.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-albania.png",
    ["flag-algeria.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-algeria.png",
    ["flag-american-samoa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-american-samoa.png",
    ["flag-andorra.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-andorra.png",
    ["flag-angola.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-angola.png",
    ["flag-anguilla.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-anguilla.png",
    ["flag-antarctica.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-antarctica.png",
    ["flag-antigua-amp-barbuda.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-antigua-amp-barbuda.png",
    ["flag-argentina.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-argentina.png",
    ["flag-armenia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-armenia.png",
    ["flag-aruba.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-aruba.png",
    ["flag-ascension-island.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-ascension-island.png",
    ["flag-australia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-australia.png",
    ["flag-austria.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-austria.png",
    ["flag-azerbaijan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-azerbaijan.png",
    ["flag-bahamas.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-bahamas.png",
    ["flag-bahrain.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-bahrain.png",
    ["flag-bangladesh.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-bangladesh.png",
    ["flag-barbados.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-barbados.png",
    ["flag-belarus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-belarus.png",
    ["flag-belgium.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-belgium.png",
    ["flag-belize.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-belize.png",
    ["flag-benin.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-benin.png",
    ["flag-bermuda.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-bermuda.png",
    ["flag-bhutan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-bhutan.png",
    ["flag-bolivia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-bolivia.png",
    ["flag-bosnia-amp-herzegovina.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-bosnia-amp-herzegovina.png",
    ["flag-botswana.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-botswana.png",
    ["flag-bouvet-island.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-bouvet-island.png",
    ["flag-brazil.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-brazil.png",
    ["flag-british-indian-ocean-territory.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-british-indian-ocean-territory.png",
    ["flag-british-virgin-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-british-virgin-islands.png",
    ["flag-brunei.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-brunei.png",
    ["flag-bulgaria.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-bulgaria.png",
    ["flag-burkina-faso.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-burkina-faso.png",
    ["flag-burundi.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-burundi.png",
    ["flag-cambodia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-cambodia.png",
    ["flag-cameroon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-cameroon.png",
    ["flag-canada.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-canada.png",
    ["flag-canary-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-canary-islands.png",
    ["flag-cape-verde.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-cape-verde.png",
    ["flag-caribbean-netherlands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-caribbean-netherlands.png",
    ["flag-cayman-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-cayman-islands.png",
    ["flag-central-african-republic.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-central-african-republic.png",
    ["flag-ceuta-amp-melilla.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-ceuta-amp-melilla.png",
    ["flag-chad.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-chad.png",
    ["flag-chile.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-chile.png",
    ["flag-china.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-china.png",
    ["flag-christmas-island.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-christmas-island.png",
    ["flag-clipperton-island.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-clipperton-island.png",
    ["flag-cocos-keeling-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-cocos-keeling-islands.png",
    ["flag-colombia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-colombia.png",
    ["flag-comoros.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-comoros.png",
    ["flag-congo-brazzaville.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-congo-brazzaville.png",
    ["flag-congo-kinshasa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-congo-kinshasa.png",
    ["flag-cook-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-cook-islands.png",
    ["flag-costa-rica.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-costa-rica.png",
    ["flag-cote-divoire.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-cote-divoire.png",
    ["flag-croatia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-croatia.png",
    ["flag-cuba.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-cuba.png",
    ["flag-curacao.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-curacao.png",
    ["flag-cyprus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-cyprus.png",
    ["flag-czechia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-czechia.png",
    ["flag-denmark.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-denmark.png",
    ["flag-diego-garcia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-diego-garcia.png",
    ["flag-djibouti.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-djibouti.png",
    ["flag-dominica.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-dominica.png",
    ["flag-dominican-republic.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-dominican-republic.png",
    ["flag-ecuador.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-ecuador.png",
    ["flag-egypt.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-egypt.png",
    ["flag-el-salvador.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-el-salvador.png",
    ["flag-england.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-england.png",
    ["flag-equatorial-guinea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-equatorial-guinea.png",
    ["flag-eritrea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-eritrea.png",
    ["flag-estonia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-estonia.png",
    ["flag-eswatini.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-eswatini.png",
    ["flag-ethiopia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-ethiopia.png",
    ["flag-european-union.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-european-union.png",
    ["flag-falkland-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-falkland-islands.png",
    ["flag-faroe-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-faroe-islands.png",
    ["flag-fiji.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-fiji.png",
    ["flag-finland.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-finland.png",
    ["flag-france.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-france.png",
    ["flag-french-guiana.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-french-guiana.png",
    ["flag-french-polynesia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-french-polynesia.png",
    ["flag-french-southern-territories.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-french-southern-territories.png",
    ["flag-gabon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-gabon.png",
    ["flag-gambia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-gambia.png",
    ["flag-georgia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-georgia.png",
    ["flag-germany.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-germany.png",
    ["flag-ghana.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-ghana.png",
    ["flag-gibraltar.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-gibraltar.png",
    ["flag-greece.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-greece.png",
    ["flag-greenland.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-greenland.png",
    ["flag-grenada.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-grenada.png",
    ["flag-guadeloupe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-guadeloupe.png",
    ["flag-guam.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-guam.png",
    ["flag-guatemala.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-guatemala.png",
    ["flag-guernsey.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-guernsey.png",
    ["flag-guinea-bissau.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-guinea-bissau.png",
    ["flag-guinea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-guinea.png",
    ["flag-guyana.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-guyana.png",
    ["flag-haiti.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-haiti.png",
    ["flag-heard-amp-mcdonald-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-heard-amp-mcdonald-islands.png",
    ["flag-honduras.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-honduras.png",
    ["flag-hong-kong-sar-china.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-hong-kong-sar-china.png",
    ["flag-hungary.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-hungary.png",
    ["flag-iceland.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-iceland.png",
    ["flag-india.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-india.png",
    ["flag-indonesia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-indonesia.png",
    ["flag-iran.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-iran.png",
    ["flag-iraq.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-iraq.png",
    ["flag-ireland.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-ireland.png",
    ["flag-isle-of-man.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-isle-of-man.png",
    ["flag-israel.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-israel.png",
    ["flag-italy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-italy.png",
    ["flag-jamaica.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-jamaica.png",
    ["flag-japan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-japan.png",
    ["flag-jersey.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-jersey.png",
    ["flag-jordan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-jordan.png",
    ["flag-kazakhstan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-kazakhstan.png",
    ["flag-kenya.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-kenya.png",
    ["flag-kiribati.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-kiribati.png",
    ["flag-kosovo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-kosovo.png",
    ["flag-kuwait.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-kuwait.png",
    ["flag-kyrgyzstan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-kyrgyzstan.png",
    ["flag-laos.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-laos.png",
    ["flag-latvia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-latvia.png",
    ["flag-lebanon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-lebanon.png",
    ["flag-lesotho.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-lesotho.png",
    ["flag-liberia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-liberia.png",
    ["flag-libya.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-libya.png",
    ["flag-liechtenstein.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-liechtenstein.png",
    ["flag-lithuania.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-lithuania.png",
    ["flag-luxembourg.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-luxembourg.png",
    ["flag-macao-sar-china.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-macao-sar-china.png",
    ["flag-macedonia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-macedonia.png",
    ["flag-madagascar.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-madagascar.png",
    ["flag-malawi.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-malawi.png",
    ["flag-malaysia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-malaysia.png",
    ["flag-maldives.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-maldives.png",
    ["flag-mali.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-mali.png",
    ["flag-malta.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-malta.png",
    ["flag-marshall-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-marshall-islands.png",
    ["flag-martinique.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-martinique.png",
    ["flag-mauritania.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-mauritania.png",
    ["flag-mauritius.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-mauritius.png",
    ["flag-mayotte.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-mayotte.png",
    ["flag-mexico.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-mexico.png",
    ["flag-micronesia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-micronesia.png",
    ["flag-moldova.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-moldova.png",
    ["flag-monaco.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-monaco.png",
    ["flag-mongolia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-mongolia.png",
    ["flag-montenegro.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-montenegro.png",
    ["flag-montserrat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-montserrat.png",
    ["flag-morocco.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-morocco.png",
    ["flag-mozambique.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-mozambique.png",
    ["flag-myanmar-burma.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-myanmar-burma.png",
    ["flag-namibia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-namibia.png",
    ["flag-nauru.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-nauru.png",
    ["flag-nepal.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-nepal.png",
    ["flag-netherlands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-netherlands.png",
    ["flag-new-caledonia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-new-caledonia.png",
    ["flag-new-zealand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-new-zealand.png",
    ["flag-nicaragua.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-nicaragua.png",
    ["flag-niger.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-niger.png",
    ["flag-nigeria.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-nigeria.png",
    ["flag-niue.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-niue.png",
    ["flag-norfolk-island.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-norfolk-island.png",
    ["flag-north-korea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-north-korea.png",
    ["flag-northern-mariana-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-northern-mariana-islands.png",
    ["flag-norway.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-norway.png",
    ["flag-oman.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-oman.png",
    ["flag-pakistan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-pakistan.png",
    ["flag-palau.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-palau.png",
    ["flag-palestinian-territories.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-palestinian-territories.png",
    ["flag-panama.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-panama.png",
    ["flag-papua-new-guinea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-papua-new-guinea.png",
    ["flag-paraguay.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-paraguay.png",
    ["flag-peru.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-peru.png",
    ["flag-philippines.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-philippines.png",
    ["flag-pitcairn-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-pitcairn-islands.png",
    ["flag-poland.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-poland.png",
    ["flag-portugal.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-portugal.png",
    ["flag-puerto-rico.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-puerto-rico.png",
    ["flag-qatar.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-qatar.png",
    ["flag-reunion.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-reunion.png",
    ["flag-romania.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-romania.png",
    ["flag-russia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-russia.png",
    ["flag-rwanda.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-rwanda.png",
    ["flag-samoa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-samoa.png",
    ["flag-san-marino.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-san-marino.png",
    ["flag-sao-tome-amp-principe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-sao-tome-amp-principe.png",
    ["flag-saudi-arabia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-saudi-arabia.png",
    ["flag-scotland.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-scotland.png",
    ["flag-senegal.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-senegal.png",
    ["flag-serbia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-serbia.png",
    ["flag-seychelles.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-seychelles.png",
    ["flag-sierra-leone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-sierra-leone.png",
    ["flag-singapore.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-singapore.png",
    ["flag-sint-maarten.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-sint-maarten.png",
    ["flag-slovakia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-slovakia.png",
    ["flag-slovenia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-slovenia.png",
    ["flag-solomon-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-solomon-islands.png",
    ["flag-somalia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-somalia.png",
    ["flag-south-africa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-south-africa.png",
    ["flag-south-georgia-amp-south-sandwich-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-south-georgia-amp-south-sandwich-islands.png",
    ["flag-south-korea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-south-korea.png",
    ["flag-south-sudan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-south-sudan.png",
    ["flag-spain.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-spain.png",
    ["flag-sri-lanka.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-sri-lanka.png",
    ["flag-st-barthelemy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-st-barthelemy.png",
    ["flag-st-helena.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-st-helena.png",
    ["flag-st-kitts-amp-nevis.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-st-kitts-amp-nevis.png",
    ["flag-st-lucia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-st-lucia.png",
    ["flag-st-martin.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-st-martin.png",
    ["flag-st-pierre-amp-miquelon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-st-pierre-amp-miquelon.png",
    ["flag-st-vincent-amp-grenadines.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-st-vincent-amp-grenadines.png",
    ["flag-sudan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-sudan.png",
    ["flag-suriname.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-suriname.png",
    ["flag-svalbard-amp-jan-mayen.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-svalbard-amp-jan-mayen.png",
    ["flag-sweden.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-sweden.png",
    ["flag-switzerland.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-switzerland.png",
    ["flag-syria.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-syria.png",
    ["flag-taiwan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-taiwan.png",
    ["flag-tajikistan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-tajikistan.png",
    ["flag-tanzania.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-tanzania.png",
    ["flag-thailand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-thailand.png",
    ["flag-timor-leste.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-timor-leste.png",
    ["flag-togo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-togo.png",
    ["flag-tokelau.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-tokelau.png",
    ["flag-tonga.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-tonga.png",
    ["flag-trinidad-amp-tobago.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-trinidad-amp-tobago.png",
    ["flag-tristan-da-cunha.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-tristan-da-cunha.png",
    ["flag-tunisia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-tunisia.png",
    ["flag-turkey.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-turkey.png",
    ["flag-turkmenistan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-turkmenistan.png",
    ["flag-turks-amp-caicos-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-turks-amp-caicos-islands.png",
    ["flag-tuvalu.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-tuvalu.png",
    ["flag-uganda.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-uganda.png",
    ["flag-ukraine.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-ukraine.png",
    ["flag-united-arab-emirates.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-united-arab-emirates.png",
    ["flag-united-kingdom.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-united-kingdom.png",
    ["flag-united-nations.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-united-nations.png",
    ["flag-united-states.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-united-states.png",
    ["flag-uruguay.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-uruguay.png",
    ["flag-us-outlying-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-us-outlying-islands.png",
    ["flag-us-virgin-islands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-us-virgin-islands.png",
    ["flag-uzbekistan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-uzbekistan.png",
    ["flag-vanuatu.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-vanuatu.png",
    ["flag-vatican-city.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-vatican-city.png",
    ["flag-venezuela.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-venezuela.png",
    ["flag-vietnam.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-vietnam.png",
    ["flag-wales.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-wales.png",
    ["flag-wallis-amp-futuna.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-wallis-amp-futuna.png",
    ["flag-western-sahara.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-western-sahara.png",
    ["flag-yemen.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-yemen.png",
    ["flag-zambia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-zambia.png",
    ["flag-zimbabwe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/flag-zimbabwe.png",
    ["pirate-flag.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/pirate-flag.png",
    ["rainbow-flag.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/rainbow-flag.png",
    ["transgender-flag.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/transgender-flag.png",
    ["triangular-flag.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/triangular-flag.png",
    ["white-flag.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/flags/white-flag.png",
  },
  food: {
    ["avocado.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/avocado.png",
    ["baby-bottle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/baby-bottle.png",
    ["bacon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/bacon.png",
    ["bagel.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/bagel.png",
    ["baguette-bread.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/baguette-bread.png",
    ["banana.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/banana.png",
    ["beer-mug.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/beer-mug.png",
    ["bell-pepper.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/bell-pepper.png",
    ["bento-box.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/bento-box.png",
    ["beverage-box.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/beverage-box.png",
    ["birthday-cake.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/birthday-cake.png",
    ["blueberries.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/blueberries.png",
    ["bottle-with-popping-cork.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/bottle-with-popping-cork.png",
    ["bowl-with-spoon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/bowl-with-spoon.png",
    ["bread.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/bread.png",
    ["broccoli.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/broccoli.png",
    ["bubble-tea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/bubble-tea.png",
    ["burrito.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/burrito.png",
    ["butter.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/butter.png",
    ["candy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/candy.png",
    ["canned-food.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/canned-food.png",
    ["carrot.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/carrot.png",
    ["cheese-wedge.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/cheese-wedge.png",
    ["cherries.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/cherries.png",
    ["chestnut.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/chestnut.png",
    ["chocolate-bar.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/chocolate-bar.png",
    ["chopsticks.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/chopsticks.png",
    ["clinking-beer-mugs.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/clinking-beer-mugs.png",
    ["clinking-glasses.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/clinking-glasses.png",
    ["cocktail-glass.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/cocktail-glass.png",
    ["coconut.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/coconut.png",
    ["cooked-rice.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/cooked-rice.png",
    ["cookie.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/cookie.png",
    ["cooking.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/cooking.png",
    ["croissant.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/croissant.png",
    ["cucumber.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/cucumber.png",
    ["cup-with-straw.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/cup-with-straw.png",
    ["cupcake.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/cupcake.png",
    ["curry-rice.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/curry-rice.png",
    ["custard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/custard.png",
    ["cut-of-meat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/cut-of-meat.png",
    ["dango.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/dango.png",
    ["doughnut.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/doughnut.png",
    ["dumpling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/dumpling.png",
    ["ear-of-corn.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/ear-of-corn.png",
    ["egg.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/egg.png",
    ["eggplant.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/eggplant.png",
    ["falafel.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/falafel.png",
    ["fish-cake-with-swirl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/fish-cake-with-swirl.png",
    ["flatbread.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/flatbread.png",
    ["fondue.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/fondue.png",
    ["fork-and-knife-with-plate.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/fork-and-knife-with-plate.png",
    ["fork-and-knife.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/fork-and-knife.png",
    ["fortune-cookie.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/fortune-cookie.png",
    ["french-fries.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/french-fries.png",
    ["fried-shrimp.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/fried-shrimp.png",
    ["garlic.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/garlic.png",
    ["glass-of-milk.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/glass-of-milk.png",
    ["grapes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/grapes.png",
    ["green-apple.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/green-apple.png",
    ["green-salad.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/green-salad.png",
    ["hamburger.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/hamburger.png",
    ["honey-pot.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/honey-pot.png",
    ["hot-beverage.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/hot-beverage.png",
    ["hot-dog.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/hot-dog.png",
    ["hot-pepper.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/hot-pepper.png",
    ["ice-cream.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/ice-cream.png",
    ["ice-cube.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/ice-cube.png",
    ["kiwi-fruit.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/kiwi-fruit.png",
    ["leafy-green.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/leafy-green.png",
    ["lemon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/lemon.png",
    ["lollipop.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/lollipop.png",
    ["mango.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/mango.png",
    ["mate.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/mate.png",
    ["meat-on-bone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/meat-on-bone.png",
    ["melon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/melon.png",
    ["moon-cake.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/moon-cake.png",
    ["oden.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/oden.png",
    ["olive.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/olive.png",
    ["onion.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/onion.png",
    ["oyster.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/oyster.png",
    ["pancakes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/pancakes.png",
    ["peach.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/peach.png",
    ["peanuts.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/peanuts.png",
    ["pear.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/pear.png",
    ["pie.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/pie.png",
    ["pineapple.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/pineapple.png",
    ["pizza.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/pizza.png",
    ["popcorn.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/popcorn.png",
    ["pot-of-food.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/pot-of-food.png",
    ["potato.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/potato.png",
    ["poultry-leg.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/poultry-leg.png",
    ["pretzel.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/pretzel.png",
    ["red-apple.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/red-apple.png",
    ["rice-ball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/rice-ball.png",
    ["rice-cracker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/rice-cracker.png",
    ["roasted-sweet-potato.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/roasted-sweet-potato.png",
    ["sake.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/sake.png",
    ["salt.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/salt.png",
    ["sandwich.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/sandwich.png",
    ["shallow-pan-of-food.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/shallow-pan-of-food.png",
    ["shaved-ice.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/shaved-ice.png",
    ["shortcake.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/shortcake.png",
    ["soft-ice-cream.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/soft-ice-cream.png",
    ["spaghetti.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/spaghetti.png",
    ["spoon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/spoon.png",
    ["steaming-bowl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/steaming-bowl.png",
    ["strawberry.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/strawberry.png",
    ["stuffed-flatbread.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/stuffed-flatbread.png",
    ["sushi.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/sushi.png",
    ["taco.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/taco.png",
    ["takeout-box.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/takeout-box.png",
    ["tamale.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/tamale.png",
    ["tangerine.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/tangerine.png",
    ["teacup-without-handle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/teacup-without-handle.png",
    ["teapot.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/teapot.png",
    ["tomato.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/tomato.png",
    ["tropical-drink.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/tropical-drink.png",
    ["tumbler-glass.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/tumbler-glass.png",
    ["waffle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/waffle.png",
    ["watermelon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/watermelon.png",
    ["wine-glass.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/food/wine-glass.png",
  },
  nature: {
    ["ant.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/ant.png",
    ["baby-chick.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/baby-chick.png",
    ["badger.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/badger.png",
    ["bat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/bat.png",
    ["bear.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/bear.png",
    ["beaver.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/beaver.png",
    ["beetle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/beetle.png",
    ["bird.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/bird.png",
    ["bison.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/bison.png",
    ["black-cat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/black-cat.png",
    ["blossom.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/blossom.png",
    ["blowfish.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/blowfish.png",
    ["boar.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/boar.png",
    ["bouquet.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/bouquet.png",
    ["bug.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/bug.png",
    ["butterfly.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/butterfly.png",
    ["cactus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/cactus.png",
    ["camel.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/camel.png",
    ["cat-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/cat-face.png",
    ["cat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/cat.png",
    ["cherry-blossom.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/cherry-blossom.png",
    ["chicken.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/chicken.png",
    ["chipmunk.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/chipmunk.png",
    ["christmas-tree.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/christmas-tree.png",
    ["cloud-with-lightning-and-rain.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/cloud-with-lightning-and-rain.png",
    ["cloud-with-lightning.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/cloud-with-lightning.png",
    ["cloud-with-rain.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/cloud-with-rain.png",
    ["cloud-with-snow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/cloud-with-snow.png",
    ["cloud.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/cloud.png",
    ["cockroach.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/cockroach.png",
    ["collision.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/collision.png",
    ["comet.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/comet.png",
    ["cow-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/cow-face.png",
    ["cow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/cow.png",
    ["crab.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/crab.png",
    ["crescent-moon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/crescent-moon.png",
    ["cricket.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/cricket.png",
    ["crocodile.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/crocodile.png",
    ["dashing-away.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/dashing-away.png",
    ["deciduous-tree.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/deciduous-tree.png",
    ["deer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/deer.png",
    ["dizzy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/dizzy.png",
    ["dodo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/dodo.png",
    ["dog-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/dog-face.png",
    ["dog.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/dog.png",
    ["dolphin.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/dolphin.png",
    ["dove.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/dove.png",
    ["dragon-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/dragon-face.png",
    ["dragon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/dragon.png",
    ["droplet.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/droplet.png",
    ["duck.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/duck.png",
    ["eagle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/eagle.png",
    ["elephant.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/elephant.png",
    ["evergreen-tree.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/evergreen-tree.png",
    ["ewe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/ewe.png",
    ["fallen-leaf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/fallen-leaf.png",
    ["feather.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/feather.png",
    ["fire.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/fire.png",
    ["first-quarter-moon-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/first-quarter-moon-face.png",
    ["first-quarter-moon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/first-quarter-moon.png",
    ["fish.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/fish.png",
    ["flamingo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/flamingo.png",
    ["fly.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/fly.png",
    ["fog.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/fog.png",
    ["four-leaf-clover.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/four-leaf-clover.png",
    ["fox.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/fox.png",
    ["frog.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/frog.png",
    ["front-facing-baby-chick.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/front-facing-baby-chick.png",
    ["full-moon-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/full-moon-face.png",
    ["full-moon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/full-moon.png",
    ["giraffe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/giraffe.png",
    ["globe-showing-americas.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/globe-showing-americas.png",
    ["globe-showing-asia-australia.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/globe-showing-asia-australia.png",
    ["globe-showing-europe-africa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/globe-showing-europe-africa.png",
    ["glowing-star.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/glowing-star.png",
    ["goat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/goat.png",
    ["gorilla.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/gorilla.png",
    ["guide-dog.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/guide-dog.png",
    ["hamster.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/hamster.png",
    ["hatching-chick.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/hatching-chick.png",
    ["hear-no-evil-monkey.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/hear-no-evil-monkey.png",
    ["hedgehog.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/hedgehog.png",
    ["herb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/herb.png",
    ["hibiscus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/hibiscus.png",
    ["high-voltage.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/high-voltage.png",
    ["hippopotamus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/hippopotamus.png",
    ["honeybee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/honeybee.png",
    ["horse-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/horse-face.png",
    ["horse.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/horse.png",
    ["kangaroo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/kangaroo.png",
    ["koala.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/koala.png",
    ["lady-beetle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/lady-beetle.png",
    ["last-quarter-moon-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/last-quarter-moon-face.png",
    ["last-quarter-moon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/last-quarter-moon.png",
    ["leaf-fluttering-in-wind.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/leaf-fluttering-in-wind.png",
    ["leopard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/leopard.png",
    ["lion.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/lion.png",
    ["lizard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/lizard.png",
    ["llama.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/llama.png",
    ["lobster.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/lobster.png",
    ["mammoth.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/mammoth.png",
    ["maple-leaf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/maple-leaf.png",
    ["monkey-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/monkey-face.png",
    ["monkey.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/monkey.png",
    ["mosquito.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/mosquito.png",
    ["mouse-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/mouse-face.png",
    ["mouse.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/mouse.png",
    ["mushroom.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/mushroom.png",
    ["new-moon-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/new-moon-face.png",
    ["new-moon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/new-moon.png",
    ["octopus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/octopus.png",
    ["orangutan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/orangutan.png",
    ["otter.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/otter.png",
    ["owl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/owl.png",
    ["ox.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/ox.png",
    ["palm-tree.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/palm-tree.png",
    ["panda.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/panda.png",
    ["parrot.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/parrot.png",
    ["paw-prints.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/paw-prints.png",
    ["peacock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/peacock.png",
    ["penguin.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/penguin.png",
    ["pig-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/pig-face.png",
    ["pig-nose.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/pig-nose.png",
    ["pig.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/pig.png",
    ["pine-decoration.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/pine-decoration.png",
    ["polar-bear.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/polar-bear.png",
    ["poodle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/poodle.png",
    ["potted-plant.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/potted-plant.png",
    ["rabbit-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/rabbit-face.png",
    ["rabbit.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/rabbit.png",
    ["raccoon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/raccoon.png",
    ["rainbow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/rainbow.png",
    ["ram.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/ram.png",
    ["rat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/rat.png",
    ["rhinoceros.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/rhinoceros.png",
    ["ringed-planet.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/ringed-planet.png",
    ["rock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/rock.png",
    ["rooster.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/rooster.png",
    ["rose.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/rose.png",
    ["sauropod.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/sauropod.png",
    ["scorpion.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/scorpion.png",
    ["seal.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/seal.png",
    ["see-no-evil-monkey.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/see-no-evil-monkey.png",
    ["seedling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/seedling.png",
    ["service-dog.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/service-dog.png",
    ["shamrock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/shamrock.png",
    ["shark.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/shark.png",
    ["sheaf-of-rice.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/sheaf-of-rice.png",
    ["shrimp.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/shrimp.png",
    ["skunk.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/skunk.png",
    ["sloth.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/sloth.png",
    ["snail.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/snail.png",
    ["snake.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/snake.png",
    ["snowflake.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/snowflake.png",
    ["snowman-without-snow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/snowman-without-snow.png",
    ["snowman.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/snowman.png",
    ["sparkles.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/sparkles.png",
    ["speak-no-evil-monkey.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/speak-no-evil-monkey.png",
    ["spider-web.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/spider-web.png",
    ["spider.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/spider.png",
    ["spiral-shell.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/spiral-shell.png",
    ["spouting-whale.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/spouting-whale.png",
    ["squid.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/squid.png",
    ["star.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/star.png",
    ["sun-behind-cloud.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/sun-behind-cloud.png",
    ["sun-behind-large-cloud.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/sun-behind-large-cloud.png",
    ["sun-behind-rain-cloud.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/sun-behind-rain-cloud.png",
    ["sun-behind-small-cloud.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/sun-behind-small-cloud.png",
    ["sun-with-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/sun-with-face.png",
    ["sun.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/sun.png",
    ["sunflower.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/sunflower.png",
    ["swan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/swan.png",
    ["sweat-droplets.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/sweat-droplets.png",
    ["t-rex.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/t-rex.png",
    ["tanabata-tree.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/tanabata-tree.png",
    ["tiger-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/tiger-face.png",
    ["tiger.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/tiger.png",
    ["tornado.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/tornado.png",
    ["tropical-fish.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/tropical-fish.png",
    ["tulip.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/tulip.png",
    ["turkey.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/turkey.png",
    ["turtle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/turtle.png",
    ["two-hump-camel.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/two-hump-camel.png",
    ["umbrella-with-rain-drops.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/umbrella-with-rain-drops.png",
    ["umbrella.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/umbrella.png",
    ["unicorn.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/unicorn.png",
    ["waning-crescent-moon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/waning-crescent-moon.png",
    ["waning-gibbous-moon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/waning-gibbous-moon.png",
    ["water-buffalo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/water-buffalo.png",
    ["water-wave.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/water-wave.png",
    ["waxing-crescent-moon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/waxing-crescent-moon.png",
    ["waxing-gibbous-moon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/waxing-gibbous-moon.png",
    ["whale.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/whale.png",
    ["wilted-flower.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/wilted-flower.png",
    ["wind-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/wind-face.png",
    ["wolf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/wolf.png",
    ["wood.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/wood.png",
    ["worm.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/worm.png",
    ["zebra.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/nature/zebra.png",
  },
  objects: {
    ["abacus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/abacus.png",
    ["adhesive-bandage.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/adhesive-bandage.png",
    ["alarm-clock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/alarm-clock.png",
    ["alembic.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/alembic.png",
    ["amphora.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/amphora.png",
    ["axe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/axe.png",
    ["balance-scale.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/balance-scale.png",
    ["balloon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/balloon.png",
    ["ballot-box-with-ballot.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/ballot-box-with-ballot.png",
    ["bar-chart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/bar-chart.png",
    ["barber-pole.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/barber-pole.png",
    ["basket.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/basket.png",
    ["bathtub.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/bathtub.png",
    ["battery.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/battery.png",
    ["bed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/bed.png",
    ["bellhop-bell.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/bellhop-bell.png",
    ["black-nib.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/black-nib.png",
    ["blue-book.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/blue-book.png",
    ["bomb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/bomb.png",
    ["bookmark-tabs.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/bookmark-tabs.png",
    ["bookmark.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/bookmark.png",
    ["books.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/books.png",
    ["brick.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/brick.png",
    ["broom.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/broom.png",
    ["bucket.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/bucket.png",
    ["calendar.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/calendar.png",
    ["camera-with-flash.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/camera-with-flash.png",
    ["camera.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/camera.png",
    ["candle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/candle.png",
    ["card-file-box.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/card-file-box.png",
    ["card-index-dividers.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/card-index-dividers.png",
    ["card-index.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/card-index.png",
    ["carp-streamer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/carp-streamer.png",
    ["carpentry-saw.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/carpentry-saw.png",
    ["chains.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/chains.png",
    ["chair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/chair.png",
    ["chart-decreasing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/chart-decreasing.png",
    ["chart-increasing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/chart-increasing.png",
    ["cigarette.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/cigarette.png",
    ["clamp.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/clamp.png",
    ["clipboard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/clipboard.png",
    ["closed-book.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/closed-book.png",
    ["closed-mailbox-with-lowered-flag.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/closed-mailbox-with-lowered-flag.png",
    ["closed-mailbox-with-raised-flag.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/closed-mailbox-with-raised-flag.png",
    ["coffin.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/coffin.png",
    ["coin.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/coin.png",
    ["compass.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/compass.png",
    ["computer-disk.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/computer-disk.png",
    ["computer-mouse.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/computer-mouse.png",
    ["confetti-ball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/confetti-ball.png",
    ["control-knobs.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/control-knobs.png",
    ["couch-and-lamp.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/couch-and-lamp.png",
    ["crayon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/crayon.png",
    ["credit-card.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/credit-card.png",
    ["crossed-swords.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/crossed-swords.png",
    ["crystal-ball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/crystal-ball.png",
    ["dagger.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/dagger.png",
    ["desktop-computer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/desktop-computer.png",
    ["diya-lamp.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/diya-lamp.png",
    ["dna.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/dna.png",
    ["dollar-banknote.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/dollar-banknote.png",
    ["door.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/door.png",
    ["drop-of-blood.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/drop-of-blood.png",
    ["dvd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/dvd.png",
    ["e-mail.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/e-mail.png",
    ["electric-plug.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/electric-plug.png",
    ["envelope-with-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/envelope-with-arrow.png",
    ["envelope.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/envelope.png",
    ["euro-banknote.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/euro-banknote.png",
    ["fax-machine.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/fax-machine.png",
    ["file-cabinet.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/file-cabinet.png",
    ["file-folder.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/file-folder.png",
    ["film-frames.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/film-frames.png",
    ["film-projector.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/film-projector.png",
    ["fire-extinguisher.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/fire-extinguisher.png",
    ["firecracker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/firecracker.png",
    ["flashlight.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/flashlight.png",
    ["floppy-disk.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/floppy-disk.png",
    ["fountain-pen.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/fountain-pen.png",
    ["framed-picture.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/framed-picture.png",
    ["funeral-urn.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/funeral-urn.png",
    ["gear.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/gear.png",
    ["gem-stone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/gem-stone.png",
    ["green-book.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/green-book.png",
    ["hammer-and-pick.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/hammer-and-pick.png",
    ["hammer-and-wrench.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/hammer-and-wrench.png",
    ["hammer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/hammer.png",
    ["headstone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/headstone.png",
    ["hole.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/hole.png",
    ["hook.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/hook.png",
    ["hourglass-done.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/hourglass-done.png",
    ["hourglass-not-done.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/hourglass-not-done.png",
    ["inbox-tray.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/inbox-tray.png",
    ["incoming-envelope.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/incoming-envelope.png",
    ["japanese-dolls.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/japanese-dolls.png",
    ["joystick.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/joystick.png",
    ["key.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/key.png",
    ["keyboard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/keyboard.png",
    ["kitchen-knife.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/kitchen-knife.png",
    ["knot.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/knot.png",
    ["label.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/label.png",
    ["ladder.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/ladder.png",
    ["laptop-computer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/laptop-computer.png",
    ["ledger.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/ledger.png",
    ["level-slider.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/level-slider.png",
    ["light-bulb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/light-bulb.png",
    ["link.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/link.png",
    ["linked-paperclips.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/linked-paperclips.png",
    ["locked-with-key.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/locked-with-key.png",
    ["locked-with-pen.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/locked-with-pen.png",
    ["locked.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/locked.png",
    ["lotion-bottle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/lotion-bottle.png",
    ["love-letter.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/love-letter.png",
    ["magic-wand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/magic-wand.png",
    ["magnet.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/magnet.png",
    ["magnifying-glass-tilted-left.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/magnifying-glass-tilted-left.png",
    ["magnifying-glass-tilted-right.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/magnifying-glass-tilted-right.png",
    ["mantelpiece-clock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/mantelpiece-clock.png",
    ["memo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/memo.png",
    ["microbe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/microbe.png",
    ["microscope.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/microscope.png",
    ["mirror.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/mirror.png",
    ["mobile-phone-with-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/mobile-phone-with-arrow.png",
    ["mobile-phone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/mobile-phone.png",
    ["money-bag.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/money-bag.png",
    ["money-with-wings.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/money-with-wings.png",
    ["mouse-trap.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/mouse-trap.png",
    ["movie-camera.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/movie-camera.png",
    ["nazar-amulet.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/nazar-amulet.png",
    ["nesting-dolls.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/nesting-dolls.png",
    ["newspaper.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/newspaper.png",
    ["notebook-with-decorative-cover.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/notebook-with-decorative-cover.png",
    ["notebook.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/notebook.png",
    ["nut-and-bolt.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/nut-and-bolt.png",
    ["oil-drum.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/oil-drum.png",
    ["old-key.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/old-key.png",
    ["open-book.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/open-book.png",
    ["open-file-folder.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/open-file-folder.png",
    ["open-mailbox-with-lowered-flag.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/open-mailbox-with-lowered-flag.png",
    ["open-mailbox-with-raised-flag.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/open-mailbox-with-raised-flag.png",
    ["optical-disk.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/optical-disk.png",
    ["orange-book.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/orange-book.png",
    ["outbox-tray.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/outbox-tray.png",
    ["package.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/package.png",
    ["page-facing-up.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/page-facing-up.png",
    ["page-with-curl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/page-with-curl.png",
    ["pager.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/pager.png",
    ["paintbrush.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/paintbrush.png",
    ["paperclip.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/paperclip.png",
    ["party-popper.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/party-popper.png",
    ["pen.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/pen.png",
    ["pencil.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/pencil.png",
    ["person-in-bed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/person-in-bed.png",
    ["person-taking-bath.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/person-taking-bath.png",
    ["petri-dish.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/petri-dish.png",
    ["pick.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/pick.png",
    ["pill.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/pill.png",
    ["pinata.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/pinata.png",
    ["pistol.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/pistol.png",
    ["placard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/placard.png",
    ["plunger.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/plunger.png",
    ["postal-horn.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/postal-horn.png",
    ["postbox.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/postbox.png",
    ["potable-water.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/potable-water.png",
    ["pound-banknote.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/pound-banknote.png",
    ["prayer-beads.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/prayer-beads.png",
    ["printer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/printer.png",
    ["pushpin.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/pushpin.png",
    ["radio.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/radio.png",
    ["razor.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/razor.png",
    ["receipt.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/receipt.png",
    ["red-envelope.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/red-envelope.png",
    ["red-paper-lantern.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/red-paper-lantern.png",
    ["ribbon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/ribbon.png",
    ["roll-of-paper.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/roll-of-paper.png",
    ["rolled-up-newspaper.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/rolled-up-newspaper.png",
    ["round-pushpin.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/round-pushpin.png",
    ["safety-pin.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/safety-pin.png",
    ["satellite-antenna.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/satellite-antenna.png",
    ["scissors.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/scissors.png",
    ["screwdriver.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/screwdriver.png",
    ["scroll.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/scroll.png",
    ["sewing-needle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/sewing-needle.png",
    ["shield.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/shield.png",
    ["shopping-bags.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/shopping-bags.png",
    ["shopping-cart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/shopping-cart.png",
    ["shower.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/shower.png",
    ["soap.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/soap.png",
    ["spiral-calendar.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/spiral-calendar.png",
    ["spiral-notepad.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/spiral-notepad.png",
    ["sponge.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/sponge.png",
    ["stethoscope.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/stethoscope.png",
    ["stopwatch.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/stopwatch.png",
    ["straight-ruler.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/straight-ruler.png",
    ["studio-microphone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/studio-microphone.png",
    ["syringe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/syringe.png",
    ["tear-off-calendar.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/tear-off-calendar.png",
    ["teddy-bear.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/teddy-bear.png",
    ["telephone-receiver.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/telephone-receiver.png",
    ["telephone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/telephone.png",
    ["telescope.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/telescope.png",
    ["television.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/television.png",
    ["test-tube.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/test-tube.png",
    ["thermometer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/thermometer.png",
    ["timer-clock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/timer-clock.png",
    ["toilet.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/toilet.png",
    ["toolbox.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/toolbox.png",
    ["toothbrush.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/toothbrush.png",
    ["trackball.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/trackball.png",
    ["triangular-ruler.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/triangular-ruler.png",
    ["unlocked.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/unlocked.png",
    ["video-camera.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/video-camera.png",
    ["videocassette.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/videocassette.png",
    ["wastebasket.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/wastebasket.png",
    ["watch.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/watch.png",
    ["wind-chime.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/wind-chime.png",
    ["window.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/window.png",
    ["wrapped-gift.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/wrapped-gift.png",
    ["wrench.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/wrench.png",
    ["yen-banknote.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/objects/yen-banknote.png",
  },
  people: {
    ["alien-monster.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/alien-monster.png",
    ["alien.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/alien.png",
    ["anatomical-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/anatomical-heart.png",
    ["angry-face-with-horns.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/angry-face-with-horns.png",
    ["angry-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/angry-face.png",
    ["anguished-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/anguished-face.png",
    ["anxious-face-with-sweat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/anxious-face-with-sweat.png",
    ["artist.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/artist.png",
    ["astonished-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/astonished-face.png",
    ["astronaut.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/astronaut.png",
    ["baby-angel.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/baby-angel.png",
    ["baby.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/baby.png",
    ["backhand-index-pointing-down.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/backhand-index-pointing-down.png",
    ["backhand-index-pointing-left.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/backhand-index-pointing-left.png",
    ["backhand-index-pointing-right.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/backhand-index-pointing-right.png",
    ["backhand-index-pointing-up.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/backhand-index-pointing-up.png",
    ["backpack.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/backpack.png",
    ["bald.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/bald.png",
    ["beaming-face-with-smiling-eyes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/beaming-face-with-smiling-eyes.png",
    ["bikini.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/bikini.png",
    ["billed-cap.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/billed-cap.png",
    ["bone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/bone.png",
    ["boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/boy.png",
    ["brain.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/brain.png",
    ["breast-feeding.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/breast-feeding.png",
    ["briefcase.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/briefcase.png",
    ["briefs.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/briefs.png",
    ["bust-in-silhouette.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/bust-in-silhouette.png",
    ["busts-in-silhouette.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/busts-in-silhouette.png",
    ["call-me-hand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/call-me-hand.png",
    ["cat-with-tears-of-joy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/cat-with-tears-of-joy.png",
    ["cat-with-wry-smile.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/cat-with-wry-smile.png",
    ["child.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/child.png",
    ["clapping-hands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/clapping-hands.png",
    ["closed-umbrella.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/closed-umbrella.png",
    ["clown-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/clown-face.png",
    ["clutch-bag.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/clutch-bag.png",
    ["coat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/coat.png",
    ["cold-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/cold-face.png",
    ["confounded-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/confounded-face.png",
    ["confused-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/confused-face.png",
    ["construction-worker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/construction-worker.png",
    ["cook.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/cook.png",
    ["couple-with-heart-man-man.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/couple-with-heart-man-man.png",
    ["couple-with-heart-woman-man.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/couple-with-heart-woman-man.png",
    ["couple-with-heart-woman-woman.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/couple-with-heart-woman-woman.png",
    ["couple-with-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/couple-with-heart.png",
    ["cowboy-hat-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/cowboy-hat-face.png",
    ["crossed-fingers.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/crossed-fingers.png",
    ["crown.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/crown.png",
    ["crying-cat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/crying-cat.png",
    ["crying-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/crying-face.png",
    ["curly-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/curly-hair.png",
    ["deaf-man.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/deaf-man.png",
    ["deaf-person.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/deaf-person.png",
    ["deaf-woman.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/deaf-woman.png",
    ["detective.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/detective.png",
    ["disappointed-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/disappointed-face.png",
    ["disguised-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/disguised-face.png",
    ["dizzy-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/dizzy-face.png",
    ["downcast-face-with-sweat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/downcast-face-with-sweat.png",
    ["dress.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/dress.png",
    ["drooling-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/drooling-face.png",
    ["ear-with-hearing-aid.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/ear-with-hearing-aid.png",
    ["ear.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/ear.png",
    ["elf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/elf.png",
    ["exploding-head.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/exploding-head.png",
    ["expressionless-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/expressionless-face.png",
    ["eye.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/eye.png",
    ["eyes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/eyes.png",
    ["face-blowing-a-kiss.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-blowing-a-kiss.png",
    ["face-exhaling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-exhaling.png",
    ["face-in-clouds.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-in-clouds.png",
    ["face-savoring-food.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-savoring-food.png",
    ["face-screaming-in-fear.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-screaming-in-fear.png",
    ["face-vomiting.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-vomiting.png",
    ["face-with-hand-over-mouth.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-with-hand-over-mouth.png",
    ["face-with-head-bandage.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-with-head-bandage.png",
    ["face-with-medical-mask.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-with-medical-mask.png",
    ["face-with-monocle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-with-monocle.png",
    ["face-with-open-mouth.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-with-open-mouth.png",
    ["face-with-raised-eyebrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-with-raised-eyebrow.png",
    ["face-with-rolling-eyes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-with-rolling-eyes.png",
    ["face-with-spiral-eyes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-with-spiral-eyes.png",
    ["face-with-steam-from-nose.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-with-steam-from-nose.png",
    ["face-with-symbols-on-mouth.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-with-symbols-on-mouth.png",
    ["face-with-tears-of-joy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-with-tears-of-joy.png",
    ["face-with-thermometer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-with-thermometer.png",
    ["face-with-tongue.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-with-tongue.png",
    ["face-without-mouth.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/face-without-mouth.png",
    ["factory-worker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/factory-worker.png",
    ["fairy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/fairy.png",
    ["family-man-boy-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-boy-boy.png",
    ["family-man-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-boy.png",
    ["family-man-girl-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-girl-boy.png",
    ["family-man-girl-girl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-girl-girl.png",
    ["family-man-girl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-girl.png",
    ["family-man-man-boy-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-man-boy-boy.png",
    ["family-man-man-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-man-boy.png",
    ["family-man-man-girl-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-man-girl-boy.png",
    ["family-man-man-girl-girl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-man-girl-girl.png",
    ["family-man-man-girl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-man-girl.png",
    ["family-man-woman-boy-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-woman-boy-boy.png",
    ["family-man-woman-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-woman-boy.png",
    ["family-man-woman-girl-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-woman-girl-boy.png",
    ["family-man-woman-girl-girl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-woman-girl-girl.png",
    ["family-man-woman-girl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-man-woman-girl.png",
    ["family-woman-boy-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-woman-boy-boy.png",
    ["family-woman-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-woman-boy.png",
    ["family-woman-girl-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-woman-girl-boy.png",
    ["family-woman-girl-girl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-woman-girl-girl.png",
    ["family-woman-girl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-woman-girl.png",
    ["family-woman-woman-boy-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-woman-woman-boy-boy.png",
    ["family-woman-woman-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-woman-woman-boy.png",
    ["family-woman-woman-girl-boy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-woman-woman-girl-boy.png",
    ["family-woman-woman-girl-girl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-woman-woman-girl-girl.png",
    ["family-woman-woman-girl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family-woman-woman-girl.png",
    ["family.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/family.png",
    ["farmer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/farmer.png",
    ["fearful-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/fearful-face.png",
    ["firefighter.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/firefighter.png",
    ["flat-shoe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/flat-shoe.png",
    ["flexed-biceps.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/flexed-biceps.png",
    ["flushed-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/flushed-face.png",
    ["folded-hands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/folded-hands.png",
    ["foot.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/foot.png",
    ["footprints.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/footprints.png",
    ["frowning-face-with-open-mouth.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/frowning-face-with-open-mouth.png",
    ["frowning-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/frowning-face.png",
    ["genie.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/genie.png",
    ["ghost.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/ghost.png",
    ["girl.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/girl.png",
    ["glasses.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/glasses.png",
    ["gloves.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/gloves.png",
    ["goblin.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/goblin.png",
    ["goggles.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/goggles.png",
    ["graduation-cap.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/graduation-cap.png",
    ["grimacing-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/grimacing-face.png",
    ["grinning-cat-with-smiling-eyes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/grinning-cat-with-smiling-eyes.png",
    ["grinning-cat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/grinning-cat.png",
    ["grinning-face-with-big-eyes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/grinning-face-with-big-eyes.png",
    ["grinning-face-with-smiling-eyes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/grinning-face-with-smiling-eyes.png",
    ["grinning-face-with-sweat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/grinning-face-with-sweat.png",
    ["grinning-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/grinning-face.png",
    ["grinning-squinting-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/grinning-squinting-face.png",
    ["guard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/guard.png",
    ["hand-with-fingers-splayed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/hand-with-fingers-splayed.png",
    ["handbag.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/handbag.png",
    ["handshake.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/handshake.png",
    ["health-worker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/health-worker.png",
    ["high-heeled-shoe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/high-heeled-shoe.png",
    ["hiking-boot.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/hiking-boot.png",
    ["hot-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/hot-face.png",
    ["hugging-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/hugging-face.png",
    ["hushed-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/hushed-face.png",
    ["index-pointing-up.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/index-pointing-up.png",
    ["jack-o-lantern.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/jack-o-lantern.png",
    ["jeans.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/jeans.png",
    ["judge.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/judge.png",
    ["kimono.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/kimono.png",
    ["kiss-man-man.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/kiss-man-man.png",
    ["kiss-mark.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/kiss-mark.png",
    ["kiss-woman-man.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/kiss-woman-man.png",
    ["kiss-woman-woman.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/kiss-woman-woman.png",
    ["kiss.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/kiss.png",
    ["kissing-cat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/kissing-cat.png",
    ["kissing-face-with-closed-eyes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/kissing-face-with-closed-eyes.png",
    ["kissing-face-with-smiling-eyes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/kissing-face-with-smiling-eyes.png",
    ["kissing-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/kissing-face.png",
    ["lab-coat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/lab-coat.png",
    ["left-facing-fist.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/left-facing-fist.png",
    ["leg.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/leg.png",
    ["lipstick.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/lipstick.png",
    ["loudly-crying-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/loudly-crying-face.png",
    ["love-you-gesture.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/love-you-gesture.png",
    ["luggage.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/luggage.png",
    ["lungs.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/lungs.png",
    ["lying-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/lying-face.png",
    ["mage.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/mage.png",
    ["man-artist.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-artist.png",
    ["man-astronaut.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-astronaut.png",
    ["man-bald.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-bald.png",
    ["man-beard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-beard.png",
    ["man-blond-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-blond-hair.png",
    ["man-bowing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-bowing.png",
    ["man-construction-worker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-construction-worker.png",
    ["man-cook.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-cook.png",
    ["man-curly-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-curly-hair.png",
    ["man-dancing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-dancing.png",
    ["man-detective.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-detective.png",
    ["man-elf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-elf.png",
    ["man-facepalming.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-facepalming.png",
    ["man-factory-worker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-factory-worker.png",
    ["man-fairy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-fairy.png",
    ["man-farmer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-farmer.png",
    ["man-feeding-baby.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-feeding-baby.png",
    ["man-firefighter.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-firefighter.png",
    ["man-frowning.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-frowning.png",
    ["man-genie.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-genie.png",
    ["man-gesturing-no.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-gesturing-no.png",
    ["man-gesturing-ok.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-gesturing-ok.png",
    ["man-getting-haircut.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-getting-haircut.png",
    ["man-getting-massage.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-getting-massage.png",
    ["man-guard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-guard.png",
    ["man-health-worker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-health-worker.png",
    ["man-in-manual-wheelchair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-in-manual-wheelchair.png",
    ["man-in-motorized-wheelchair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-in-motorized-wheelchair.png",
    ["man-in-steamy-room.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-in-steamy-room.png",
    ["man-in-suit-levitating.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-in-suit-levitating.png",
    ["man-in-tuxedo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-in-tuxedo.png",
    ["man-judge.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-judge.png",
    ["man-kneeling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-kneeling.png",
    ["man-mage.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-mage.png",
    ["man-mechanic.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-mechanic.png",
    ["man-office-worker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-office-worker.png",
    ["man-pilot.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-pilot.png",
    ["man-police-officer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-police-officer.png",
    ["man-pouting.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-pouting.png",
    ["man-raising-hand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-raising-hand.png",
    ["man-red-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-red-hair.png",
    ["man-running.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-running.png",
    ["man-scientist.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-scientist.png",
    ["man-shrugging.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-shrugging.png",
    ["man-singer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-singer.png",
    ["man-standing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-standing.png",
    ["man-student.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-student.png",
    ["man-superhero.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-superhero.png",
    ["man-supervillain.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-supervillain.png",
    ["man-teacher.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-teacher.png",
    ["man-technologist.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-technologist.png",
    ["man-tipping-hand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-tipping-hand.png",
    ["man-vampire.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-vampire.png",
    ["man-walking.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-walking.png",
    ["man-wearing-turban.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-wearing-turban.png",
    ["man-white-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-white-hair.png",
    ["man-with-chinese-cap.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-with-chinese-cap.png",
    ["man-with-probing-cane.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-with-probing-cane.png",
    ["man-with-veil.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-with-veil.png",
    ["man-zombie.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man-zombie.png",
    ["man.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/man.png",
    ["mans-shoe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/mans-shoe.png",
    ["mechanic.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/mechanic.png",
    ["mechanical-arm.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/mechanical-arm.png",
    ["mechanical-leg.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/mechanical-leg.png",
    ["men-holding-hands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/men-holding-hands.png",
    ["men-with-bunny-ears.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/men-with-bunny-ears.png",
    ["mermaid.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/mermaid.png",
    ["merman.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/merman.png",
    ["merperson.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/merperson.png",
    ["middle-finger.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/middle-finger.png",
    ["military-helmet.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/military-helmet.png",
    ["money-mouth-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/money-mouth-face.png",
    ["mouth.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/mouth.png",
    ["mrs-claus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/mrs-claus.png",
    ["mx-claus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/mx-claus.png",
    ["nail-polish.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/nail-polish.png",
    ["nauseated-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/nauseated-face.png",
    ["necktie.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/necktie.png",
    ["nerd-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/nerd-face.png",
    ["neutral-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/neutral-face.png",
    ["ninja.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/ninja.png",
    ["nose.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/nose.png",
    ["office-worker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/office-worker.png",
    ["ogre.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/ogre.png",
    ["ok-hand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/ok-hand.png",
    ["old-man.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/old-man.png",
    ["old-woman.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/old-woman.png",
    ["older-person.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/older-person.png",
    ["oncoming-fist.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/oncoming-fist.png",
    ["one-piece-swimsuit.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/one-piece-swimsuit.png",
    ["open-hands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/open-hands.png",
    ["palms-up-together.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/palms-up-together.png",
    ["partying-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/partying-face.png",
    ["pensive-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/pensive-face.png",
    ["people-holding-hands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/people-holding-hands.png",
    ["people-hugging.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/people-hugging.png",
    ["people-with-bunny-ears.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/people-with-bunny-ears.png",
    ["persevering-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/persevering-face.png",
    ["person-bald.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-bald.png",
    ["person-beard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-beard.png",
    ["person-blond-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-blond-hair.png",
    ["person-bowing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-bowing.png",
    ["person-curly-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-curly-hair.png",
    ["person-facepalming.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-facepalming.png",
    ["person-feeding-baby.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-feeding-baby.png",
    ["person-frowning.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-frowning.png",
    ["person-gesturing-no.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-gesturing-no.png",
    ["person-gesturing-ok.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-gesturing-ok.png",
    ["person-getting-haircut.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-getting-haircut.png",
    ["person-getting-massage.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-getting-massage.png",
    ["person-in-manual-wheelchair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-in-manual-wheelchair.png",
    ["person-in-motorized-wheelchair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-in-motorized-wheelchair.png",
    ["person-in-steamy-room.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-in-steamy-room.png",
    ["person-in-tuxedo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-in-tuxedo.png",
    ["person-kneeling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-kneeling.png",
    ["person-pouting.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-pouting.png",
    ["person-raising-hand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-raising-hand.png",
    ["person-red-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-red-hair.png",
    ["person-running.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-running.png",
    ["person-shrugging.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-shrugging.png",
    ["person-standing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-standing.png",
    ["person-tipping-hand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-tipping-hand.png",
    ["person-walking.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-walking.png",
    ["person-wearing-turban.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-wearing-turban.png",
    ["person-white-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-white-hair.png",
    ["person-with-probing-cane.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-with-probing-cane.png",
    ["person-with-veil.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person-with-veil.png",
    ["person.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/person.png",
    ["pile-of-poo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/pile-of-poo.png",
    ["pilot.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/pilot.png",
    ["pinched-fingers.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/pinched-fingers.png",
    ["pinching-hand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/pinching-hand.png",
    ["pleading-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/pleading-face.png",
    ["police-officer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/police-officer.png",
    ["pouting-cat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/pouting-cat.png",
    ["pouting-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/pouting-face.png",
    ["pregnant-woman.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/pregnant-woman.png",
    ["prince.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/prince.png",
    ["princess.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/princess.png",
    ["purse.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/purse.png",
    ["raised-back-of-hand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/raised-back-of-hand.png",
    ["raised-fist.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/raised-fist.png",
    ["raised-hand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/raised-hand.png",
    ["raising-hands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/raising-hands.png",
    ["red-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/red-hair.png",
    ["relieved-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/relieved-face.png",
    ["rescue-workers-helmet.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/rescue-workers-helmet.png",
    ["right-facing-fist.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/right-facing-fist.png",
    ["ring.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/ring.png",
    ["robot.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/robot.png",
    ["rolling-on-the-floor-laughing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/rolling-on-the-floor-laughing.png",
    ["running-shoe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/running-shoe.png",
    ["sad-but-relieved-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/sad-but-relieved-face.png",
    ["safety-vest.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/safety-vest.png",
    ["santa-claus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/santa-claus.png",
    ["sari.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/sari.png",
    ["scarf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/scarf.png",
    ["scientist.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/scientist.png",
    ["selfie.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/selfie.png",
    ["shorts.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/shorts.png",
    ["shushing-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/shushing-face.png",
    ["sign-of-the-horns.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/sign-of-the-horns.png",
    ["singer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/singer.png",
    ["skull-and-crossbones.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/skull-and-crossbones.png",
    ["skull.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/skull.png",
    ["sleeping-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/sleeping-face.png",
    ["sleepy-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/sleepy-face.png",
    ["slightly-frowning-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/slightly-frowning-face.png",
    ["slightly-smiling-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/slightly-smiling-face.png",
    ["smiling-cat-with-heart-eyes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/smiling-cat-with-heart-eyes.png",
    ["smiling-face-with-halo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/smiling-face-with-halo.png",
    ["smiling-face-with-heart-eyes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/smiling-face-with-heart-eyes.png",
    ["smiling-face-with-hearts.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/smiling-face-with-hearts.png",
    ["smiling-face-with-horns.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/smiling-face-with-horns.png",
    ["smiling-face-with-smiling-eyes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/smiling-face-with-smiling-eyes.png",
    ["smiling-face-with-sunglasses.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/smiling-face-with-sunglasses.png",
    ["smiling-face-with-tear.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/smiling-face-with-tear.png",
    ["smiling-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/smiling-face.png",
    ["smirking-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/smirking-face.png",
    ["sneezing-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/sneezing-face.png",
    ["socks.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/socks.png",
    ["speaking-head.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/speaking-head.png",
    ["squinting-face-with-tongue.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/squinting-face-with-tongue.png",
    ["star-struck.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/star-struck.png",
    ["student.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/student.png",
    ["sunglasses.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/sunglasses.png",
    ["superhero.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/superhero.png",
    ["supervillain.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/supervillain.png",
    ["t-shirt.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/t-shirt.png",
    ["teacher.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/teacher.png",
    ["technologist.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/technologist.png",
    ["thinking-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/thinking-face.png",
    ["thong-sandal.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/thong-sandal.png",
    ["thread.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/thread.png",
    ["thumbs-down.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/thumbs-down.png",
    ["thumbs-up.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/thumbs-up.png",
    ["tired-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/tired-face.png",
    ["tongue.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/tongue.png",
    ["tooth.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/tooth.png",
    ["top-hat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/top-hat.png",
    ["unamused-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/unamused-face.png",
    ["upside-down-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/upside-down-face.png",
    ["vampire.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/vampire.png",
    ["victory-hand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/victory-hand.png",
    ["vulcan-salute.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/vulcan-salute.png",
    ["waving-hand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/waving-hand.png",
    ["weary-cat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/weary-cat.png",
    ["weary-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/weary-face.png",
    ["white-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/white-hair.png",
    ["winking-face-with-tongue.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/winking-face-with-tongue.png",
    ["winking-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/winking-face.png",
    ["woman-and-man-holding-hands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-and-man-holding-hands.png",
    ["woman-artist.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-artist.png",
    ["woman-astronaut.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-astronaut.png",
    ["woman-bald.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-bald.png",
    ["woman-beard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-beard.png",
    ["woman-blond-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-blond-hair.png",
    ["woman-bowing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-bowing.png",
    ["woman-construction-worker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-construction-worker.png",
    ["woman-cook.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-cook.png",
    ["woman-curly-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-curly-hair.png",
    ["woman-dancing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-dancing.png",
    ["woman-detective.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-detective.png",
    ["woman-elf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-elf.png",
    ["woman-facepalming.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-facepalming.png",
    ["woman-factory-worker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-factory-worker.png",
    ["woman-fairy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-fairy.png",
    ["woman-farmer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-farmer.png",
    ["woman-feeding-baby.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-feeding-baby.png",
    ["woman-firefighter.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-firefighter.png",
    ["woman-frowning.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-frowning.png",
    ["woman-genie.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-genie.png",
    ["woman-gesturing-no.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-gesturing-no.png",
    ["woman-gesturing-ok.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-gesturing-ok.png",
    ["woman-getting-haircut.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-getting-haircut.png",
    ["woman-getting-massage.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-getting-massage.png",
    ["woman-guard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-guard.png",
    ["woman-health-worker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-health-worker.png",
    ["woman-in-manual-wheelchair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-in-manual-wheelchair.png",
    ["woman-in-motorized-wheelchair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-in-motorized-wheelchair.png",
    ["woman-in-steamy-room.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-in-steamy-room.png",
    ["woman-in-tuxedo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-in-tuxedo.png",
    ["woman-judge.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-judge.png",
    ["woman-kneeling.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-kneeling.png",
    ["woman-mage.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-mage.png",
    ["woman-mechanic.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-mechanic.png",
    ["woman-office-worker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-office-worker.png",
    ["woman-pilot.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-pilot.png",
    ["woman-police-officer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-police-officer.png",
    ["woman-pouting.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-pouting.png",
    ["woman-raising-hand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-raising-hand.png",
    ["woman-red-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-red-hair.png",
    ["woman-running.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-running.png",
    ["woman-scientist.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-scientist.png",
    ["woman-shrugging.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-shrugging.png",
    ["woman-singer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-singer.png",
    ["woman-standing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-standing.png",
    ["woman-student.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-student.png",
    ["woman-superhero.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-superhero.png",
    ["woman-supervillain.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-supervillain.png",
    ["woman-teacher.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-teacher.png",
    ["woman-technologist.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-technologist.png",
    ["woman-tipping-hand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-tipping-hand.png",
    ["woman-vampire.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-vampire.png",
    ["woman-walking.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-walking.png",
    ["woman-wearing-turban.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-wearing-turban.png",
    ["woman-white-hair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-white-hair.png",
    ["woman-with-headscarf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-with-headscarf.png",
    ["woman-with-probing-cane.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-with-probing-cane.png",
    ["woman-with-veil.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-with-veil.png",
    ["woman-zombie.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman-zombie.png",
    ["woman.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woman.png",
    ["womans-boot.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/womans-boot.png",
    ["womans-clothes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/womans-clothes.png",
    ["womans-hat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/womans-hat.png",
    ["womans-sandal.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/womans-sandal.png",
    ["women-holding-hands.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/women-holding-hands.png",
    ["women-with-bunny-ears.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/women-with-bunny-ears.png",
    ["woozy-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/woozy-face.png",
    ["worried-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/worried-face.png",
    ["writing-hand.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/writing-hand.png",
    ["yarn.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/yarn.png",
    ["yawning-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/yawning-face.png",
    ["zany-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/zany-face.png",
    ["zipper-mouth-face.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/zipper-mouth-face.png",
    ["zombie.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/people/zombie.png",
  },
  regional: {
    ["regional-indicator-symbol-letter-a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-a.png",
    ["regional-indicator-symbol-letter-b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-b.png",
    ["regional-indicator-symbol-letter-c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-c.png",
    ["regional-indicator-symbol-letter-d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-d.png",
    ["regional-indicator-symbol-letter-e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-e.png",
    ["regional-indicator-symbol-letter-f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-f.png",
    ["regional-indicator-symbol-letter-g.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-g.png",
    ["regional-indicator-symbol-letter-h.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-h.png",
    ["regional-indicator-symbol-letter-i.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-i.png",
    ["regional-indicator-symbol-letter-j.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-j.png",
    ["regional-indicator-symbol-letter-k.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-k.png",
    ["regional-indicator-symbol-letter-l.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-l.png",
    ["regional-indicator-symbol-letter-m.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-m.png",
    ["regional-indicator-symbol-letter-n.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-n.png",
    ["regional-indicator-symbol-letter-o.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-o.png",
    ["regional-indicator-symbol-letter-p.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-p.png",
    ["regional-indicator-symbol-letter-q.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-q.png",
    ["regional-indicator-symbol-letter-r.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-r.png",
    ["regional-indicator-symbol-letter-s.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-s.png",
    ["regional-indicator-symbol-letter-t.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-t.png",
    ["regional-indicator-symbol-letter-u.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-u.png",
    ["regional-indicator-symbol-letter-v.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-v.png",
    ["regional-indicator-symbol-letter-w.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-w.png",
    ["regional-indicator-symbol-letter-x.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-x.png",
    ["regional-indicator-symbol-letter-y.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-y.png",
    ["regional-indicator-symbol-letter-z.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/regional/regional-indicator-symbol-letter-z.png",
  },
  symbols: {
    ["a-button-blood-type.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/a-button-blood-type.png",
    ["ab-button-blood-type.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/ab-button-blood-type.png",
    ["anger-symbol.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/anger-symbol.png",
    ["antenna-bars.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/antenna-bars.png",
    ["aquarius.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/aquarius.png",
    ["aries.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/aries.png",
    ["asterisk.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/asterisk.png",
    ["atm-sign.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/atm-sign.png",
    ["atom-symbol.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/atom-symbol.png",
    ["b-button-blood-type.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/b-button-blood-type.png",
    ["baby-symbol.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/baby-symbol.png",
    ["back-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/back-arrow.png",
    ["baggage-claim.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/baggage-claim.png",
    ["beating-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/beating-heart.png",
    ["bell-with-slash.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/bell-with-slash.png",
    ["bell.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/bell.png",
    ["biohazard.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/biohazard.png",
    ["black-circle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/black-circle.png",
    ["black-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/black-heart.png",
    ["black-large-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/black-large-square.png",
    ["black-medium-small-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/black-medium-small-square.png",
    ["black-medium-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/black-medium-square.png",
    ["black-small-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/black-small-square.png",
    ["black-square-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/black-square-button.png",
    ["blue-circle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/blue-circle.png",
    ["blue-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/blue-heart.png",
    ["blue-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/blue-square.png",
    ["bright-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/bright-button.png",
    ["broken-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/broken-heart.png",
    ["brown-circle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/brown-circle.png",
    ["brown-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/brown-heart.png",
    ["brown-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/brown-square.png",
    ["cancer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/cancer.png",
    ["capricorn.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/capricorn.png",
    ["chart-increasing-with-yen.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/chart-increasing-with-yen.png",
    ["check-box-with-check.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/check-box-with-check.png",
    ["check-mark-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/check-mark-button.png",
    ["check-mark.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/check-mark.png",
    ["children-crossing.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/children-crossing.png",
    ["cinema.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/cinema.png",
    ["circled-m.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/circled-m.png",
    ["cl-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/cl-button.png",
    ["clockwise-vertical-arrows.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/clockwise-vertical-arrows.png",
    ["club-suit.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/club-suit.png",
    ["cool-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/cool-button.png",
    ["copyright.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/copyright.png",
    ["counterclockwise-arrows-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/counterclockwise-arrows-button.png",
    ["cross-mark-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/cross-mark-button.png",
    ["cross-mark.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/cross-mark.png",
    ["curly-loop.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/curly-loop.png",
    ["currency-exchange.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/currency-exchange.png",
    ["customs.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/customs.png",
    ["cyclone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/cyclone.png",
    ["diamond-suit.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/diamond-suit.png",
    ["diamond-with-a-dot.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/diamond-with-a-dot.png",
    ["digit-eight.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/digit-eight.png",
    ["digit-five.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/digit-five.png",
    ["digit-four.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/digit-four.png",
    ["digit-nine.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/digit-nine.png",
    ["digit-one.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/digit-one.png",
    ["digit-seven.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/digit-seven.png",
    ["digit-six.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/digit-six.png",
    ["digit-three.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/digit-three.png",
    ["digit-two.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/digit-two.png",
    ["digit-zero.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/digit-zero.png",
    ["dim-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/dim-button.png",
    ["division-sign.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/division-sign.png",
    ["dotted-six-pointed-star.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/dotted-six-pointed-star.png",
    ["double-curly-loop.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/double-curly-loop.png",
    ["double-exclamation-mark.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/double-exclamation-mark.png",
    ["down-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/down-arrow.png",
    ["down-left-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/down-left-arrow.png",
    ["down-right-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/down-right-arrow.png",
    ["downwards-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/downwards-button.png",
    ["eight-oclock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/eight-oclock.png",
    ["eight-pointed-star.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/eight-pointed-star.png",
    ["eight-spoked-asterisk.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/eight-spoked-asterisk.png",
    ["eight-thirty.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/eight-thirty.png",
    ["eject-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/eject-button.png",
    ["elevator.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/elevator.png",
    ["eleven-oclock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/eleven-oclock.png",
    ["eleven-thirty.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/eleven-thirty.png",
    ["end-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/end-arrow.png",
    ["exclamation-mark.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/exclamation-mark.png",
    ["exclamation-question-mark.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/exclamation-question-mark.png",
    ["eye-in-speech-bubble.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/eye-in-speech-bubble.png",
    ["fast-down-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/fast-down-button.png",
    ["fast-forward-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/fast-forward-button.png",
    ["fast-reverse-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/fast-reverse-button.png",
    ["fast-up-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/fast-up-button.png",
    ["female-sign.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/female-sign.png",
    ["five-oclock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/five-oclock.png",
    ["five-thirty.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/five-thirty.png",
    ["fleur-de-lis.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/fleur-de-lis.png",
    ["flower-playing-cards.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/flower-playing-cards.png",
    ["four-oclock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/four-oclock.png",
    ["four-thirty.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/four-thirty.png",
    ["free-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/free-button.png",
    ["gemini.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/gemini.png",
    ["globe-with-meridians.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/globe-with-meridians.png",
    ["green-circle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/green-circle.png",
    ["green-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/green-heart.png",
    ["green-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/green-square.png",
    ["growing-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/growing-heart.png",
    ["heart-decoration.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/heart-decoration.png",
    ["heart-exclamation.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/heart-exclamation.png",
    ["heart-on-fire.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/heart-on-fire.png",
    ["heart-suit.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/heart-suit.png",
    ["heart-with-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/heart-with-arrow.png",
    ["heart-with-ribbon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/heart-with-ribbon.png",
    ["heavy-dollar-sign.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/heavy-dollar-sign.png",
    ["hollow-red-circle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/hollow-red-circle.png",
    ["hot-springs.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/hot-springs.png",
    ["hundred-points.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/hundred-points.png",
    ["id-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/id-button.png",
    ["infinity.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/infinity.png",
    ["information.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/information.png",
    ["input-latin-letters.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/input-latin-letters.png",
    ["input-latin-lowercase.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/input-latin-lowercase.png",
    ["input-latin-uppercase.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/input-latin-uppercase.png",
    ["input-numbers.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/input-numbers.png",
    ["input-symbols.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/input-symbols.png",
    ["japanese-acceptable-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-acceptable-button.png",
    ["japanese-application-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-application-button.png",
    ["japanese-bargain-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-bargain-button.png",
    ["japanese-congratulations-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-congratulations-button.png",
    ["japanese-discount-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-discount-button.png",
    ["japanese-free-of-charge-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-free-of-charge-button.png",
    ["japanese-here-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-here-button.png",
    ["japanese-monthly-amount-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-monthly-amount-button.png",
    ["japanese-no-vacancy-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-no-vacancy-button.png",
    ["japanese-not-free-of-charge-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-not-free-of-charge-button.png",
    ["japanese-open-for-business-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-open-for-business-button.png",
    ["japanese-passing-grade-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-passing-grade-button.png",
    ["japanese-prohibited-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-prohibited-button.png",
    ["japanese-reserved-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-reserved-button.png",
    ["japanese-secret-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-secret-button.png",
    ["japanese-service-charge-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-service-charge-button.png",
    ["japanese-symbol-for-beginner.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-symbol-for-beginner.png",
    ["japanese-vacancy-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/japanese-vacancy-button.png",
    ["joker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/joker.png",
    ["keycap-0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/keycap-0.png",
    ["keycap-1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/keycap-1.png",
    ["keycap-10.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/keycap-10.png",
    ["keycap-2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/keycap-2.png",
    ["keycap-3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/keycap-3.png",
    ["keycap-4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/keycap-4.png",
    ["keycap-5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/keycap-5.png",
    ["keycap-6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/keycap-6.png",
    ["keycap-7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/keycap-7.png",
    ["keycap-8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/keycap-8.png",
    ["keycap-9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/keycap-9.png",
    ["keycap.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/keycap.png",
    ["large-blue-diamond.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/large-blue-diamond.png",
    ["large-orange-diamond.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/large-orange-diamond.png",
    ["last-track-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/last-track-button.png",
    ["latin-cross.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/latin-cross.png",
    ["left-arrow-curving-right.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/left-arrow-curving-right.png",
    ["left-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/left-arrow.png",
    ["left-luggage.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/left-luggage.png",
    ["left-right-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/left-right-arrow.png",
    ["left-speech-bubble.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/left-speech-bubble.png",
    ["leo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/leo.png",
    ["libra.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/libra.png",
    ["litter-in-bin-sign.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/litter-in-bin-sign.png",
    ["loudspeaker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/loudspeaker.png",
    ["mahjong-red-dragon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/mahjong-red-dragon.png",
    ["male-sign.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/male-sign.png",
    ["medical-symbol.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/medical-symbol.png",
    ["megaphone.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/megaphone.png",
    ["mending-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/mending-heart.png",
    ["menorah.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/menorah.png",
    ["mens-room.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/mens-room.png",
    ["minus-sign.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/minus-sign.png",
    ["mobile-phone-off.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/mobile-phone-off.png",
    ["multiplication-sign.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/multiplication-sign.png",
    ["musical-note.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/musical-note.png",
    ["musical-notes.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/musical-notes.png",
    ["muted-speaker.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/muted-speaker.png",
    ["name-badge.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/name-badge.png",
    ["new-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/new-button.png",
    ["next-track-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/next-track-button.png",
    ["ng-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/ng-button.png",
    ["nine-oclock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/nine-oclock.png",
    ["nine-thirty.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/nine-thirty.png",
    ["no-bicycles.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/no-bicycles.png",
    ["no-entry.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/no-entry.png",
    ["no-littering.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/no-littering.png",
    ["no-mobile-phones.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/no-mobile-phones.png",
    ["no-one-under-eighteen.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/no-one-under-eighteen.png",
    ["no-pedestrians.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/no-pedestrians.png",
    ["no-smoking.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/no-smoking.png",
    ["non-potable-water.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/non-potable-water.png",
    ["o-button-blood-type.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/o-button-blood-type.png",
    ["ok-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/ok-button.png",
    ["om.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/om.png",
    ["on-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/on-arrow.png",
    ["one-oclock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/one-oclock.png",
    ["one-thirty.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/one-thirty.png",
    ["ophiuchus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/ophiuchus.png",
    ["orange-circle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/orange-circle.png",
    ["orange-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/orange-heart.png",
    ["orange-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/orange-square.png",
    ["orthodox-cross.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/orthodox-cross.png",
    ["p-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/p-button.png",
    ["part-alternation-mark.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/part-alternation-mark.png",
    ["passport-control.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/passport-control.png",
    ["pause-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/pause-button.png",
    ["peace-symbol.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/peace-symbol.png",
    ["pisces.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/pisces.png",
    ["place-of-worship.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/place-of-worship.png",
    ["play-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/play-button.png",
    ["play-or-pause-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/play-or-pause-button.png",
    ["plus-sign.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/plus-sign.png",
    ["pound-symbol.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/pound-symbol.png",
    ["prohibited.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/prohibited.png",
    ["purple-circle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/purple-circle.png",
    ["purple-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/purple-heart.png",
    ["purple-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/purple-square.png",
    ["question-mark.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/question-mark.png",
    ["radio-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/radio-button.png",
    ["radioactive.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/radioactive.png",
    ["record-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/record-button.png",
    ["recycling-symbol.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/recycling-symbol.png",
    ["red-circle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/red-circle.png",
    ["red-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/red-heart.png",
    ["red-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/red-square.png",
    ["red-triangle-pointed-down.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/red-triangle-pointed-down.png",
    ["red-triangle-pointed-up.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/red-triangle-pointed-up.png",
    ["registered.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/registered.png",
    ["repeat-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/repeat-button.png",
    ["repeat-single-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/repeat-single-button.png",
    ["restroom.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/restroom.png",
    ["reverse-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/reverse-button.png",
    ["revolving-hearts.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/revolving-hearts.png",
    ["right-anger-bubble.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/right-anger-bubble.png",
    ["right-arrow-curving-down.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/right-arrow-curving-down.png",
    ["right-arrow-curving-left.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/right-arrow-curving-left.png",
    ["right-arrow-curving-up.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/right-arrow-curving-up.png",
    ["right-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/right-arrow.png",
    ["sagittarius.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/sagittarius.png",
    ["scorpio.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/scorpio.png",
    ["seven-oclock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/seven-oclock.png",
    ["seven-thirty.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/seven-thirty.png",
    ["shuffle-tracks-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/shuffle-tracks-button.png",
    ["six-oclock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/six-oclock.png",
    ["six-thirty.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/six-thirty.png",
    ["small-blue-diamond.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/small-blue-diamond.png",
    ["small-orange-diamond.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/small-orange-diamond.png",
    ["soon-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/soon-arrow.png",
    ["sos-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/sos-button.png",
    ["spade-suit.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/spade-suit.png",
    ["sparkle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/sparkle.png",
    ["sparkling-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/sparkling-heart.png",
    ["speaker-high-volume.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/speaker-high-volume.png",
    ["speaker-low-volume.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/speaker-low-volume.png",
    ["speaker-medium-volume.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/speaker-medium-volume.png",
    ["speech-balloon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/speech-balloon.png",
    ["star-and-crescent.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/star-and-crescent.png",
    ["star-of-david.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/star-of-david.png",
    ["stop-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/stop-button.png",
    ["stop-sign.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/stop-sign.png",
    ["taurus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/taurus.png",
    ["ten-oclock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/ten-oclock.png",
    ["ten-thirty.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/ten-thirty.png",
    ["thought-balloon.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/thought-balloon.png",
    ["three-oclock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/three-oclock.png",
    ["three-thirty.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/three-thirty.png",
    ["top-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/top-arrow.png",
    ["trade-mark.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/trade-mark.png",
    ["transgender-symbol.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/transgender-symbol.png",
    ["trident-emblem.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/trident-emblem.png",
    ["twelve-oclock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/twelve-oclock.png",
    ["twelve-thirty.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/twelve-thirty.png",
    ["two-hearts.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/two-hearts.png",
    ["two-oclock.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/two-oclock.png",
    ["two-thirty.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/two-thirty.png",
    ["up-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/up-arrow.png",
    ["up-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/up-button.png",
    ["up-down-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/up-down-arrow.png",
    ["up-left-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/up-left-arrow.png",
    ["up-right-arrow.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/up-right-arrow.png",
    ["upwards-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/upwards-button.png",
    ["vibration-mode.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/vibration-mode.png",
    ["virgo.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/virgo.png",
    ["vs-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/vs-button.png",
    ["warning.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/warning.png",
    ["water-closet.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/water-closet.png",
    ["wavy-dash.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/wavy-dash.png",
    ["wheel-of-dharma.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/wheel-of-dharma.png",
    ["wheelchair-symbol.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/wheelchair-symbol.png",
    ["white-circle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/white-circle.png",
    ["white-exclamation-mark.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/white-exclamation-mark.png",
    ["white-flower.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/white-flower.png",
    ["white-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/white-heart.png",
    ["white-large-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/white-large-square.png",
    ["white-medium-small-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/white-medium-small-square.png",
    ["white-medium-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/white-medium-square.png",
    ["white-question-mark.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/white-question-mark.png",
    ["white-small-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/white-small-square.png",
    ["white-square-button.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/white-square-button.png",
    ["womens-room.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/womens-room.png",
    ["yellow-circle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/yellow-circle.png",
    ["yellow-heart.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/yellow-heart.png",
    ["yellow-square.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/yellow-square.png",
    ["yin-yang.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/yin-yang.png",
    ["zzz.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/symbols/zzz.png",
  },
  travel: {
    ["aerial-tramway.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/aerial-tramway.png",
    ["airplane-arrival.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/airplane-arrival.png",
    ["airplane-departure.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/airplane-departure.png",
    ["airplane.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/airplane.png",
    ["ambulance.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/ambulance.png",
    ["anchor.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/anchor.png",
    ["articulated-lorry.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/articulated-lorry.png",
    ["auto-rickshaw.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/auto-rickshaw.png",
    ["automobile.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/automobile.png",
    ["bank.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/bank.png",
    ["beach-with-umbrella.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/beach-with-umbrella.png",
    ["bicycle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/bicycle.png",
    ["bridge-at-night.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/bridge-at-night.png",
    ["building-construction.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/building-construction.png",
    ["bullet-train.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/bullet-train.png",
    ["bus-stop.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/bus-stop.png",
    ["bus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/bus.png",
    ["camping.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/camping.png",
    ["canoe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/canoe.png",
    ["carousel-horse.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/carousel-horse.png",
    ["castle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/castle.png",
    ["church.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/church.png",
    ["cityscape-at-dusk.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/cityscape-at-dusk.png",
    ["cityscape.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/cityscape.png",
    ["classical-building.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/classical-building.png",
    ["construction.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/construction.png",
    ["convenience-store.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/convenience-store.png",
    ["delivery-truck.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/delivery-truck.png",
    ["department-store.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/department-store.png",
    ["derelict-house.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/derelict-house.png",
    ["desert-island.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/desert-island.png",
    ["desert.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/desert.png",
    ["factory.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/factory.png",
    ["ferris-wheel.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/ferris-wheel.png",
    ["ferry.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/ferry.png",
    ["fire-engine.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/fire-engine.png",
    ["fireworks.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/fireworks.png",
    ["flying-saucer.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/flying-saucer.png",
    ["foggy.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/foggy.png",
    ["fountain.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/fountain.png",
    ["fuel-pump.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/fuel-pump.png",
    ["helicopter.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/helicopter.png",
    ["high-speed-train.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/high-speed-train.png",
    ["hindu-temple.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/hindu-temple.png",
    ["horizontal-traffic-light.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/horizontal-traffic-light.png",
    ["hospital.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/hospital.png",
    ["hotel.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/hotel.png",
    ["house-with-garden.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/house-with-garden.png",
    ["house.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/house.png",
    ["houses.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/houses.png",
    ["hut.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/hut.png",
    ["japanese-castle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/japanese-castle.png",
    ["japanese-post-office.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/japanese-post-office.png",
    ["kaaba.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/kaaba.png",
    ["kick-scooter.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/kick-scooter.png",
    ["light-rail.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/light-rail.png",
    ["locomotive.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/locomotive.png",
    ["love-hotel.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/love-hotel.png",
    ["manual-wheelchair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/manual-wheelchair.png",
    ["map-of-japan.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/map-of-japan.png",
    ["metro.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/metro.png",
    ["milky-way.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/milky-way.png",
    ["minibus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/minibus.png",
    ["moai.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/moai.png",
    ["monorail.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/monorail.png",
    ["moon-viewing-ceremony.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/moon-viewing-ceremony.png",
    ["mosque.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/mosque.png",
    ["motor-boat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/motor-boat.png",
    ["motor-scooter.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/motor-scooter.png",
    ["motorcycle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/motorcycle.png",
    ["motorized-wheelchair.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/motorized-wheelchair.png",
    ["motorway.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/motorway.png",
    ["mount-fuji.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/mount-fuji.png",
    ["mountain-cableway.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/mountain-cableway.png",
    ["mountain-railway.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/mountain-railway.png",
    ["mountain.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/mountain.png",
    ["national-park.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/national-park.png",
    ["night-with-stars.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/night-with-stars.png",
    ["office-building.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/office-building.png",
    ["oncoming-automobile.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/oncoming-automobile.png",
    ["oncoming-bus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/oncoming-bus.png",
    ["oncoming-police-car.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/oncoming-police-car.png",
    ["oncoming-taxi.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/oncoming-taxi.png",
    ["passenger-ship.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/passenger-ship.png",
    ["pickup-truck.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/pickup-truck.png",
    ["police-car-light.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/police-car-light.png",
    ["police-car.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/police-car.png",
    ["post-office.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/post-office.png",
    ["probing-cane.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/probing-cane.png",
    ["racing-car.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/racing-car.png",
    ["railway-car.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/railway-car.png",
    ["railway-track.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/railway-track.png",
    ["rocket.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/rocket.png",
    ["roller-coaster.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/roller-coaster.png",
    ["sailboat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/sailboat.png",
    ["satellite.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/satellite.png",
    ["school.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/school.png",
    ["seat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/seat.png",
    ["shinto-shrine.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/shinto-shrine.png",
    ["ship.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/ship.png",
    ["shooting-star.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/shooting-star.png",
    ["small-airplane.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/small-airplane.png",
    ["snow-capped-mountain.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/snow-capped-mountain.png",
    ["sparkler.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/sparkler.png",
    ["speedboat.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/speedboat.png",
    ["sport-utility-vehicle.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/sport-utility-vehicle.png",
    ["stadium.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/stadium.png",
    ["station.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/station.png",
    ["statue-of-liberty.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/statue-of-liberty.png",
    ["sunrise-over-mountains.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/sunrise-over-mountains.png",
    ["sunrise.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/sunrise.png",
    ["sunset.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/sunset.png",
    ["suspension-railway.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/suspension-railway.png",
    ["synagogue.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/synagogue.png",
    ["taxi.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/taxi.png",
    ["tent.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/tent.png",
    ["tokyo-tower.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/tokyo-tower.png",
    ["tractor.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/tractor.png",
    ["train.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/train.png",
    ["tram-car.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/tram-car.png",
    ["tram.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/tram.png",
    ["trolleybus.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/trolleybus.png",
    ["umbrella-on-ground.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/umbrella-on-ground.png",
    ["vertical-traffic-light.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/vertical-traffic-light.png",
    ["volcano.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/volcano.png",
    ["wedding.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/wedding.png",
    ["world-map.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/travel/world-map.png",
  },
  unicode: {
    ["0023-20e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0023-20e3.png",
    ["0023.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0023.png",
    ["002a-20e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/002a-20e3.png",
    ["002a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/002a.png",
    ["0030-20e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0030-20e3.png",
    ["0030.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0030.png",
    ["0031-20e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0031-20e3.png",
    ["0031.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0031.png",
    ["0032-20e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0032-20e3.png",
    ["0032.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0032.png",
    ["0033-20e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0033-20e3.png",
    ["0033.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0033.png",
    ["0034-20e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0034-20e3.png",
    ["0034.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0034.png",
    ["0035-20e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0035-20e3.png",
    ["0035.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0035.png",
    ["0036-20e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0036-20e3.png",
    ["0036.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0036.png",
    ["0037-20e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0037-20e3.png",
    ["0037.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0037.png",
    ["0038-20e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0038-20e3.png",
    ["0038.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0038.png",
    ["0039-20e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0039-20e3.png",
    ["0039.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/0039.png",
    ["00a9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/00a9.png",
    ["00ae.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/00ae.png",
    ["1f004.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f004.png",
    ["1f0cf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f0cf.png",
    ["1f170.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f170.png",
    ["1f171.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f171.png",
    ["1f17e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f17e.png",
    ["1f17f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f17f.png",
    ["1f18e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f18e.png",
    ["1f191.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f191.png",
    ["1f192.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f192.png",
    ["1f193.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f193.png",
    ["1f194.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f194.png",
    ["1f195.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f195.png",
    ["1f196.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f196.png",
    ["1f197.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f197.png",
    ["1f198.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f198.png",
    ["1f199.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f199.png",
    ["1f19a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f19a.png",
    ["1f1e6-1f1e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1e8.png",
    ["1f1e6-1f1e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1e9.png",
    ["1f1e6-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1ea.png",
    ["1f1e6-1f1eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1eb.png",
    ["1f1e6-1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1ec.png",
    ["1f1e6-1f1ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1ee.png",
    ["1f1e6-1f1f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1f1.png",
    ["1f1e6-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1f2.png",
    ["1f1e6-1f1f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1f4.png",
    ["1f1e6-1f1f6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1f6.png",
    ["1f1e6-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1f7.png",
    ["1f1e6-1f1f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1f8.png",
    ["1f1e6-1f1f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1f9.png",
    ["1f1e6-1f1fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1fa.png",
    ["1f1e6-1f1fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1fc.png",
    ["1f1e6-1f1fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1fd.png",
    ["1f1e6-1f1ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6-1f1ff.png",
    ["1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e6.png",
    ["1f1e7-1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1e6.png",
    ["1f1e7-1f1e7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1e7.png",
    ["1f1e7-1f1e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1e9.png",
    ["1f1e7-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1ea.png",
    ["1f1e7-1f1eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1eb.png",
    ["1f1e7-1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1ec.png",
    ["1f1e7-1f1ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1ed.png",
    ["1f1e7-1f1ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1ee.png",
    ["1f1e7-1f1ef.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1ef.png",
    ["1f1e7-1f1f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1f1.png",
    ["1f1e7-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1f2.png",
    ["1f1e7-1f1f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1f3.png",
    ["1f1e7-1f1f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1f4.png",
    ["1f1e7-1f1f6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1f6.png",
    ["1f1e7-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1f7.png",
    ["1f1e7-1f1f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1f8.png",
    ["1f1e7-1f1f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1f9.png",
    ["1f1e7-1f1fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1fb.png",
    ["1f1e7-1f1fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1fc.png",
    ["1f1e7-1f1fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1fe.png",
    ["1f1e7-1f1ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7-1f1ff.png",
    ["1f1e7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e7.png",
    ["1f1e8-1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1e6.png",
    ["1f1e8-1f1e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1e8.png",
    ["1f1e8-1f1e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1e9.png",
    ["1f1e8-1f1eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1eb.png",
    ["1f1e8-1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1ec.png",
    ["1f1e8-1f1ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1ed.png",
    ["1f1e8-1f1ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1ee.png",
    ["1f1e8-1f1f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1f0.png",
    ["1f1e8-1f1f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1f1.png",
    ["1f1e8-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1f2.png",
    ["1f1e8-1f1f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1f3.png",
    ["1f1e8-1f1f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1f4.png",
    ["1f1e8-1f1f5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1f5.png",
    ["1f1e8-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1f7.png",
    ["1f1e8-1f1fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1fa.png",
    ["1f1e8-1f1fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1fb.png",
    ["1f1e8-1f1fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1fc.png",
    ["1f1e8-1f1fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1fd.png",
    ["1f1e8-1f1fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1fe.png",
    ["1f1e8-1f1ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8-1f1ff.png",
    ["1f1e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e8.png",
    ["1f1e9-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e9-1f1ea.png",
    ["1f1e9-1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e9-1f1ec.png",
    ["1f1e9-1f1ef.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e9-1f1ef.png",
    ["1f1e9-1f1f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e9-1f1f0.png",
    ["1f1e9-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e9-1f1f2.png",
    ["1f1e9-1f1f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e9-1f1f4.png",
    ["1f1e9-1f1ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e9-1f1ff.png",
    ["1f1e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1e9.png",
    ["1f1ea-1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ea-1f1e6.png",
    ["1f1ea-1f1e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ea-1f1e8.png",
    ["1f1ea-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ea-1f1ea.png",
    ["1f1ea-1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ea-1f1ec.png",
    ["1f1ea-1f1ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ea-1f1ed.png",
    ["1f1ea-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ea-1f1f7.png",
    ["1f1ea-1f1f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ea-1f1f8.png",
    ["1f1ea-1f1f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ea-1f1f9.png",
    ["1f1ea-1f1fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ea-1f1fa.png",
    ["1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ea.png",
    ["1f1eb-1f1ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1eb-1f1ee.png",
    ["1f1eb-1f1ef.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1eb-1f1ef.png",
    ["1f1eb-1f1f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1eb-1f1f0.png",
    ["1f1eb-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1eb-1f1f2.png",
    ["1f1eb-1f1f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1eb-1f1f4.png",
    ["1f1eb-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1eb-1f1f7.png",
    ["1f1eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1eb.png",
    ["1f1ec-1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1e6.png",
    ["1f1ec-1f1e7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1e7.png",
    ["1f1ec-1f1e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1e9.png",
    ["1f1ec-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1ea.png",
    ["1f1ec-1f1eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1eb.png",
    ["1f1ec-1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1ec.png",
    ["1f1ec-1f1ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1ed.png",
    ["1f1ec-1f1ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1ee.png",
    ["1f1ec-1f1f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1f1.png",
    ["1f1ec-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1f2.png",
    ["1f1ec-1f1f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1f3.png",
    ["1f1ec-1f1f5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1f5.png",
    ["1f1ec-1f1f6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1f6.png",
    ["1f1ec-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1f7.png",
    ["1f1ec-1f1f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1f8.png",
    ["1f1ec-1f1f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1f9.png",
    ["1f1ec-1f1fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1fa.png",
    ["1f1ec-1f1fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1fc.png",
    ["1f1ec-1f1fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec-1f1fe.png",
    ["1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ec.png",
    ["1f1ed-1f1f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ed-1f1f0.png",
    ["1f1ed-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ed-1f1f2.png",
    ["1f1ed-1f1f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ed-1f1f3.png",
    ["1f1ed-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ed-1f1f7.png",
    ["1f1ed-1f1f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ed-1f1f9.png",
    ["1f1ed-1f1fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ed-1f1fa.png",
    ["1f1ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ed.png",
    ["1f1ee-1f1e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ee-1f1e8.png",
    ["1f1ee-1f1e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ee-1f1e9.png",
    ["1f1ee-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ee-1f1ea.png",
    ["1f1ee-1f1f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ee-1f1f1.png",
    ["1f1ee-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ee-1f1f2.png",
    ["1f1ee-1f1f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ee-1f1f3.png",
    ["1f1ee-1f1f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ee-1f1f4.png",
    ["1f1ee-1f1f6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ee-1f1f6.png",
    ["1f1ee-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ee-1f1f7.png",
    ["1f1ee-1f1f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ee-1f1f8.png",
    ["1f1ee-1f1f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ee-1f1f9.png",
    ["1f1ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ee.png",
    ["1f1ef-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ef-1f1ea.png",
    ["1f1ef-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ef-1f1f2.png",
    ["1f1ef-1f1f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ef-1f1f4.png",
    ["1f1ef-1f1f5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ef-1f1f5.png",
    ["1f1ef.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ef.png",
    ["1f1f0-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f0-1f1ea.png",
    ["1f1f0-1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f0-1f1ec.png",
    ["1f1f0-1f1ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f0-1f1ed.png",
    ["1f1f0-1f1ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f0-1f1ee.png",
    ["1f1f0-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f0-1f1f2.png",
    ["1f1f0-1f1f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f0-1f1f3.png",
    ["1f1f0-1f1f5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f0-1f1f5.png",
    ["1f1f0-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f0-1f1f7.png",
    ["1f1f0-1f1fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f0-1f1fc.png",
    ["1f1f0-1f1fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f0-1f1fe.png",
    ["1f1f0-1f1ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f0-1f1ff.png",
    ["1f1f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f0.png",
    ["1f1f1-1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f1-1f1e6.png",
    ["1f1f1-1f1e7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f1-1f1e7.png",
    ["1f1f1-1f1e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f1-1f1e8.png",
    ["1f1f1-1f1ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f1-1f1ee.png",
    ["1f1f1-1f1f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f1-1f1f0.png",
    ["1f1f1-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f1-1f1f7.png",
    ["1f1f1-1f1f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f1-1f1f8.png",
    ["1f1f1-1f1f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f1-1f1f9.png",
    ["1f1f1-1f1fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f1-1f1fa.png",
    ["1f1f1-1f1fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f1-1f1fb.png",
    ["1f1f1-1f1fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f1-1f1fe.png",
    ["1f1f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f1.png",
    ["1f1f2-1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1e6.png",
    ["1f1f2-1f1e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1e8.png",
    ["1f1f2-1f1e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1e9.png",
    ["1f1f2-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1ea.png",
    ["1f1f2-1f1eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1eb.png",
    ["1f1f2-1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1ec.png",
    ["1f1f2-1f1ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1ed.png",
    ["1f1f2-1f1f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1f0.png",
    ["1f1f2-1f1f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1f1.png",
    ["1f1f2-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1f2.png",
    ["1f1f2-1f1f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1f3.png",
    ["1f1f2-1f1f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1f4.png",
    ["1f1f2-1f1f5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1f5.png",
    ["1f1f2-1f1f6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1f6.png",
    ["1f1f2-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1f7.png",
    ["1f1f2-1f1f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1f8.png",
    ["1f1f2-1f1f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1f9.png",
    ["1f1f2-1f1fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1fa.png",
    ["1f1f2-1f1fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1fb.png",
    ["1f1f2-1f1fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1fc.png",
    ["1f1f2-1f1fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1fd.png",
    ["1f1f2-1f1fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1fe.png",
    ["1f1f2-1f1ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2-1f1ff.png",
    ["1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f2.png",
    ["1f1f3-1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f3-1f1e6.png",
    ["1f1f3-1f1e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f3-1f1e8.png",
    ["1f1f3-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f3-1f1ea.png",
    ["1f1f3-1f1eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f3-1f1eb.png",
    ["1f1f3-1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f3-1f1ec.png",
    ["1f1f3-1f1ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f3-1f1ee.png",
    ["1f1f3-1f1f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f3-1f1f1.png",
    ["1f1f3-1f1f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f3-1f1f4.png",
    ["1f1f3-1f1f5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f3-1f1f5.png",
    ["1f1f3-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f3-1f1f7.png",
    ["1f1f3-1f1fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f3-1f1fa.png",
    ["1f1f3-1f1ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f3-1f1ff.png",
    ["1f1f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f3.png",
    ["1f1f4-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f4-1f1f2.png",
    ["1f1f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f4.png",
    ["1f1f5-1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5-1f1e6.png",
    ["1f1f5-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5-1f1ea.png",
    ["1f1f5-1f1eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5-1f1eb.png",
    ["1f1f5-1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5-1f1ec.png",
    ["1f1f5-1f1ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5-1f1ed.png",
    ["1f1f5-1f1f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5-1f1f0.png",
    ["1f1f5-1f1f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5-1f1f1.png",
    ["1f1f5-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5-1f1f2.png",
    ["1f1f5-1f1f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5-1f1f3.png",
    ["1f1f5-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5-1f1f7.png",
    ["1f1f5-1f1f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5-1f1f8.png",
    ["1f1f5-1f1f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5-1f1f9.png",
    ["1f1f5-1f1fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5-1f1fc.png",
    ["1f1f5-1f1fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5-1f1fe.png",
    ["1f1f5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f5.png",
    ["1f1f6-1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f6-1f1e6.png",
    ["1f1f6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f6.png",
    ["1f1f7-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f7-1f1ea.png",
    ["1f1f7-1f1f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f7-1f1f4.png",
    ["1f1f7-1f1f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f7-1f1f8.png",
    ["1f1f7-1f1fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f7-1f1fa.png",
    ["1f1f7-1f1fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f7-1f1fc.png",
    ["1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f7.png",
    ["1f1f8-1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1e6.png",
    ["1f1f8-1f1e7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1e7.png",
    ["1f1f8-1f1e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1e8.png",
    ["1f1f8-1f1e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1e9.png",
    ["1f1f8-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1ea.png",
    ["1f1f8-1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1ec.png",
    ["1f1f8-1f1ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1ed.png",
    ["1f1f8-1f1ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1ee.png",
    ["1f1f8-1f1ef.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1ef.png",
    ["1f1f8-1f1f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1f0.png",
    ["1f1f8-1f1f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1f1.png",
    ["1f1f8-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1f2.png",
    ["1f1f8-1f1f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1f3.png",
    ["1f1f8-1f1f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1f4.png",
    ["1f1f8-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1f7.png",
    ["1f1f8-1f1f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1f8.png",
    ["1f1f8-1f1f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1f9.png",
    ["1f1f8-1f1fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1fb.png",
    ["1f1f8-1f1fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1fd.png",
    ["1f1f8-1f1fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1fe.png",
    ["1f1f8-1f1ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8-1f1ff.png",
    ["1f1f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f8.png",
    ["1f1f9-1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1e6.png",
    ["1f1f9-1f1e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1e8.png",
    ["1f1f9-1f1e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1e9.png",
    ["1f1f9-1f1eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1eb.png",
    ["1f1f9-1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1ec.png",
    ["1f1f9-1f1ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1ed.png",
    ["1f1f9-1f1ef.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1ef.png",
    ["1f1f9-1f1f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1f0.png",
    ["1f1f9-1f1f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1f1.png",
    ["1f1f9-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1f2.png",
    ["1f1f9-1f1f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1f3.png",
    ["1f1f9-1f1f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1f4.png",
    ["1f1f9-1f1f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1f7.png",
    ["1f1f9-1f1f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1f9.png",
    ["1f1f9-1f1fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1fb.png",
    ["1f1f9-1f1fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1fc.png",
    ["1f1f9-1f1ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9-1f1ff.png",
    ["1f1f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1f9.png",
    ["1f1fa-1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fa-1f1e6.png",
    ["1f1fa-1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fa-1f1ec.png",
    ["1f1fa-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fa-1f1f2.png",
    ["1f1fa-1f1f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fa-1f1f3.png",
    ["1f1fa-1f1f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fa-1f1f8.png",
    ["1f1fa-1f1fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fa-1f1fe.png",
    ["1f1fa-1f1ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fa-1f1ff.png",
    ["1f1fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fa.png",
    ["1f1fb-1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fb-1f1e6.png",
    ["1f1fb-1f1e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fb-1f1e8.png",
    ["1f1fb-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fb-1f1ea.png",
    ["1f1fb-1f1ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fb-1f1ec.png",
    ["1f1fb-1f1ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fb-1f1ee.png",
    ["1f1fb-1f1f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fb-1f1f3.png",
    ["1f1fb-1f1fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fb-1f1fa.png",
    ["1f1fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fb.png",
    ["1f1fc-1f1eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fc-1f1eb.png",
    ["1f1fc-1f1f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fc-1f1f8.png",
    ["1f1fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fc.png",
    ["1f1fd-1f1f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fd-1f1f0.png",
    ["1f1fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fd.png",
    ["1f1fe-1f1ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fe-1f1ea.png",
    ["1f1fe-1f1f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fe-1f1f9.png",
    ["1f1fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1fe.png",
    ["1f1ff-1f1e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ff-1f1e6.png",
    ["1f1ff-1f1f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ff-1f1f2.png",
    ["1f1ff-1f1fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ff-1f1fc.png",
    ["1f1ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f1ff.png",
    ["1f201.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f201.png",
    ["1f202.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f202.png",
    ["1f21a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f21a.png",
    ["1f22f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f22f.png",
    ["1f232.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f232.png",
    ["1f233.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f233.png",
    ["1f234.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f234.png",
    ["1f235.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f235.png",
    ["1f236.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f236.png",
    ["1f237.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f237.png",
    ["1f238.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f238.png",
    ["1f239.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f239.png",
    ["1f23a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f23a.png",
    ["1f250.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f250.png",
    ["1f251.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f251.png",
    ["1f300.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f300.png",
    ["1f301.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f301.png",
    ["1f302.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f302.png",
    ["1f303.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f303.png",
    ["1f304.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f304.png",
    ["1f305.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f305.png",
    ["1f306.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f306.png",
    ["1f307.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f307.png",
    ["1f308.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f308.png",
    ["1f309.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f309.png",
    ["1f30a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f30a.png",
    ["1f30b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f30b.png",
    ["1f30c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f30c.png",
    ["1f30d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f30d.png",
    ["1f30e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f30e.png",
    ["1f30f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f30f.png",
    ["1f310.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f310.png",
    ["1f311.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f311.png",
    ["1f312.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f312.png",
    ["1f313.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f313.png",
    ["1f314.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f314.png",
    ["1f315.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f315.png",
    ["1f316.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f316.png",
    ["1f317.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f317.png",
    ["1f318.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f318.png",
    ["1f319.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f319.png",
    ["1f31a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f31a.png",
    ["1f31b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f31b.png",
    ["1f31c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f31c.png",
    ["1f31d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f31d.png",
    ["1f31e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f31e.png",
    ["1f31f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f31f.png",
    ["1f320.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f320.png",
    ["1f321.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f321.png",
    ["1f324.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f324.png",
    ["1f325.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f325.png",
    ["1f326.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f326.png",
    ["1f327.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f327.png",
    ["1f328.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f328.png",
    ["1f329.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f329.png",
    ["1f32a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f32a.png",
    ["1f32b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f32b.png",
    ["1f32c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f32c.png",
    ["1f32d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f32d.png",
    ["1f32e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f32e.png",
    ["1f32f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f32f.png",
    ["1f330.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f330.png",
    ["1f331.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f331.png",
    ["1f332.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f332.png",
    ["1f333.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f333.png",
    ["1f334.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f334.png",
    ["1f335.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f335.png",
    ["1f336.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f336.png",
    ["1f337.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f337.png",
    ["1f338.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f338.png",
    ["1f339.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f339.png",
    ["1f33a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f33a.png",
    ["1f33b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f33b.png",
    ["1f33c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f33c.png",
    ["1f33d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f33d.png",
    ["1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f33e.png",
    ["1f33f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f33f.png",
    ["1f340.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f340.png",
    ["1f341.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f341.png",
    ["1f342.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f342.png",
    ["1f343.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f343.png",
    ["1f344.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f344.png",
    ["1f345.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f345.png",
    ["1f346.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f346.png",
    ["1f347.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f347.png",
    ["1f348.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f348.png",
    ["1f349.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f349.png",
    ["1f34a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f34a.png",
    ["1f34b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f34b.png",
    ["1f34c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f34c.png",
    ["1f34d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f34d.png",
    ["1f34e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f34e.png",
    ["1f34f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f34f.png",
    ["1f350.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f350.png",
    ["1f351.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f351.png",
    ["1f352.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f352.png",
    ["1f353.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f353.png",
    ["1f354.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f354.png",
    ["1f355.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f355.png",
    ["1f356.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f356.png",
    ["1f357.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f357.png",
    ["1f358.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f358.png",
    ["1f359.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f359.png",
    ["1f35a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f35a.png",
    ["1f35b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f35b.png",
    ["1f35c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f35c.png",
    ["1f35d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f35d.png",
    ["1f35e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f35e.png",
    ["1f35f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f35f.png",
    ["1f360.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f360.png",
    ["1f361.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f361.png",
    ["1f362.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f362.png",
    ["1f363.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f363.png",
    ["1f364.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f364.png",
    ["1f365.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f365.png",
    ["1f366.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f366.png",
    ["1f367.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f367.png",
    ["1f368.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f368.png",
    ["1f369.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f369.png",
    ["1f36a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f36a.png",
    ["1f36b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f36b.png",
    ["1f36c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f36c.png",
    ["1f36d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f36d.png",
    ["1f36e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f36e.png",
    ["1f36f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f36f.png",
    ["1f370.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f370.png",
    ["1f371.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f371.png",
    ["1f372.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f372.png",
    ["1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f373.png",
    ["1f374.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f374.png",
    ["1f375.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f375.png",
    ["1f376.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f376.png",
    ["1f377.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f377.png",
    ["1f378.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f378.png",
    ["1f379.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f379.png",
    ["1f37a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f37a.png",
    ["1f37b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f37b.png",
    ["1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f37c.png",
    ["1f37d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f37d.png",
    ["1f37e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f37e.png",
    ["1f37f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f37f.png",
    ["1f380.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f380.png",
    ["1f381.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f381.png",
    ["1f382.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f382.png",
    ["1f383.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f383.png",
    ["1f384.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f384.png",
    ["1f385-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f385-1f3fb.png",
    ["1f385-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f385-1f3fc.png",
    ["1f385-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f385-1f3fd.png",
    ["1f385-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f385-1f3fe.png",
    ["1f385-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f385-1f3ff.png",
    ["1f385.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f385.png",
    ["1f386.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f386.png",
    ["1f387.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f387.png",
    ["1f388.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f388.png",
    ["1f389.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f389.png",
    ["1f38a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f38a.png",
    ["1f38b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f38b.png",
    ["1f38c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f38c.png",
    ["1f38d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f38d.png",
    ["1f38e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f38e.png",
    ["1f38f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f38f.png",
    ["1f390.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f390.png",
    ["1f391.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f391.png",
    ["1f392.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f392.png",
    ["1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f393.png",
    ["1f396.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f396.png",
    ["1f397.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f397.png",
    ["1f399.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f399.png",
    ["1f39a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f39a.png",
    ["1f39b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f39b.png",
    ["1f39e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f39e.png",
    ["1f39f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f39f.png",
    ["1f3a0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3a0.png",
    ["1f3a1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3a1.png",
    ["1f3a2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3a2.png",
    ["1f3a3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3a3.png",
    ["1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3a4.png",
    ["1f3a5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3a5.png",
    ["1f3a6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3a6.png",
    ["1f3a7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3a7.png",
    ["1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3a8.png",
    ["1f3a9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3a9.png",
    ["1f3aa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3aa.png",
    ["1f3ab.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ab.png",
    ["1f3ac.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ac.png",
    ["1f3ad.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ad.png",
    ["1f3ae.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ae.png",
    ["1f3af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3af.png",
    ["1f3b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3b0.png",
    ["1f3b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3b1.png",
    ["1f3b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3b2.png",
    ["1f3b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3b3.png",
    ["1f3b4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3b4.png",
    ["1f3b5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3b5.png",
    ["1f3b6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3b6.png",
    ["1f3b7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3b7.png",
    ["1f3b8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3b8.png",
    ["1f3b9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3b9.png",
    ["1f3ba.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ba.png",
    ["1f3bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3bb.png",
    ["1f3bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3bc.png",
    ["1f3bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3bd.png",
    ["1f3be.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3be.png",
    ["1f3bf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3bf.png",
    ["1f3c0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c0.png",
    ["1f3c1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c1.png",
    ["1f3c2-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c2-1f3fb.png",
    ["1f3c2-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c2-1f3fc.png",
    ["1f3c2-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c2-1f3fd.png",
    ["1f3c2-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c2-1f3fe.png",
    ["1f3c2-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c2-1f3ff.png",
    ["1f3c2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c2.png",
    ["1f3c3-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3fb-2640.png",
    ["1f3c3-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3fb-2642.png",
    ["1f3c3-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3fb.png",
    ["1f3c3-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3fc-2640.png",
    ["1f3c3-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3fc-2642.png",
    ["1f3c3-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3fc.png",
    ["1f3c3-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3fd-2640.png",
    ["1f3c3-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3fd-2642.png",
    ["1f3c3-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3fd.png",
    ["1f3c3-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3fe-2640.png",
    ["1f3c3-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3fe-2642.png",
    ["1f3c3-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3fe.png",
    ["1f3c3-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3ff-2640.png",
    ["1f3c3-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3ff-2642.png",
    ["1f3c3-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-1f3ff.png",
    ["1f3c3-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-2640.png",
    ["1f3c3-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3-2642.png",
    ["1f3c3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c3.png",
    ["1f3c4-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3fb-2640.png",
    ["1f3c4-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3fb-2642.png",
    ["1f3c4-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3fb.png",
    ["1f3c4-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3fc-2640.png",
    ["1f3c4-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3fc-2642.png",
    ["1f3c4-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3fc.png",
    ["1f3c4-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3fd-2640.png",
    ["1f3c4-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3fd-2642.png",
    ["1f3c4-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3fd.png",
    ["1f3c4-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3fe-2640.png",
    ["1f3c4-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3fe-2642.png",
    ["1f3c4-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3fe.png",
    ["1f3c4-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3ff-2640.png",
    ["1f3c4-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3ff-2642.png",
    ["1f3c4-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-1f3ff.png",
    ["1f3c4-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-2640.png",
    ["1f3c4-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4-2642.png",
    ["1f3c4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c4.png",
    ["1f3c5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c5.png",
    ["1f3c6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c6.png",
    ["1f3c7-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c7-1f3fb.png",
    ["1f3c7-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c7-1f3fc.png",
    ["1f3c7-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c7-1f3fd.png",
    ["1f3c7-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c7-1f3fe.png",
    ["1f3c7-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c7-1f3ff.png",
    ["1f3c7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c7.png",
    ["1f3c8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c8.png",
    ["1f3c9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3c9.png",
    ["1f3ca-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3fb-2640.png",
    ["1f3ca-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3fb-2642.png",
    ["1f3ca-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3fb.png",
    ["1f3ca-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3fc-2640.png",
    ["1f3ca-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3fc-2642.png",
    ["1f3ca-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3fc.png",
    ["1f3ca-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3fd-2640.png",
    ["1f3ca-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3fd-2642.png",
    ["1f3ca-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3fd.png",
    ["1f3ca-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3fe-2640.png",
    ["1f3ca-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3fe-2642.png",
    ["1f3ca-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3fe.png",
    ["1f3ca-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3ff-2640.png",
    ["1f3ca-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3ff-2642.png",
    ["1f3ca-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-1f3ff.png",
    ["1f3ca-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-2640.png",
    ["1f3ca-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca-2642.png",
    ["1f3ca.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ca.png",
    ["1f3cb-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3fb-2640.png",
    ["1f3cb-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3fb-2642.png",
    ["1f3cb-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3fb.png",
    ["1f3cb-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3fc-2640.png",
    ["1f3cb-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3fc-2642.png",
    ["1f3cb-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3fc.png",
    ["1f3cb-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3fd-2640.png",
    ["1f3cb-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3fd-2642.png",
    ["1f3cb-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3fd.png",
    ["1f3cb-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3fe-2640.png",
    ["1f3cb-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3fe-2642.png",
    ["1f3cb-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3fe.png",
    ["1f3cb-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3ff-2640.png",
    ["1f3cb-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3ff-2642.png",
    ["1f3cb-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-1f3ff.png",
    ["1f3cb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-2640.png",
    ["1f3cb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb-2642.png",
    ["1f3cb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cb.png",
    ["1f3cc-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3fb-2640.png",
    ["1f3cc-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3fb-2642.png",
    ["1f3cc-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3fb.png",
    ["1f3cc-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3fc-2640.png",
    ["1f3cc-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3fc-2642.png",
    ["1f3cc-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3fc.png",
    ["1f3cc-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3fd-2640.png",
    ["1f3cc-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3fd-2642.png",
    ["1f3cc-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3fd.png",
    ["1f3cc-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3fe-2640.png",
    ["1f3cc-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3fe-2642.png",
    ["1f3cc-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3fe.png",
    ["1f3cc-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3ff-2640.png",
    ["1f3cc-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3ff-2642.png",
    ["1f3cc-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-1f3ff.png",
    ["1f3cc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-2640.png",
    ["1f3cc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc-2642.png",
    ["1f3cc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cc.png",
    ["1f3cd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cd.png",
    ["1f3ce.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ce.png",
    ["1f3cf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3cf.png",
    ["1f3d0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3d0.png",
    ["1f3d1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3d1.png",
    ["1f3d2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3d2.png",
    ["1f3d3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3d3.png",
    ["1f3d4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3d4.png",
    ["1f3d5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3d5.png",
    ["1f3d6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3d6.png",
    ["1f3d7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3d7.png",
    ["1f3d8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3d8.png",
    ["1f3d9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3d9.png",
    ["1f3da.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3da.png",
    ["1f3db.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3db.png",
    ["1f3dc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3dc.png",
    ["1f3dd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3dd.png",
    ["1f3de.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3de.png",
    ["1f3df.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3df.png",
    ["1f3e0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3e0.png",
    ["1f3e1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3e1.png",
    ["1f3e2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3e2.png",
    ["1f3e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3e3.png",
    ["1f3e4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3e4.png",
    ["1f3e5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3e5.png",
    ["1f3e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3e6.png",
    ["1f3e7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3e7.png",
    ["1f3e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3e8.png",
    ["1f3e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3e9.png",
    ["1f3ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ea.png",
    ["1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3eb.png",
    ["1f3ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ec.png",
    ["1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ed.png",
    ["1f3ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ee.png",
    ["1f3ef.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ef.png",
    ["1f3f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3f0.png",
    ["1f3f3-1f308.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3f3-1f308.png",
    ["1f3f3-26a7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3f3-26a7.png",
    ["1f3f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3f3.png",
    ["1f3f4-2620.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3f4-2620.png",
    ["1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.png",
    ["1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.png",
    ["1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.png",
    ["1f3f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3f4.png",
    ["1f3f5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3f5.png",
    ["1f3f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3f7.png",
    ["1f3f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3f8.png",
    ["1f3f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3f9.png",
    ["1f3fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3fa.png",
    ["1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3fb.png",
    ["1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3fc.png",
    ["1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3fd.png",
    ["1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3fe.png",
    ["1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f3ff.png",
    ["1f400.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f400.png",
    ["1f401.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f401.png",
    ["1f402.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f402.png",
    ["1f403.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f403.png",
    ["1f404.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f404.png",
    ["1f405.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f405.png",
    ["1f406.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f406.png",
    ["1f407.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f407.png",
    ["1f408-2b1b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f408-2b1b.png",
    ["1f408.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f408.png",
    ["1f409.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f409.png",
    ["1f40a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f40a.png",
    ["1f40b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f40b.png",
    ["1f40c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f40c.png",
    ["1f40d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f40d.png",
    ["1f40e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f40e.png",
    ["1f40f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f40f.png",
    ["1f410.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f410.png",
    ["1f411.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f411.png",
    ["1f412.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f412.png",
    ["1f413.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f413.png",
    ["1f414.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f414.png",
    ["1f415-1f9ba.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f415-1f9ba.png",
    ["1f415.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f415.png",
    ["1f416.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f416.png",
    ["1f417.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f417.png",
    ["1f418.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f418.png",
    ["1f419.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f419.png",
    ["1f41a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f41a.png",
    ["1f41b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f41b.png",
    ["1f41c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f41c.png",
    ["1f41d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f41d.png",
    ["1f41e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f41e.png",
    ["1f41f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f41f.png",
    ["1f420.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f420.png",
    ["1f421.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f421.png",
    ["1f422.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f422.png",
    ["1f423.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f423.png",
    ["1f424.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f424.png",
    ["1f425.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f425.png",
    ["1f426.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f426.png",
    ["1f427.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f427.png",
    ["1f428.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f428.png",
    ["1f429.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f429.png",
    ["1f42a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f42a.png",
    ["1f42b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f42b.png",
    ["1f42c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f42c.png",
    ["1f42d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f42d.png",
    ["1f42e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f42e.png",
    ["1f42f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f42f.png",
    ["1f430.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f430.png",
    ["1f431.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f431.png",
    ["1f432.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f432.png",
    ["1f433.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f433.png",
    ["1f434.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f434.png",
    ["1f435.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f435.png",
    ["1f436.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f436.png",
    ["1f437.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f437.png",
    ["1f438.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f438.png",
    ["1f439.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f439.png",
    ["1f43a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f43a.png",
    ["1f43b-2744.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f43b-2744.png",
    ["1f43b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f43b.png",
    ["1f43c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f43c.png",
    ["1f43d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f43d.png",
    ["1f43e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f43e.png",
    ["1f43f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f43f.png",
    ["1f440.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f440.png",
    ["1f441-1f5e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f441-1f5e8.png",
    ["1f441.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f441.png",
    ["1f442-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f442-1f3fb.png",
    ["1f442-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f442-1f3fc.png",
    ["1f442-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f442-1f3fd.png",
    ["1f442-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f442-1f3fe.png",
    ["1f442-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f442-1f3ff.png",
    ["1f442.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f442.png",
    ["1f443-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f443-1f3fb.png",
    ["1f443-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f443-1f3fc.png",
    ["1f443-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f443-1f3fd.png",
    ["1f443-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f443-1f3fe.png",
    ["1f443-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f443-1f3ff.png",
    ["1f443.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f443.png",
    ["1f444.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f444.png",
    ["1f445.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f445.png",
    ["1f446-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f446-1f3fb.png",
    ["1f446-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f446-1f3fc.png",
    ["1f446-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f446-1f3fd.png",
    ["1f446-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f446-1f3fe.png",
    ["1f446-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f446-1f3ff.png",
    ["1f446.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f446.png",
    ["1f447-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f447-1f3fb.png",
    ["1f447-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f447-1f3fc.png",
    ["1f447-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f447-1f3fd.png",
    ["1f447-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f447-1f3fe.png",
    ["1f447-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f447-1f3ff.png",
    ["1f447.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f447.png",
    ["1f448-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f448-1f3fb.png",
    ["1f448-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f448-1f3fc.png",
    ["1f448-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f448-1f3fd.png",
    ["1f448-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f448-1f3fe.png",
    ["1f448-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f448-1f3ff.png",
    ["1f448.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f448.png",
    ["1f449-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f449-1f3fb.png",
    ["1f449-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f449-1f3fc.png",
    ["1f449-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f449-1f3fd.png",
    ["1f449-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f449-1f3fe.png",
    ["1f449-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f449-1f3ff.png",
    ["1f449.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f449.png",
    ["1f44a-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44a-1f3fb.png",
    ["1f44a-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44a-1f3fc.png",
    ["1f44a-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44a-1f3fd.png",
    ["1f44a-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44a-1f3fe.png",
    ["1f44a-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44a-1f3ff.png",
    ["1f44a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44a.png",
    ["1f44b-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44b-1f3fb.png",
    ["1f44b-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44b-1f3fc.png",
    ["1f44b-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44b-1f3fd.png",
    ["1f44b-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44b-1f3fe.png",
    ["1f44b-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44b-1f3ff.png",
    ["1f44b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44b.png",
    ["1f44c-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44c-1f3fb.png",
    ["1f44c-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44c-1f3fc.png",
    ["1f44c-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44c-1f3fd.png",
    ["1f44c-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44c-1f3fe.png",
    ["1f44c-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44c-1f3ff.png",
    ["1f44c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44c.png",
    ["1f44d-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44d-1f3fb.png",
    ["1f44d-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44d-1f3fc.png",
    ["1f44d-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44d-1f3fd.png",
    ["1f44d-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44d-1f3fe.png",
    ["1f44d-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44d-1f3ff.png",
    ["1f44d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44d.png",
    ["1f44e-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44e-1f3fb.png",
    ["1f44e-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44e-1f3fc.png",
    ["1f44e-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44e-1f3fd.png",
    ["1f44e-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44e-1f3fe.png",
    ["1f44e-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44e-1f3ff.png",
    ["1f44e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44e.png",
    ["1f44f-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44f-1f3fb.png",
    ["1f44f-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44f-1f3fc.png",
    ["1f44f-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44f-1f3fd.png",
    ["1f44f-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44f-1f3fe.png",
    ["1f44f-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44f-1f3ff.png",
    ["1f44f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f44f.png",
    ["1f450-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f450-1f3fb.png",
    ["1f450-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f450-1f3fc.png",
    ["1f450-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f450-1f3fd.png",
    ["1f450-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f450-1f3fe.png",
    ["1f450-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f450-1f3ff.png",
    ["1f450.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f450.png",
    ["1f451.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f451.png",
    ["1f452.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f452.png",
    ["1f453.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f453.png",
    ["1f454.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f454.png",
    ["1f455.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f455.png",
    ["1f456.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f456.png",
    ["1f457.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f457.png",
    ["1f458.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f458.png",
    ["1f459.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f459.png",
    ["1f45a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f45a.png",
    ["1f45b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f45b.png",
    ["1f45c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f45c.png",
    ["1f45d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f45d.png",
    ["1f45e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f45e.png",
    ["1f45f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f45f.png",
    ["1f460.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f460.png",
    ["1f461.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f461.png",
    ["1f462.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f462.png",
    ["1f463.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f463.png",
    ["1f464.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f464.png",
    ["1f465.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f465.png",
    ["1f466-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f466-1f3fb.png",
    ["1f466-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f466-1f3fc.png",
    ["1f466-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f466-1f3fd.png",
    ["1f466-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f466-1f3fe.png",
    ["1f466-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f466-1f3ff.png",
    ["1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f466.png",
    ["1f467-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f467-1f3fb.png",
    ["1f467-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f467-1f3fc.png",
    ["1f467-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f467-1f3fd.png",
    ["1f467-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f467-1f3fe.png",
    ["1f467-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f467-1f3ff.png",
    ["1f467.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f467.png",
    ["1f468-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f33e.png",
    ["1f468-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f373.png",
    ["1f468-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f37c.png",
    ["1f468-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f393.png",
    ["1f468-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3a4.png",
    ["1f468-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3a8.png",
    ["1f468-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3eb.png",
    ["1f468-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ed.png",
    ["1f468-1f3fb-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f33e.png",
    ["1f468-1f3fb-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f373.png",
    ["1f468-1f3fb-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f37c.png",
    ["1f468-1f3fb-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f393.png",
    ["1f468-1f3fb-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f3a4.png",
    ["1f468-1f3fb-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f3a8.png",
    ["1f468-1f3fb-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f3eb.png",
    ["1f468-1f3fb-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f3ed.png",
    ["1f468-1f3fb-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f4bb.png",
    ["1f468-1f3fb-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f4bc.png",
    ["1f468-1f3fb-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f527.png",
    ["1f468-1f3fb-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f52c.png",
    ["1f468-1f3fb-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f680.png",
    ["1f468-1f3fb-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f692.png",
    ["1f468-1f3fb-1f91d-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f91d-1f468-1f3fc.png",
    ["1f468-1f3fb-1f91d-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f91d-1f468-1f3fd.png",
    ["1f468-1f3fb-1f91d-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f91d-1f468-1f3fe.png",
    ["1f468-1f3fb-1f91d-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f91d-1f468-1f3ff.png",
    ["1f468-1f3fb-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f9af.png",
    ["1f468-1f3fb-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f9b0.png",
    ["1f468-1f3fb-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f9b1.png",
    ["1f468-1f3fb-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f9b2.png",
    ["1f468-1f3fb-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f9b3.png",
    ["1f468-1f3fb-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f9bc.png",
    ["1f468-1f3fb-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-1f9bd.png",
    ["1f468-1f3fb-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-2695.png",
    ["1f468-1f3fb-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-2696.png",
    ["1f468-1f3fb-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-2708.png",
    ["1f468-1f3fb-2764-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-2764-1f468-1f3fb.png",
    ["1f468-1f3fb-2764-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-2764-1f468-1f3fc.png",
    ["1f468-1f3fb-2764-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-2764-1f468-1f3fd.png",
    ["1f468-1f3fb-2764-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-2764-1f468-1f3fe.png",
    ["1f468-1f3fb-2764-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-2764-1f468-1f3ff.png",
    ["1f468-1f3fb-2764-1f48b-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-2764-1f48b-1f468-1f3fb.png",
    ["1f468-1f3fb-2764-1f48b-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-2764-1f48b-1f468-1f3fc.png",
    ["1f468-1f3fb-2764-1f48b-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-2764-1f48b-1f468-1f3fd.png",
    ["1f468-1f3fb-2764-1f48b-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-2764-1f48b-1f468-1f3fe.png",
    ["1f468-1f3fb-2764-1f48b-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb-2764-1f48b-1f468-1f3ff.png",
    ["1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fb.png",
    ["1f468-1f3fc-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f33e.png",
    ["1f468-1f3fc-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f373.png",
    ["1f468-1f3fc-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f37c.png",
    ["1f468-1f3fc-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f393.png",
    ["1f468-1f3fc-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f3a4.png",
    ["1f468-1f3fc-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f3a8.png",
    ["1f468-1f3fc-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f3eb.png",
    ["1f468-1f3fc-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f3ed.png",
    ["1f468-1f3fc-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f4bb.png",
    ["1f468-1f3fc-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f4bc.png",
    ["1f468-1f3fc-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f527.png",
    ["1f468-1f3fc-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f52c.png",
    ["1f468-1f3fc-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f680.png",
    ["1f468-1f3fc-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f692.png",
    ["1f468-1f3fc-1f91d-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f91d-1f468-1f3fb.png",
    ["1f468-1f3fc-1f91d-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f91d-1f468-1f3fd.png",
    ["1f468-1f3fc-1f91d-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f91d-1f468-1f3fe.png",
    ["1f468-1f3fc-1f91d-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f91d-1f468-1f3ff.png",
    ["1f468-1f3fc-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f9af.png",
    ["1f468-1f3fc-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f9b0.png",
    ["1f468-1f3fc-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f9b1.png",
    ["1f468-1f3fc-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f9b2.png",
    ["1f468-1f3fc-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f9b3.png",
    ["1f468-1f3fc-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f9bc.png",
    ["1f468-1f3fc-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-1f9bd.png",
    ["1f468-1f3fc-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-2695.png",
    ["1f468-1f3fc-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-2696.png",
    ["1f468-1f3fc-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-2708.png",
    ["1f468-1f3fc-2764-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-2764-1f468-1f3fb.png",
    ["1f468-1f3fc-2764-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-2764-1f468-1f3fc.png",
    ["1f468-1f3fc-2764-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-2764-1f468-1f3fd.png",
    ["1f468-1f3fc-2764-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-2764-1f468-1f3fe.png",
    ["1f468-1f3fc-2764-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-2764-1f468-1f3ff.png",
    ["1f468-1f3fc-2764-1f48b-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-2764-1f48b-1f468-1f3fb.png",
    ["1f468-1f3fc-2764-1f48b-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-2764-1f48b-1f468-1f3fc.png",
    ["1f468-1f3fc-2764-1f48b-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-2764-1f48b-1f468-1f3fd.png",
    ["1f468-1f3fc-2764-1f48b-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-2764-1f48b-1f468-1f3fe.png",
    ["1f468-1f3fc-2764-1f48b-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc-2764-1f48b-1f468-1f3ff.png",
    ["1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fc.png",
    ["1f468-1f3fd-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f33e.png",
    ["1f468-1f3fd-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f373.png",
    ["1f468-1f3fd-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f37c.png",
    ["1f468-1f3fd-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f393.png",
    ["1f468-1f3fd-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f3a4.png",
    ["1f468-1f3fd-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f3a8.png",
    ["1f468-1f3fd-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f3eb.png",
    ["1f468-1f3fd-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f3ed.png",
    ["1f468-1f3fd-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f4bb.png",
    ["1f468-1f3fd-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f4bc.png",
    ["1f468-1f3fd-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f527.png",
    ["1f468-1f3fd-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f52c.png",
    ["1f468-1f3fd-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f680.png",
    ["1f468-1f3fd-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f692.png",
    ["1f468-1f3fd-1f91d-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f91d-1f468-1f3fb.png",
    ["1f468-1f3fd-1f91d-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f91d-1f468-1f3fc.png",
    ["1f468-1f3fd-1f91d-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f91d-1f468-1f3fe.png",
    ["1f468-1f3fd-1f91d-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f91d-1f468-1f3ff.png",
    ["1f468-1f3fd-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f9af.png",
    ["1f468-1f3fd-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f9b0.png",
    ["1f468-1f3fd-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f9b1.png",
    ["1f468-1f3fd-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f9b2.png",
    ["1f468-1f3fd-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f9b3.png",
    ["1f468-1f3fd-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f9bc.png",
    ["1f468-1f3fd-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-1f9bd.png",
    ["1f468-1f3fd-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-2695.png",
    ["1f468-1f3fd-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-2696.png",
    ["1f468-1f3fd-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-2708.png",
    ["1f468-1f3fd-2764-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-2764-1f468-1f3fb.png",
    ["1f468-1f3fd-2764-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-2764-1f468-1f3fc.png",
    ["1f468-1f3fd-2764-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-2764-1f468-1f3fd.png",
    ["1f468-1f3fd-2764-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-2764-1f468-1f3fe.png",
    ["1f468-1f3fd-2764-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-2764-1f468-1f3ff.png",
    ["1f468-1f3fd-2764-1f48b-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-2764-1f48b-1f468-1f3fb.png",
    ["1f468-1f3fd-2764-1f48b-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-2764-1f48b-1f468-1f3fc.png",
    ["1f468-1f3fd-2764-1f48b-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-2764-1f48b-1f468-1f3fd.png",
    ["1f468-1f3fd-2764-1f48b-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-2764-1f48b-1f468-1f3fe.png",
    ["1f468-1f3fd-2764-1f48b-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd-2764-1f48b-1f468-1f3ff.png",
    ["1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fd.png",
    ["1f468-1f3fe-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f33e.png",
    ["1f468-1f3fe-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f373.png",
    ["1f468-1f3fe-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f37c.png",
    ["1f468-1f3fe-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f393.png",
    ["1f468-1f3fe-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f3a4.png",
    ["1f468-1f3fe-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f3a8.png",
    ["1f468-1f3fe-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f3eb.png",
    ["1f468-1f3fe-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f3ed.png",
    ["1f468-1f3fe-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f4bb.png",
    ["1f468-1f3fe-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f4bc.png",
    ["1f468-1f3fe-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f527.png",
    ["1f468-1f3fe-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f52c.png",
    ["1f468-1f3fe-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f680.png",
    ["1f468-1f3fe-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f692.png",
    ["1f468-1f3fe-1f91d-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f91d-1f468-1f3fb.png",
    ["1f468-1f3fe-1f91d-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f91d-1f468-1f3fc.png",
    ["1f468-1f3fe-1f91d-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f91d-1f468-1f3fd.png",
    ["1f468-1f3fe-1f91d-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f91d-1f468-1f3ff.png",
    ["1f468-1f3fe-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f9af.png",
    ["1f468-1f3fe-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f9b0.png",
    ["1f468-1f3fe-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f9b1.png",
    ["1f468-1f3fe-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f9b2.png",
    ["1f468-1f3fe-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f9b3.png",
    ["1f468-1f3fe-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f9bc.png",
    ["1f468-1f3fe-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-1f9bd.png",
    ["1f468-1f3fe-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-2695.png",
    ["1f468-1f3fe-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-2696.png",
    ["1f468-1f3fe-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-2708.png",
    ["1f468-1f3fe-2764-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-2764-1f468-1f3fb.png",
    ["1f468-1f3fe-2764-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-2764-1f468-1f3fc.png",
    ["1f468-1f3fe-2764-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-2764-1f468-1f3fd.png",
    ["1f468-1f3fe-2764-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-2764-1f468-1f3fe.png",
    ["1f468-1f3fe-2764-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-2764-1f468-1f3ff.png",
    ["1f468-1f3fe-2764-1f48b-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-2764-1f48b-1f468-1f3fb.png",
    ["1f468-1f3fe-2764-1f48b-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-2764-1f48b-1f468-1f3fc.png",
    ["1f468-1f3fe-2764-1f48b-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-2764-1f48b-1f468-1f3fd.png",
    ["1f468-1f3fe-2764-1f48b-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-2764-1f48b-1f468-1f3fe.png",
    ["1f468-1f3fe-2764-1f48b-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe-2764-1f48b-1f468-1f3ff.png",
    ["1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3fe.png",
    ["1f468-1f3ff-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f33e.png",
    ["1f468-1f3ff-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f373.png",
    ["1f468-1f3ff-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f37c.png",
    ["1f468-1f3ff-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f393.png",
    ["1f468-1f3ff-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f3a4.png",
    ["1f468-1f3ff-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f3a8.png",
    ["1f468-1f3ff-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f3eb.png",
    ["1f468-1f3ff-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f3ed.png",
    ["1f468-1f3ff-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f4bb.png",
    ["1f468-1f3ff-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f4bc.png",
    ["1f468-1f3ff-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f527.png",
    ["1f468-1f3ff-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f52c.png",
    ["1f468-1f3ff-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f680.png",
    ["1f468-1f3ff-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f692.png",
    ["1f468-1f3ff-1f91d-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f91d-1f468-1f3fb.png",
    ["1f468-1f3ff-1f91d-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f91d-1f468-1f3fc.png",
    ["1f468-1f3ff-1f91d-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f91d-1f468-1f3fd.png",
    ["1f468-1f3ff-1f91d-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f91d-1f468-1f3fe.png",
    ["1f468-1f3ff-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f9af.png",
    ["1f468-1f3ff-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f9b0.png",
    ["1f468-1f3ff-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f9b1.png",
    ["1f468-1f3ff-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f9b2.png",
    ["1f468-1f3ff-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f9b3.png",
    ["1f468-1f3ff-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f9bc.png",
    ["1f468-1f3ff-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-1f9bd.png",
    ["1f468-1f3ff-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-2695.png",
    ["1f468-1f3ff-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-2696.png",
    ["1f468-1f3ff-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-2708.png",
    ["1f468-1f3ff-2764-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-2764-1f468-1f3fb.png",
    ["1f468-1f3ff-2764-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-2764-1f468-1f3fc.png",
    ["1f468-1f3ff-2764-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-2764-1f468-1f3fd.png",
    ["1f468-1f3ff-2764-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-2764-1f468-1f3fe.png",
    ["1f468-1f3ff-2764-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-2764-1f468-1f3ff.png",
    ["1f468-1f3ff-2764-1f48b-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-2764-1f48b-1f468-1f3fb.png",
    ["1f468-1f3ff-2764-1f48b-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-2764-1f48b-1f468-1f3fc.png",
    ["1f468-1f3ff-2764-1f48b-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-2764-1f48b-1f468-1f3fd.png",
    ["1f468-1f3ff-2764-1f48b-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-2764-1f48b-1f468-1f3fe.png",
    ["1f468-1f3ff-2764-1f48b-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff-2764-1f48b-1f468-1f3ff.png",
    ["1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f3ff.png",
    ["1f468-1f466-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f466-1f466.png",
    ["1f468-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f466.png",
    ["1f468-1f467-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f467-1f466.png",
    ["1f468-1f467-1f467.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f467-1f467.png",
    ["1f468-1f467.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f467.png",
    ["1f468-1f468-1f466-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f468-1f466-1f466.png",
    ["1f468-1f468-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f468-1f466.png",
    ["1f468-1f468-1f467-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f468-1f467-1f466.png",
    ["1f468-1f468-1f467-1f467.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f468-1f467-1f467.png",
    ["1f468-1f468-1f467.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f468-1f467.png",
    ["1f468-1f469-1f466-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f469-1f466-1f466.png",
    ["1f468-1f469-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f469-1f466.png",
    ["1f468-1f469-1f467-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f469-1f467-1f466.png",
    ["1f468-1f469-1f467-1f467.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f469-1f467-1f467.png",
    ["1f468-1f469-1f467.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f469-1f467.png",
    ["1f468-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f4bb.png",
    ["1f468-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f4bc.png",
    ["1f468-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f527.png",
    ["1f468-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f52c.png",
    ["1f468-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f680.png",
    ["1f468-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f692.png",
    ["1f468-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f9af.png",
    ["1f468-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f9b0.png",
    ["1f468-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f9b1.png",
    ["1f468-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f9b2.png",
    ["1f468-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f9b3.png",
    ["1f468-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f9bc.png",
    ["1f468-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-1f9bd.png",
    ["1f468-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-2695.png",
    ["1f468-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-2696.png",
    ["1f468-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-2708.png",
    ["1f468-2764-1f468.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-2764-1f468.png",
    ["1f468-2764-1f48b-1f468.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468-2764-1f48b-1f468.png",
    ["1f468.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f468.png",
    ["1f469-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f33e.png",
    ["1f469-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f373.png",
    ["1f469-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f37c.png",
    ["1f469-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f393.png",
    ["1f469-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3a4.png",
    ["1f469-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3a8.png",
    ["1f469-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3eb.png",
    ["1f469-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ed.png",
    ["1f469-1f3fb-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f33e.png",
    ["1f469-1f3fb-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f373.png",
    ["1f469-1f3fb-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f37c.png",
    ["1f469-1f3fb-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f393.png",
    ["1f469-1f3fb-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f3a4.png",
    ["1f469-1f3fb-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f3a8.png",
    ["1f469-1f3fb-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f3eb.png",
    ["1f469-1f3fb-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f3ed.png",
    ["1f469-1f3fb-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f4bb.png",
    ["1f469-1f3fb-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f4bc.png",
    ["1f469-1f3fb-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f527.png",
    ["1f469-1f3fb-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f52c.png",
    ["1f469-1f3fb-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f680.png",
    ["1f469-1f3fb-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f692.png",
    ["1f469-1f3fb-1f91d-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f91d-1f468-1f3fc.png",
    ["1f469-1f3fb-1f91d-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f91d-1f468-1f3fd.png",
    ["1f469-1f3fb-1f91d-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f91d-1f468-1f3fe.png",
    ["1f469-1f3fb-1f91d-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f91d-1f468-1f3ff.png",
    ["1f469-1f3fb-1f91d-1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f91d-1f469-1f3fc.png",
    ["1f469-1f3fb-1f91d-1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f91d-1f469-1f3fd.png",
    ["1f469-1f3fb-1f91d-1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f91d-1f469-1f3fe.png",
    ["1f469-1f3fb-1f91d-1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f91d-1f469-1f3ff.png",
    ["1f469-1f3fb-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f9af.png",
    ["1f469-1f3fb-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f9b0.png",
    ["1f469-1f3fb-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f9b1.png",
    ["1f469-1f3fb-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f9b2.png",
    ["1f469-1f3fb-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f9b3.png",
    ["1f469-1f3fb-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f9bc.png",
    ["1f469-1f3fb-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-1f9bd.png",
    ["1f469-1f3fb-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2695.png",
    ["1f469-1f3fb-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2696.png",
    ["1f469-1f3fb-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2708.png",
    ["1f469-1f3fb-2764-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f468-1f3fb.png",
    ["1f469-1f3fb-2764-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f468-1f3fc.png",
    ["1f469-1f3fb-2764-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f468-1f3fd.png",
    ["1f469-1f3fb-2764-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f468-1f3fe.png",
    ["1f469-1f3fb-2764-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f468-1f3ff.png",
    ["1f469-1f3fb-2764-1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f469-1f3fb.png",
    ["1f469-1f3fb-2764-1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f469-1f3fc.png",
    ["1f469-1f3fb-2764-1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f469-1f3fd.png",
    ["1f469-1f3fb-2764-1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f469-1f3fe.png",
    ["1f469-1f3fb-2764-1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f469-1f3ff.png",
    ["1f469-1f3fb-2764-1f48b-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f48b-1f468-1f3fb.png",
    ["1f469-1f3fb-2764-1f48b-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f48b-1f468-1f3fc.png",
    ["1f469-1f3fb-2764-1f48b-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f48b-1f468-1f3fd.png",
    ["1f469-1f3fb-2764-1f48b-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f48b-1f468-1f3fe.png",
    ["1f469-1f3fb-2764-1f48b-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f48b-1f468-1f3ff.png",
    ["1f469-1f3fb-2764-1f48b-1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f48b-1f469-1f3fb.png",
    ["1f469-1f3fb-2764-1f48b-1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f48b-1f469-1f3fc.png",
    ["1f469-1f3fb-2764-1f48b-1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f48b-1f469-1f3fd.png",
    ["1f469-1f3fb-2764-1f48b-1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f48b-1f469-1f3fe.png",
    ["1f469-1f3fb-2764-1f48b-1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb-2764-1f48b-1f469-1f3ff.png",
    ["1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fb.png",
    ["1f469-1f3fc-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f33e.png",
    ["1f469-1f3fc-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f373.png",
    ["1f469-1f3fc-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f37c.png",
    ["1f469-1f3fc-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f393.png",
    ["1f469-1f3fc-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f3a4.png",
    ["1f469-1f3fc-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f3a8.png",
    ["1f469-1f3fc-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f3eb.png",
    ["1f469-1f3fc-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f3ed.png",
    ["1f469-1f3fc-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f4bb.png",
    ["1f469-1f3fc-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f4bc.png",
    ["1f469-1f3fc-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f527.png",
    ["1f469-1f3fc-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f52c.png",
    ["1f469-1f3fc-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f680.png",
    ["1f469-1f3fc-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f692.png",
    ["1f469-1f3fc-1f91d-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f91d-1f468-1f3fb.png",
    ["1f469-1f3fc-1f91d-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f91d-1f468-1f3fd.png",
    ["1f469-1f3fc-1f91d-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f91d-1f468-1f3fe.png",
    ["1f469-1f3fc-1f91d-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f91d-1f468-1f3ff.png",
    ["1f469-1f3fc-1f91d-1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f91d-1f469-1f3fb.png",
    ["1f469-1f3fc-1f91d-1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f91d-1f469-1f3fd.png",
    ["1f469-1f3fc-1f91d-1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f91d-1f469-1f3fe.png",
    ["1f469-1f3fc-1f91d-1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f91d-1f469-1f3ff.png",
    ["1f469-1f3fc-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f9af.png",
    ["1f469-1f3fc-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f9b0.png",
    ["1f469-1f3fc-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f9b1.png",
    ["1f469-1f3fc-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f9b2.png",
    ["1f469-1f3fc-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f9b3.png",
    ["1f469-1f3fc-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f9bc.png",
    ["1f469-1f3fc-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-1f9bd.png",
    ["1f469-1f3fc-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2695.png",
    ["1f469-1f3fc-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2696.png",
    ["1f469-1f3fc-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2708.png",
    ["1f469-1f3fc-2764-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f468-1f3fb.png",
    ["1f469-1f3fc-2764-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f468-1f3fc.png",
    ["1f469-1f3fc-2764-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f468-1f3fd.png",
    ["1f469-1f3fc-2764-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f468-1f3fe.png",
    ["1f469-1f3fc-2764-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f468-1f3ff.png",
    ["1f469-1f3fc-2764-1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f469-1f3fb.png",
    ["1f469-1f3fc-2764-1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f469-1f3fc.png",
    ["1f469-1f3fc-2764-1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f469-1f3fd.png",
    ["1f469-1f3fc-2764-1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f469-1f3fe.png",
    ["1f469-1f3fc-2764-1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f469-1f3ff.png",
    ["1f469-1f3fc-2764-1f48b-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f48b-1f468-1f3fb.png",
    ["1f469-1f3fc-2764-1f48b-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f48b-1f468-1f3fc.png",
    ["1f469-1f3fc-2764-1f48b-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f48b-1f468-1f3fd.png",
    ["1f469-1f3fc-2764-1f48b-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f48b-1f468-1f3fe.png",
    ["1f469-1f3fc-2764-1f48b-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f48b-1f468-1f3ff.png",
    ["1f469-1f3fc-2764-1f48b-1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f48b-1f469-1f3fb.png",
    ["1f469-1f3fc-2764-1f48b-1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f48b-1f469-1f3fc.png",
    ["1f469-1f3fc-2764-1f48b-1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f48b-1f469-1f3fd.png",
    ["1f469-1f3fc-2764-1f48b-1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f48b-1f469-1f3fe.png",
    ["1f469-1f3fc-2764-1f48b-1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc-2764-1f48b-1f469-1f3ff.png",
    ["1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fc.png",
    ["1f469-1f3fd-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f33e.png",
    ["1f469-1f3fd-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f373.png",
    ["1f469-1f3fd-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f37c.png",
    ["1f469-1f3fd-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f393.png",
    ["1f469-1f3fd-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f3a4.png",
    ["1f469-1f3fd-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f3a8.png",
    ["1f469-1f3fd-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f3eb.png",
    ["1f469-1f3fd-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f3ed.png",
    ["1f469-1f3fd-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f4bb.png",
    ["1f469-1f3fd-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f4bc.png",
    ["1f469-1f3fd-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f527.png",
    ["1f469-1f3fd-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f52c.png",
    ["1f469-1f3fd-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f680.png",
    ["1f469-1f3fd-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f692.png",
    ["1f469-1f3fd-1f91d-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f91d-1f468-1f3fb.png",
    ["1f469-1f3fd-1f91d-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f91d-1f468-1f3fc.png",
    ["1f469-1f3fd-1f91d-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f91d-1f468-1f3fe.png",
    ["1f469-1f3fd-1f91d-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f91d-1f468-1f3ff.png",
    ["1f469-1f3fd-1f91d-1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f91d-1f469-1f3fb.png",
    ["1f469-1f3fd-1f91d-1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f91d-1f469-1f3fc.png",
    ["1f469-1f3fd-1f91d-1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f91d-1f469-1f3fe.png",
    ["1f469-1f3fd-1f91d-1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f91d-1f469-1f3ff.png",
    ["1f469-1f3fd-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f9af.png",
    ["1f469-1f3fd-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f9b0.png",
    ["1f469-1f3fd-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f9b1.png",
    ["1f469-1f3fd-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f9b2.png",
    ["1f469-1f3fd-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f9b3.png",
    ["1f469-1f3fd-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f9bc.png",
    ["1f469-1f3fd-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-1f9bd.png",
    ["1f469-1f3fd-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2695.png",
    ["1f469-1f3fd-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2696.png",
    ["1f469-1f3fd-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2708.png",
    ["1f469-1f3fd-2764-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f468-1f3fb.png",
    ["1f469-1f3fd-2764-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f468-1f3fc.png",
    ["1f469-1f3fd-2764-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f468-1f3fd.png",
    ["1f469-1f3fd-2764-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f468-1f3fe.png",
    ["1f469-1f3fd-2764-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f468-1f3ff.png",
    ["1f469-1f3fd-2764-1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f469-1f3fb.png",
    ["1f469-1f3fd-2764-1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f469-1f3fc.png",
    ["1f469-1f3fd-2764-1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f469-1f3fd.png",
    ["1f469-1f3fd-2764-1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f469-1f3fe.png",
    ["1f469-1f3fd-2764-1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f469-1f3ff.png",
    ["1f469-1f3fd-2764-1f48b-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f48b-1f468-1f3fb.png",
    ["1f469-1f3fd-2764-1f48b-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f48b-1f468-1f3fc.png",
    ["1f469-1f3fd-2764-1f48b-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f48b-1f468-1f3fd.png",
    ["1f469-1f3fd-2764-1f48b-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f48b-1f468-1f3fe.png",
    ["1f469-1f3fd-2764-1f48b-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f48b-1f468-1f3ff.png",
    ["1f469-1f3fd-2764-1f48b-1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f48b-1f469-1f3fb.png",
    ["1f469-1f3fd-2764-1f48b-1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f48b-1f469-1f3fc.png",
    ["1f469-1f3fd-2764-1f48b-1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f48b-1f469-1f3fd.png",
    ["1f469-1f3fd-2764-1f48b-1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f48b-1f469-1f3fe.png",
    ["1f469-1f3fd-2764-1f48b-1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd-2764-1f48b-1f469-1f3ff.png",
    ["1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fd.png",
    ["1f469-1f3fe-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f33e.png",
    ["1f469-1f3fe-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f373.png",
    ["1f469-1f3fe-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f37c.png",
    ["1f469-1f3fe-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f393.png",
    ["1f469-1f3fe-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f3a4.png",
    ["1f469-1f3fe-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f3a8.png",
    ["1f469-1f3fe-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f3eb.png",
    ["1f469-1f3fe-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f3ed.png",
    ["1f469-1f3fe-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f4bb.png",
    ["1f469-1f3fe-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f4bc.png",
    ["1f469-1f3fe-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f527.png",
    ["1f469-1f3fe-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f52c.png",
    ["1f469-1f3fe-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f680.png",
    ["1f469-1f3fe-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f692.png",
    ["1f469-1f3fe-1f91d-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f91d-1f468-1f3fb.png",
    ["1f469-1f3fe-1f91d-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f91d-1f468-1f3fc.png",
    ["1f469-1f3fe-1f91d-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f91d-1f468-1f3fd.png",
    ["1f469-1f3fe-1f91d-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f91d-1f468-1f3ff.png",
    ["1f469-1f3fe-1f91d-1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f91d-1f469-1f3fb.png",
    ["1f469-1f3fe-1f91d-1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f91d-1f469-1f3fc.png",
    ["1f469-1f3fe-1f91d-1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f91d-1f469-1f3fd.png",
    ["1f469-1f3fe-1f91d-1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f91d-1f469-1f3ff.png",
    ["1f469-1f3fe-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f9af.png",
    ["1f469-1f3fe-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f9b0.png",
    ["1f469-1f3fe-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f9b1.png",
    ["1f469-1f3fe-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f9b2.png",
    ["1f469-1f3fe-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f9b3.png",
    ["1f469-1f3fe-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f9bc.png",
    ["1f469-1f3fe-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-1f9bd.png",
    ["1f469-1f3fe-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2695.png",
    ["1f469-1f3fe-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2696.png",
    ["1f469-1f3fe-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2708.png",
    ["1f469-1f3fe-2764-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f468-1f3fb.png",
    ["1f469-1f3fe-2764-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f468-1f3fc.png",
    ["1f469-1f3fe-2764-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f468-1f3fd.png",
    ["1f469-1f3fe-2764-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f468-1f3fe.png",
    ["1f469-1f3fe-2764-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f468-1f3ff.png",
    ["1f469-1f3fe-2764-1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f469-1f3fb.png",
    ["1f469-1f3fe-2764-1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f469-1f3fc.png",
    ["1f469-1f3fe-2764-1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f469-1f3fd.png",
    ["1f469-1f3fe-2764-1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f469-1f3fe.png",
    ["1f469-1f3fe-2764-1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f469-1f3ff.png",
    ["1f469-1f3fe-2764-1f48b-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f48b-1f468-1f3fb.png",
    ["1f469-1f3fe-2764-1f48b-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f48b-1f468-1f3fc.png",
    ["1f469-1f3fe-2764-1f48b-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f48b-1f468-1f3fd.png",
    ["1f469-1f3fe-2764-1f48b-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f48b-1f468-1f3fe.png",
    ["1f469-1f3fe-2764-1f48b-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f48b-1f468-1f3ff.png",
    ["1f469-1f3fe-2764-1f48b-1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f48b-1f469-1f3fb.png",
    ["1f469-1f3fe-2764-1f48b-1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f48b-1f469-1f3fc.png",
    ["1f469-1f3fe-2764-1f48b-1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f48b-1f469-1f3fd.png",
    ["1f469-1f3fe-2764-1f48b-1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f48b-1f469-1f3fe.png",
    ["1f469-1f3fe-2764-1f48b-1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe-2764-1f48b-1f469-1f3ff.png",
    ["1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3fe.png",
    ["1f469-1f3ff-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f33e.png",
    ["1f469-1f3ff-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f373.png",
    ["1f469-1f3ff-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f37c.png",
    ["1f469-1f3ff-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f393.png",
    ["1f469-1f3ff-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f3a4.png",
    ["1f469-1f3ff-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f3a8.png",
    ["1f469-1f3ff-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f3eb.png",
    ["1f469-1f3ff-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f3ed.png",
    ["1f469-1f3ff-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f4bb.png",
    ["1f469-1f3ff-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f4bc.png",
    ["1f469-1f3ff-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f527.png",
    ["1f469-1f3ff-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f52c.png",
    ["1f469-1f3ff-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f680.png",
    ["1f469-1f3ff-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f692.png",
    ["1f469-1f3ff-1f91d-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f91d-1f468-1f3fb.png",
    ["1f469-1f3ff-1f91d-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f91d-1f468-1f3fc.png",
    ["1f469-1f3ff-1f91d-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f91d-1f468-1f3fd.png",
    ["1f469-1f3ff-1f91d-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f91d-1f468-1f3fe.png",
    ["1f469-1f3ff-1f91d-1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f91d-1f469-1f3fb.png",
    ["1f469-1f3ff-1f91d-1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f91d-1f469-1f3fc.png",
    ["1f469-1f3ff-1f91d-1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f91d-1f469-1f3fd.png",
    ["1f469-1f3ff-1f91d-1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f91d-1f469-1f3fe.png",
    ["1f469-1f3ff-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f9af.png",
    ["1f469-1f3ff-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f9b0.png",
    ["1f469-1f3ff-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f9b1.png",
    ["1f469-1f3ff-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f9b2.png",
    ["1f469-1f3ff-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f9b3.png",
    ["1f469-1f3ff-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f9bc.png",
    ["1f469-1f3ff-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-1f9bd.png",
    ["1f469-1f3ff-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2695.png",
    ["1f469-1f3ff-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2696.png",
    ["1f469-1f3ff-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2708.png",
    ["1f469-1f3ff-2764-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f468-1f3fb.png",
    ["1f469-1f3ff-2764-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f468-1f3fc.png",
    ["1f469-1f3ff-2764-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f468-1f3fd.png",
    ["1f469-1f3ff-2764-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f468-1f3fe.png",
    ["1f469-1f3ff-2764-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f468-1f3ff.png",
    ["1f469-1f3ff-2764-1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f469-1f3fb.png",
    ["1f469-1f3ff-2764-1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f469-1f3fc.png",
    ["1f469-1f3ff-2764-1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f469-1f3fd.png",
    ["1f469-1f3ff-2764-1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f469-1f3fe.png",
    ["1f469-1f3ff-2764-1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f469-1f3ff.png",
    ["1f469-1f3ff-2764-1f48b-1f468-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f48b-1f468-1f3fb.png",
    ["1f469-1f3ff-2764-1f48b-1f468-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f48b-1f468-1f3fc.png",
    ["1f469-1f3ff-2764-1f48b-1f468-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f48b-1f468-1f3fd.png",
    ["1f469-1f3ff-2764-1f48b-1f468-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f48b-1f468-1f3fe.png",
    ["1f469-1f3ff-2764-1f48b-1f468-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f48b-1f468-1f3ff.png",
    ["1f469-1f3ff-2764-1f48b-1f469-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f48b-1f469-1f3fb.png",
    ["1f469-1f3ff-2764-1f48b-1f469-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f48b-1f469-1f3fc.png",
    ["1f469-1f3ff-2764-1f48b-1f469-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f48b-1f469-1f3fd.png",
    ["1f469-1f3ff-2764-1f48b-1f469-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f48b-1f469-1f3fe.png",
    ["1f469-1f3ff-2764-1f48b-1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff-2764-1f48b-1f469-1f3ff.png",
    ["1f469-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f3ff.png",
    ["1f469-1f466-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f466-1f466.png",
    ["1f469-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f466.png",
    ["1f469-1f467-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f467-1f466.png",
    ["1f469-1f467-1f467.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f467-1f467.png",
    ["1f469-1f467.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f467.png",
    ["1f469-1f469-1f466-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f469-1f466-1f466.png",
    ["1f469-1f469-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f469-1f466.png",
    ["1f469-1f469-1f467-1f466.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f469-1f467-1f466.png",
    ["1f469-1f469-1f467-1f467.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f469-1f467-1f467.png",
    ["1f469-1f469-1f467.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f469-1f467.png",
    ["1f469-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f4bb.png",
    ["1f469-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f4bc.png",
    ["1f469-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f527.png",
    ["1f469-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f52c.png",
    ["1f469-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f680.png",
    ["1f469-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f692.png",
    ["1f469-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f9af.png",
    ["1f469-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f9b0.png",
    ["1f469-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f9b1.png",
    ["1f469-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f9b2.png",
    ["1f469-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f9b3.png",
    ["1f469-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f9bc.png",
    ["1f469-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-1f9bd.png",
    ["1f469-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-2695.png",
    ["1f469-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-2696.png",
    ["1f469-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-2708.png",
    ["1f469-2764-1f468.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-2764-1f468.png",
    ["1f469-2764-1f469.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-2764-1f469.png",
    ["1f469-2764-1f48b-1f468.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-2764-1f48b-1f468.png",
    ["1f469-2764-1f48b-1f469.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469-2764-1f48b-1f469.png",
    ["1f469.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f469.png",
    ["1f46a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46a.png",
    ["1f46b-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46b-1f3fb.png",
    ["1f46b-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46b-1f3fc.png",
    ["1f46b-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46b-1f3fd.png",
    ["1f46b-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46b-1f3fe.png",
    ["1f46b-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46b-1f3ff.png",
    ["1f46b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46b.png",
    ["1f46c-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46c-1f3fb.png",
    ["1f46c-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46c-1f3fc.png",
    ["1f46c-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46c-1f3fd.png",
    ["1f46c-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46c-1f3fe.png",
    ["1f46c-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46c-1f3ff.png",
    ["1f46c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46c.png",
    ["1f46d-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46d-1f3fb.png",
    ["1f46d-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46d-1f3fc.png",
    ["1f46d-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46d-1f3fd.png",
    ["1f46d-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46d-1f3fe.png",
    ["1f46d-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46d-1f3ff.png",
    ["1f46d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46d.png",
    ["1f46e-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3fb-2640.png",
    ["1f46e-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3fb-2642.png",
    ["1f46e-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3fb.png",
    ["1f46e-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3fc-2640.png",
    ["1f46e-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3fc-2642.png",
    ["1f46e-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3fc.png",
    ["1f46e-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3fd-2640.png",
    ["1f46e-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3fd-2642.png",
    ["1f46e-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3fd.png",
    ["1f46e-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3fe-2640.png",
    ["1f46e-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3fe-2642.png",
    ["1f46e-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3fe.png",
    ["1f46e-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3ff-2640.png",
    ["1f46e-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3ff-2642.png",
    ["1f46e-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-1f3ff.png",
    ["1f46e-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-2640.png",
    ["1f46e-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e-2642.png",
    ["1f46e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46e.png",
    ["1f46f-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46f-2640.png",
    ["1f46f-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46f-2642.png",
    ["1f46f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f46f.png",
    ["1f470-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3fb-2640.png",
    ["1f470-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3fb-2642.png",
    ["1f470-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3fb.png",
    ["1f470-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3fc-2640.png",
    ["1f470-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3fc-2642.png",
    ["1f470-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3fc.png",
    ["1f470-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3fd-2640.png",
    ["1f470-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3fd-2642.png",
    ["1f470-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3fd.png",
    ["1f470-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3fe-2640.png",
    ["1f470-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3fe-2642.png",
    ["1f470-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3fe.png",
    ["1f470-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3ff-2640.png",
    ["1f470-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3ff-2642.png",
    ["1f470-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-1f3ff.png",
    ["1f470-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-2640.png",
    ["1f470-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470-2642.png",
    ["1f470.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f470.png",
    ["1f471-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3fb-2640.png",
    ["1f471-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3fb-2642.png",
    ["1f471-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3fb.png",
    ["1f471-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3fc-2640.png",
    ["1f471-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3fc-2642.png",
    ["1f471-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3fc.png",
    ["1f471-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3fd-2640.png",
    ["1f471-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3fd-2642.png",
    ["1f471-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3fd.png",
    ["1f471-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3fe-2640.png",
    ["1f471-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3fe-2642.png",
    ["1f471-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3fe.png",
    ["1f471-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3ff-2640.png",
    ["1f471-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3ff-2642.png",
    ["1f471-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-1f3ff.png",
    ["1f471-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-2640.png",
    ["1f471-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471-2642.png",
    ["1f471.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f471.png",
    ["1f472-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f472-1f3fb.png",
    ["1f472-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f472-1f3fc.png",
    ["1f472-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f472-1f3fd.png",
    ["1f472-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f472-1f3fe.png",
    ["1f472-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f472-1f3ff.png",
    ["1f472.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f472.png",
    ["1f473-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3fb-2640.png",
    ["1f473-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3fb-2642.png",
    ["1f473-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3fb.png",
    ["1f473-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3fc-2640.png",
    ["1f473-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3fc-2642.png",
    ["1f473-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3fc.png",
    ["1f473-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3fd-2640.png",
    ["1f473-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3fd-2642.png",
    ["1f473-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3fd.png",
    ["1f473-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3fe-2640.png",
    ["1f473-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3fe-2642.png",
    ["1f473-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3fe.png",
    ["1f473-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3ff-2640.png",
    ["1f473-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3ff-2642.png",
    ["1f473-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-1f3ff.png",
    ["1f473-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-2640.png",
    ["1f473-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473-2642.png",
    ["1f473.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f473.png",
    ["1f474-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f474-1f3fb.png",
    ["1f474-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f474-1f3fc.png",
    ["1f474-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f474-1f3fd.png",
    ["1f474-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f474-1f3fe.png",
    ["1f474-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f474-1f3ff.png",
    ["1f474.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f474.png",
    ["1f475-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f475-1f3fb.png",
    ["1f475-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f475-1f3fc.png",
    ["1f475-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f475-1f3fd.png",
    ["1f475-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f475-1f3fe.png",
    ["1f475-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f475-1f3ff.png",
    ["1f475.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f475.png",
    ["1f476-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f476-1f3fb.png",
    ["1f476-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f476-1f3fc.png",
    ["1f476-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f476-1f3fd.png",
    ["1f476-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f476-1f3fe.png",
    ["1f476-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f476-1f3ff.png",
    ["1f476.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f476.png",
    ["1f477-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3fb-2640.png",
    ["1f477-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3fb-2642.png",
    ["1f477-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3fb.png",
    ["1f477-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3fc-2640.png",
    ["1f477-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3fc-2642.png",
    ["1f477-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3fc.png",
    ["1f477-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3fd-2640.png",
    ["1f477-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3fd-2642.png",
    ["1f477-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3fd.png",
    ["1f477-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3fe-2640.png",
    ["1f477-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3fe-2642.png",
    ["1f477-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3fe.png",
    ["1f477-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3ff-2640.png",
    ["1f477-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3ff-2642.png",
    ["1f477-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-1f3ff.png",
    ["1f477-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-2640.png",
    ["1f477-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477-2642.png",
    ["1f477.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f477.png",
    ["1f478-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f478-1f3fb.png",
    ["1f478-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f478-1f3fc.png",
    ["1f478-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f478-1f3fd.png",
    ["1f478-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f478-1f3fe.png",
    ["1f478-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f478-1f3ff.png",
    ["1f478.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f478.png",
    ["1f479.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f479.png",
    ["1f47a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f47a.png",
    ["1f47b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f47b.png",
    ["1f47c-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f47c-1f3fb.png",
    ["1f47c-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f47c-1f3fc.png",
    ["1f47c-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f47c-1f3fd.png",
    ["1f47c-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f47c-1f3fe.png",
    ["1f47c-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f47c-1f3ff.png",
    ["1f47c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f47c.png",
    ["1f47d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f47d.png",
    ["1f47e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f47e.png",
    ["1f47f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f47f.png",
    ["1f480.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f480.png",
    ["1f481-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3fb-2640.png",
    ["1f481-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3fb-2642.png",
    ["1f481-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3fb.png",
    ["1f481-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3fc-2640.png",
    ["1f481-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3fc-2642.png",
    ["1f481-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3fc.png",
    ["1f481-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3fd-2640.png",
    ["1f481-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3fd-2642.png",
    ["1f481-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3fd.png",
    ["1f481-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3fe-2640.png",
    ["1f481-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3fe-2642.png",
    ["1f481-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3fe.png",
    ["1f481-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3ff-2640.png",
    ["1f481-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3ff-2642.png",
    ["1f481-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-1f3ff.png",
    ["1f481-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-2640.png",
    ["1f481-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481-2642.png",
    ["1f481.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f481.png",
    ["1f482-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3fb-2640.png",
    ["1f482-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3fb-2642.png",
    ["1f482-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3fb.png",
    ["1f482-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3fc-2640.png",
    ["1f482-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3fc-2642.png",
    ["1f482-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3fc.png",
    ["1f482-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3fd-2640.png",
    ["1f482-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3fd-2642.png",
    ["1f482-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3fd.png",
    ["1f482-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3fe-2640.png",
    ["1f482-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3fe-2642.png",
    ["1f482-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3fe.png",
    ["1f482-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3ff-2640.png",
    ["1f482-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3ff-2642.png",
    ["1f482-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-1f3ff.png",
    ["1f482-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-2640.png",
    ["1f482-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482-2642.png",
    ["1f482.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f482.png",
    ["1f483-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f483-1f3fb.png",
    ["1f483-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f483-1f3fc.png",
    ["1f483-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f483-1f3fd.png",
    ["1f483-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f483-1f3fe.png",
    ["1f483-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f483-1f3ff.png",
    ["1f483.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f483.png",
    ["1f484.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f484.png",
    ["1f485-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f485-1f3fb.png",
    ["1f485-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f485-1f3fc.png",
    ["1f485-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f485-1f3fd.png",
    ["1f485-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f485-1f3fe.png",
    ["1f485-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f485-1f3ff.png",
    ["1f485.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f485.png",
    ["1f486-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3fb-2640.png",
    ["1f486-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3fb-2642.png",
    ["1f486-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3fb.png",
    ["1f486-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3fc-2640.png",
    ["1f486-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3fc-2642.png",
    ["1f486-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3fc.png",
    ["1f486-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3fd-2640.png",
    ["1f486-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3fd-2642.png",
    ["1f486-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3fd.png",
    ["1f486-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3fe-2640.png",
    ["1f486-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3fe-2642.png",
    ["1f486-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3fe.png",
    ["1f486-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3ff-2640.png",
    ["1f486-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3ff-2642.png",
    ["1f486-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-1f3ff.png",
    ["1f486-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-2640.png",
    ["1f486-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486-2642.png",
    ["1f486.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f486.png",
    ["1f487-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3fb-2640.png",
    ["1f487-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3fb-2642.png",
    ["1f487-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3fb.png",
    ["1f487-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3fc-2640.png",
    ["1f487-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3fc-2642.png",
    ["1f487-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3fc.png",
    ["1f487-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3fd-2640.png",
    ["1f487-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3fd-2642.png",
    ["1f487-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3fd.png",
    ["1f487-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3fe-2640.png",
    ["1f487-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3fe-2642.png",
    ["1f487-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3fe.png",
    ["1f487-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3ff-2640.png",
    ["1f487-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3ff-2642.png",
    ["1f487-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-1f3ff.png",
    ["1f487-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-2640.png",
    ["1f487-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487-2642.png",
    ["1f487.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f487.png",
    ["1f488.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f488.png",
    ["1f489.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f489.png",
    ["1f48a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f48a.png",
    ["1f48b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f48b.png",
    ["1f48c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f48c.png",
    ["1f48d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f48d.png",
    ["1f48e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f48e.png",
    ["1f48f-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f48f-1f3fb.png",
    ["1f48f-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f48f-1f3fc.png",
    ["1f48f-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f48f-1f3fd.png",
    ["1f48f-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f48f-1f3fe.png",
    ["1f48f-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f48f-1f3ff.png",
    ["1f48f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f48f.png",
    ["1f490.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f490.png",
    ["1f491-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f491-1f3fb.png",
    ["1f491-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f491-1f3fc.png",
    ["1f491-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f491-1f3fd.png",
    ["1f491-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f491-1f3fe.png",
    ["1f491-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f491-1f3ff.png",
    ["1f491.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f491.png",
    ["1f492.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f492.png",
    ["1f493.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f493.png",
    ["1f494.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f494.png",
    ["1f495.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f495.png",
    ["1f496.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f496.png",
    ["1f497.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f497.png",
    ["1f498.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f498.png",
    ["1f499.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f499.png",
    ["1f49a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f49a.png",
    ["1f49b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f49b.png",
    ["1f49c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f49c.png",
    ["1f49d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f49d.png",
    ["1f49e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f49e.png",
    ["1f49f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f49f.png",
    ["1f4a0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4a0.png",
    ["1f4a1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4a1.png",
    ["1f4a2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4a2.png",
    ["1f4a3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4a3.png",
    ["1f4a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4a4.png",
    ["1f4a5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4a5.png",
    ["1f4a6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4a6.png",
    ["1f4a7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4a7.png",
    ["1f4a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4a8.png",
    ["1f4a9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4a9.png",
    ["1f4aa-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4aa-1f3fb.png",
    ["1f4aa-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4aa-1f3fc.png",
    ["1f4aa-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4aa-1f3fd.png",
    ["1f4aa-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4aa-1f3fe.png",
    ["1f4aa-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4aa-1f3ff.png",
    ["1f4aa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4aa.png",
    ["1f4ab.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4ab.png",
    ["1f4ac.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4ac.png",
    ["1f4ad.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4ad.png",
    ["1f4ae.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4ae.png",
    ["1f4af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4af.png",
    ["1f4b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4b0.png",
    ["1f4b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4b1.png",
    ["1f4b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4b2.png",
    ["1f4b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4b3.png",
    ["1f4b4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4b4.png",
    ["1f4b5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4b5.png",
    ["1f4b6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4b6.png",
    ["1f4b7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4b7.png",
    ["1f4b8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4b8.png",
    ["1f4b9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4b9.png",
    ["1f4ba.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4ba.png",
    ["1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4bb.png",
    ["1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4bc.png",
    ["1f4bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4bd.png",
    ["1f4be.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4be.png",
    ["1f4bf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4bf.png",
    ["1f4c0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4c0.png",
    ["1f4c1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4c1.png",
    ["1f4c2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4c2.png",
    ["1f4c3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4c3.png",
    ["1f4c4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4c4.png",
    ["1f4c5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4c5.png",
    ["1f4c6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4c6.png",
    ["1f4c7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4c7.png",
    ["1f4c8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4c8.png",
    ["1f4c9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4c9.png",
    ["1f4ca.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4ca.png",
    ["1f4cb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4cb.png",
    ["1f4cc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4cc.png",
    ["1f4cd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4cd.png",
    ["1f4ce.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4ce.png",
    ["1f4cf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4cf.png",
    ["1f4d0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4d0.png",
    ["1f4d1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4d1.png",
    ["1f4d2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4d2.png",
    ["1f4d3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4d3.png",
    ["1f4d4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4d4.png",
    ["1f4d5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4d5.png",
    ["1f4d6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4d6.png",
    ["1f4d7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4d7.png",
    ["1f4d8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4d8.png",
    ["1f4d9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4d9.png",
    ["1f4da.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4da.png",
    ["1f4db.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4db.png",
    ["1f4dc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4dc.png",
    ["1f4dd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4dd.png",
    ["1f4de.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4de.png",
    ["1f4df.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4df.png",
    ["1f4e0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4e0.png",
    ["1f4e1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4e1.png",
    ["1f4e2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4e2.png",
    ["1f4e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4e3.png",
    ["1f4e4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4e4.png",
    ["1f4e5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4e5.png",
    ["1f4e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4e6.png",
    ["1f4e7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4e7.png",
    ["1f4e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4e8.png",
    ["1f4e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4e9.png",
    ["1f4ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4ea.png",
    ["1f4eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4eb.png",
    ["1f4ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4ec.png",
    ["1f4ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4ed.png",
    ["1f4ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4ee.png",
    ["1f4ef.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4ef.png",
    ["1f4f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4f0.png",
    ["1f4f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4f1.png",
    ["1f4f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4f2.png",
    ["1f4f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4f3.png",
    ["1f4f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4f4.png",
    ["1f4f5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4f5.png",
    ["1f4f6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4f6.png",
    ["1f4f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4f7.png",
    ["1f4f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4f8.png",
    ["1f4f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4f9.png",
    ["1f4fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4fa.png",
    ["1f4fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4fb.png",
    ["1f4fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4fc.png",
    ["1f4fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4fd.png",
    ["1f4ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f4ff.png",
    ["1f500.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f500.png",
    ["1f501.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f501.png",
    ["1f502.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f502.png",
    ["1f503.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f503.png",
    ["1f504.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f504.png",
    ["1f505.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f505.png",
    ["1f506.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f506.png",
    ["1f507.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f507.png",
    ["1f508.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f508.png",
    ["1f509.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f509.png",
    ["1f50a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f50a.png",
    ["1f50b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f50b.png",
    ["1f50c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f50c.png",
    ["1f50d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f50d.png",
    ["1f50e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f50e.png",
    ["1f50f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f50f.png",
    ["1f510.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f510.png",
    ["1f511.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f511.png",
    ["1f512.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f512.png",
    ["1f513.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f513.png",
    ["1f514.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f514.png",
    ["1f515.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f515.png",
    ["1f516.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f516.png",
    ["1f517.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f517.png",
    ["1f518.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f518.png",
    ["1f519.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f519.png",
    ["1f51a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f51a.png",
    ["1f51b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f51b.png",
    ["1f51c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f51c.png",
    ["1f51d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f51d.png",
    ["1f51e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f51e.png",
    ["1f51f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f51f.png",
    ["1f520.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f520.png",
    ["1f521.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f521.png",
    ["1f522.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f522.png",
    ["1f523.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f523.png",
    ["1f524.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f524.png",
    ["1f525.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f525.png",
    ["1f526.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f526.png",
    ["1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f527.png",
    ["1f528.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f528.png",
    ["1f529.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f529.png",
    ["1f52a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f52a.png",
    ["1f52b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f52b.png",
    ["1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f52c.png",
    ["1f52d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f52d.png",
    ["1f52e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f52e.png",
    ["1f52f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f52f.png",
    ["1f530.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f530.png",
    ["1f531.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f531.png",
    ["1f532.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f532.png",
    ["1f533.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f533.png",
    ["1f534.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f534.png",
    ["1f535.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f535.png",
    ["1f536.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f536.png",
    ["1f537.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f537.png",
    ["1f538.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f538.png",
    ["1f539.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f539.png",
    ["1f53a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f53a.png",
    ["1f53b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f53b.png",
    ["1f53c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f53c.png",
    ["1f53d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f53d.png",
    ["1f549.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f549.png",
    ["1f54a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f54a.png",
    ["1f54b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f54b.png",
    ["1f54c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f54c.png",
    ["1f54d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f54d.png",
    ["1f54e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f54e.png",
    ["1f550.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f550.png",
    ["1f551.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f551.png",
    ["1f552.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f552.png",
    ["1f553.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f553.png",
    ["1f554.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f554.png",
    ["1f555.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f555.png",
    ["1f556.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f556.png",
    ["1f557.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f557.png",
    ["1f558.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f558.png",
    ["1f559.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f559.png",
    ["1f55a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f55a.png",
    ["1f55b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f55b.png",
    ["1f55c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f55c.png",
    ["1f55d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f55d.png",
    ["1f55e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f55e.png",
    ["1f55f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f55f.png",
    ["1f560.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f560.png",
    ["1f561.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f561.png",
    ["1f562.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f562.png",
    ["1f563.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f563.png",
    ["1f564.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f564.png",
    ["1f565.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f565.png",
    ["1f566.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f566.png",
    ["1f567.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f567.png",
    ["1f56f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f56f.png",
    ["1f570.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f570.png",
    ["1f573.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f573.png",
    ["1f574-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f574-1f3fb.png",
    ["1f574-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f574-1f3fc.png",
    ["1f574-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f574-1f3fd.png",
    ["1f574-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f574-1f3fe.png",
    ["1f574-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f574-1f3ff.png",
    ["1f574.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f574.png",
    ["1f575-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3fb-2640.png",
    ["1f575-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3fb-2642.png",
    ["1f575-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3fb.png",
    ["1f575-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3fc-2640.png",
    ["1f575-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3fc-2642.png",
    ["1f575-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3fc.png",
    ["1f575-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3fd-2640.png",
    ["1f575-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3fd-2642.png",
    ["1f575-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3fd.png",
    ["1f575-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3fe-2640.png",
    ["1f575-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3fe-2642.png",
    ["1f575-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3fe.png",
    ["1f575-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3ff-2640.png",
    ["1f575-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3ff-2642.png",
    ["1f575-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-1f3ff.png",
    ["1f575-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-2640.png",
    ["1f575-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575-2642.png",
    ["1f575.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f575.png",
    ["1f576.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f576.png",
    ["1f577.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f577.png",
    ["1f578.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f578.png",
    ["1f579.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f579.png",
    ["1f57a-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f57a-1f3fb.png",
    ["1f57a-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f57a-1f3fc.png",
    ["1f57a-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f57a-1f3fd.png",
    ["1f57a-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f57a-1f3fe.png",
    ["1f57a-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f57a-1f3ff.png",
    ["1f57a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f57a.png",
    ["1f587.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f587.png",
    ["1f58a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f58a.png",
    ["1f58b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f58b.png",
    ["1f58c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f58c.png",
    ["1f58d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f58d.png",
    ["1f590-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f590-1f3fb.png",
    ["1f590-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f590-1f3fc.png",
    ["1f590-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f590-1f3fd.png",
    ["1f590-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f590-1f3fe.png",
    ["1f590-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f590-1f3ff.png",
    ["1f590.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f590.png",
    ["1f595-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f595-1f3fb.png",
    ["1f595-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f595-1f3fc.png",
    ["1f595-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f595-1f3fd.png",
    ["1f595-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f595-1f3fe.png",
    ["1f595-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f595-1f3ff.png",
    ["1f595.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f595.png",
    ["1f596-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f596-1f3fb.png",
    ["1f596-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f596-1f3fc.png",
    ["1f596-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f596-1f3fd.png",
    ["1f596-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f596-1f3fe.png",
    ["1f596-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f596-1f3ff.png",
    ["1f596.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f596.png",
    ["1f5a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5a4.png",
    ["1f5a5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5a5.png",
    ["1f5a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5a8.png",
    ["1f5b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5b1.png",
    ["1f5b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5b2.png",
    ["1f5bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5bc.png",
    ["1f5c2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5c2.png",
    ["1f5c3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5c3.png",
    ["1f5c4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5c4.png",
    ["1f5d1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5d1.png",
    ["1f5d2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5d2.png",
    ["1f5d3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5d3.png",
    ["1f5dc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5dc.png",
    ["1f5dd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5dd.png",
    ["1f5de.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5de.png",
    ["1f5e1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5e1.png",
    ["1f5e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5e3.png",
    ["1f5e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5e8.png",
    ["1f5ef.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5ef.png",
    ["1f5f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5f3.png",
    ["1f5fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5fa.png",
    ["1f5fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5fb.png",
    ["1f5fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5fc.png",
    ["1f5fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5fd.png",
    ["1f5fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5fe.png",
    ["1f5ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f5ff.png",
    ["1f600.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f600.png",
    ["1f601.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f601.png",
    ["1f602.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f602.png",
    ["1f603.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f603.png",
    ["1f604.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f604.png",
    ["1f605.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f605.png",
    ["1f606.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f606.png",
    ["1f607.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f607.png",
    ["1f608.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f608.png",
    ["1f609.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f609.png",
    ["1f60a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f60a.png",
    ["1f60b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f60b.png",
    ["1f60c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f60c.png",
    ["1f60d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f60d.png",
    ["1f60e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f60e.png",
    ["1f60f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f60f.png",
    ["1f610.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f610.png",
    ["1f611.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f611.png",
    ["1f612.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f612.png",
    ["1f613.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f613.png",
    ["1f614.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f614.png",
    ["1f615.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f615.png",
    ["1f616.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f616.png",
    ["1f617.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f617.png",
    ["1f618.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f618.png",
    ["1f619.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f619.png",
    ["1f61a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f61a.png",
    ["1f61b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f61b.png",
    ["1f61c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f61c.png",
    ["1f61d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f61d.png",
    ["1f61e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f61e.png",
    ["1f61f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f61f.png",
    ["1f620.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f620.png",
    ["1f621.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f621.png",
    ["1f622.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f622.png",
    ["1f623.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f623.png",
    ["1f624.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f624.png",
    ["1f625.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f625.png",
    ["1f626.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f626.png",
    ["1f627.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f627.png",
    ["1f628.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f628.png",
    ["1f629.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f629.png",
    ["1f62a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f62a.png",
    ["1f62b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f62b.png",
    ["1f62c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f62c.png",
    ["1f62d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f62d.png",
    ["1f62e-1f4a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f62e-1f4a8.png",
    ["1f62e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f62e.png",
    ["1f62f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f62f.png",
    ["1f630.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f630.png",
    ["1f631.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f631.png",
    ["1f632.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f632.png",
    ["1f633.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f633.png",
    ["1f634.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f634.png",
    ["1f635-1f4ab.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f635-1f4ab.png",
    ["1f635.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f635.png",
    ["1f636-1f32b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f636-1f32b.png",
    ["1f636.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f636.png",
    ["1f637.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f637.png",
    ["1f638.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f638.png",
    ["1f639.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f639.png",
    ["1f63a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f63a.png",
    ["1f63b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f63b.png",
    ["1f63c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f63c.png",
    ["1f63d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f63d.png",
    ["1f63e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f63e.png",
    ["1f63f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f63f.png",
    ["1f640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f640.png",
    ["1f641.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f641.png",
    ["1f642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f642.png",
    ["1f643.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f643.png",
    ["1f644.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f644.png",
    ["1f645-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3fb-2640.png",
    ["1f645-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3fb-2642.png",
    ["1f645-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3fb.png",
    ["1f645-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3fc-2640.png",
    ["1f645-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3fc-2642.png",
    ["1f645-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3fc.png",
    ["1f645-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3fd-2640.png",
    ["1f645-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3fd-2642.png",
    ["1f645-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3fd.png",
    ["1f645-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3fe-2640.png",
    ["1f645-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3fe-2642.png",
    ["1f645-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3fe.png",
    ["1f645-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3ff-2640.png",
    ["1f645-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3ff-2642.png",
    ["1f645-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-1f3ff.png",
    ["1f645-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-2640.png",
    ["1f645-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645-2642.png",
    ["1f645.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f645.png",
    ["1f646-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3fb-2640.png",
    ["1f646-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3fb-2642.png",
    ["1f646-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3fb.png",
    ["1f646-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3fc-2640.png",
    ["1f646-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3fc-2642.png",
    ["1f646-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3fc.png",
    ["1f646-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3fd-2640.png",
    ["1f646-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3fd-2642.png",
    ["1f646-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3fd.png",
    ["1f646-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3fe-2640.png",
    ["1f646-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3fe-2642.png",
    ["1f646-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3fe.png",
    ["1f646-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3ff-2640.png",
    ["1f646-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3ff-2642.png",
    ["1f646-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-1f3ff.png",
    ["1f646-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-2640.png",
    ["1f646-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646-2642.png",
    ["1f646.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f646.png",
    ["1f647-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3fb-2640.png",
    ["1f647-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3fb-2642.png",
    ["1f647-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3fb.png",
    ["1f647-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3fc-2640.png",
    ["1f647-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3fc-2642.png",
    ["1f647-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3fc.png",
    ["1f647-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3fd-2640.png",
    ["1f647-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3fd-2642.png",
    ["1f647-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3fd.png",
    ["1f647-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3fe-2640.png",
    ["1f647-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3fe-2642.png",
    ["1f647-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3fe.png",
    ["1f647-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3ff-2640.png",
    ["1f647-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3ff-2642.png",
    ["1f647-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-1f3ff.png",
    ["1f647-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-2640.png",
    ["1f647-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647-2642.png",
    ["1f647.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f647.png",
    ["1f648.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f648.png",
    ["1f649.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f649.png",
    ["1f64a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64a.png",
    ["1f64b-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3fb-2640.png",
    ["1f64b-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3fb-2642.png",
    ["1f64b-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3fb.png",
    ["1f64b-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3fc-2640.png",
    ["1f64b-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3fc-2642.png",
    ["1f64b-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3fc.png",
    ["1f64b-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3fd-2640.png",
    ["1f64b-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3fd-2642.png",
    ["1f64b-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3fd.png",
    ["1f64b-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3fe-2640.png",
    ["1f64b-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3fe-2642.png",
    ["1f64b-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3fe.png",
    ["1f64b-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3ff-2640.png",
    ["1f64b-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3ff-2642.png",
    ["1f64b-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-1f3ff.png",
    ["1f64b-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-2640.png",
    ["1f64b-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b-2642.png",
    ["1f64b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64b.png",
    ["1f64c-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64c-1f3fb.png",
    ["1f64c-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64c-1f3fc.png",
    ["1f64c-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64c-1f3fd.png",
    ["1f64c-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64c-1f3fe.png",
    ["1f64c-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64c-1f3ff.png",
    ["1f64c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64c.png",
    ["1f64d-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3fb-2640.png",
    ["1f64d-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3fb-2642.png",
    ["1f64d-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3fb.png",
    ["1f64d-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3fc-2640.png",
    ["1f64d-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3fc-2642.png",
    ["1f64d-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3fc.png",
    ["1f64d-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3fd-2640.png",
    ["1f64d-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3fd-2642.png",
    ["1f64d-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3fd.png",
    ["1f64d-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3fe-2640.png",
    ["1f64d-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3fe-2642.png",
    ["1f64d-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3fe.png",
    ["1f64d-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3ff-2640.png",
    ["1f64d-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3ff-2642.png",
    ["1f64d-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-1f3ff.png",
    ["1f64d-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-2640.png",
    ["1f64d-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d-2642.png",
    ["1f64d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64d.png",
    ["1f64e-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3fb-2640.png",
    ["1f64e-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3fb-2642.png",
    ["1f64e-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3fb.png",
    ["1f64e-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3fc-2640.png",
    ["1f64e-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3fc-2642.png",
    ["1f64e-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3fc.png",
    ["1f64e-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3fd-2640.png",
    ["1f64e-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3fd-2642.png",
    ["1f64e-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3fd.png",
    ["1f64e-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3fe-2640.png",
    ["1f64e-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3fe-2642.png",
    ["1f64e-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3fe.png",
    ["1f64e-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3ff-2640.png",
    ["1f64e-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3ff-2642.png",
    ["1f64e-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-1f3ff.png",
    ["1f64e-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-2640.png",
    ["1f64e-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e-2642.png",
    ["1f64e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64e.png",
    ["1f64f-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64f-1f3fb.png",
    ["1f64f-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64f-1f3fc.png",
    ["1f64f-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64f-1f3fd.png",
    ["1f64f-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64f-1f3fe.png",
    ["1f64f-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64f-1f3ff.png",
    ["1f64f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f64f.png",
    ["1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f680.png",
    ["1f681.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f681.png",
    ["1f682.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f682.png",
    ["1f683.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f683.png",
    ["1f684.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f684.png",
    ["1f685.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f685.png",
    ["1f686.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f686.png",
    ["1f687.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f687.png",
    ["1f688.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f688.png",
    ["1f689.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f689.png",
    ["1f68a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f68a.png",
    ["1f68b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f68b.png",
    ["1f68c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f68c.png",
    ["1f68d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f68d.png",
    ["1f68e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f68e.png",
    ["1f68f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f68f.png",
    ["1f690.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f690.png",
    ["1f691.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f691.png",
    ["1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f692.png",
    ["1f693.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f693.png",
    ["1f694.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f694.png",
    ["1f695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f695.png",
    ["1f696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f696.png",
    ["1f697.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f697.png",
    ["1f698.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f698.png",
    ["1f699.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f699.png",
    ["1f69a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f69a.png",
    ["1f69b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f69b.png",
    ["1f69c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f69c.png",
    ["1f69d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f69d.png",
    ["1f69e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f69e.png",
    ["1f69f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f69f.png",
    ["1f6a0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a0.png",
    ["1f6a1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a1.png",
    ["1f6a2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a2.png",
    ["1f6a3-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3fb-2640.png",
    ["1f6a3-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3fb-2642.png",
    ["1f6a3-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3fb.png",
    ["1f6a3-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3fc-2640.png",
    ["1f6a3-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3fc-2642.png",
    ["1f6a3-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3fc.png",
    ["1f6a3-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3fd-2640.png",
    ["1f6a3-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3fd-2642.png",
    ["1f6a3-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3fd.png",
    ["1f6a3-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3fe-2640.png",
    ["1f6a3-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3fe-2642.png",
    ["1f6a3-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3fe.png",
    ["1f6a3-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3ff-2640.png",
    ["1f6a3-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3ff-2642.png",
    ["1f6a3-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-1f3ff.png",
    ["1f6a3-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-2640.png",
    ["1f6a3-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3-2642.png",
    ["1f6a3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a3.png",
    ["1f6a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a4.png",
    ["1f6a5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a5.png",
    ["1f6a6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a6.png",
    ["1f6a7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a7.png",
    ["1f6a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a8.png",
    ["1f6a9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6a9.png",
    ["1f6aa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6aa.png",
    ["1f6ab.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6ab.png",
    ["1f6ac.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6ac.png",
    ["1f6ad.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6ad.png",
    ["1f6ae.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6ae.png",
    ["1f6af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6af.png",
    ["1f6b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b0.png",
    ["1f6b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b1.png",
    ["1f6b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b2.png",
    ["1f6b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b3.png",
    ["1f6b4-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3fb-2640.png",
    ["1f6b4-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3fb-2642.png",
    ["1f6b4-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3fb.png",
    ["1f6b4-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3fc-2640.png",
    ["1f6b4-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3fc-2642.png",
    ["1f6b4-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3fc.png",
    ["1f6b4-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3fd-2640.png",
    ["1f6b4-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3fd-2642.png",
    ["1f6b4-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3fd.png",
    ["1f6b4-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3fe-2640.png",
    ["1f6b4-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3fe-2642.png",
    ["1f6b4-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3fe.png",
    ["1f6b4-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3ff-2640.png",
    ["1f6b4-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3ff-2642.png",
    ["1f6b4-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-1f3ff.png",
    ["1f6b4-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-2640.png",
    ["1f6b4-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4-2642.png",
    ["1f6b4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b4.png",
    ["1f6b5-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3fb-2640.png",
    ["1f6b5-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3fb-2642.png",
    ["1f6b5-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3fb.png",
    ["1f6b5-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3fc-2640.png",
    ["1f6b5-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3fc-2642.png",
    ["1f6b5-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3fc.png",
    ["1f6b5-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3fd-2640.png",
    ["1f6b5-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3fd-2642.png",
    ["1f6b5-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3fd.png",
    ["1f6b5-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3fe-2640.png",
    ["1f6b5-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3fe-2642.png",
    ["1f6b5-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3fe.png",
    ["1f6b5-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3ff-2640.png",
    ["1f6b5-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3ff-2642.png",
    ["1f6b5-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-1f3ff.png",
    ["1f6b5-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-2640.png",
    ["1f6b5-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5-2642.png",
    ["1f6b5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b5.png",
    ["1f6b6-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3fb-2640.png",
    ["1f6b6-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3fb-2642.png",
    ["1f6b6-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3fb.png",
    ["1f6b6-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3fc-2640.png",
    ["1f6b6-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3fc-2642.png",
    ["1f6b6-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3fc.png",
    ["1f6b6-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3fd-2640.png",
    ["1f6b6-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3fd-2642.png",
    ["1f6b6-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3fd.png",
    ["1f6b6-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3fe-2640.png",
    ["1f6b6-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3fe-2642.png",
    ["1f6b6-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3fe.png",
    ["1f6b6-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3ff-2640.png",
    ["1f6b6-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3ff-2642.png",
    ["1f6b6-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-1f3ff.png",
    ["1f6b6-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-2640.png",
    ["1f6b6-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6-2642.png",
    ["1f6b6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b6.png",
    ["1f6b7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b7.png",
    ["1f6b8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b8.png",
    ["1f6b9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6b9.png",
    ["1f6ba.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6ba.png",
    ["1f6bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6bb.png",
    ["1f6bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6bc.png",
    ["1f6bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6bd.png",
    ["1f6be.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6be.png",
    ["1f6bf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6bf.png",
    ["1f6c0-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6c0-1f3fb.png",
    ["1f6c0-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6c0-1f3fc.png",
    ["1f6c0-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6c0-1f3fd.png",
    ["1f6c0-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6c0-1f3fe.png",
    ["1f6c0-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6c0-1f3ff.png",
    ["1f6c0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6c0.png",
    ["1f6c1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6c1.png",
    ["1f6c2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6c2.png",
    ["1f6c3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6c3.png",
    ["1f6c4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6c4.png",
    ["1f6c5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6c5.png",
    ["1f6cb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6cb.png",
    ["1f6cc-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6cc-1f3fb.png",
    ["1f6cc-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6cc-1f3fc.png",
    ["1f6cc-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6cc-1f3fd.png",
    ["1f6cc-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6cc-1f3fe.png",
    ["1f6cc-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6cc-1f3ff.png",
    ["1f6cc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6cc.png",
    ["1f6cd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6cd.png",
    ["1f6ce.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6ce.png",
    ["1f6cf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6cf.png",
    ["1f6d0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6d0.png",
    ["1f6d1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6d1.png",
    ["1f6d2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6d2.png",
    ["1f6d5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6d5.png",
    ["1f6d6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6d6.png",
    ["1f6d7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6d7.png",
    ["1f6e0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6e0.png",
    ["1f6e1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6e1.png",
    ["1f6e2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6e2.png",
    ["1f6e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6e3.png",
    ["1f6e4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6e4.png",
    ["1f6e5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6e5.png",
    ["1f6e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6e9.png",
    ["1f6eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6eb.png",
    ["1f6ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6ec.png",
    ["1f6f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6f0.png",
    ["1f6f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6f3.png",
    ["1f6f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6f4.png",
    ["1f6f5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6f5.png",
    ["1f6f6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6f6.png",
    ["1f6f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6f7.png",
    ["1f6f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6f8.png",
    ["1f6f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6f9.png",
    ["1f6fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6fa.png",
    ["1f6fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6fb.png",
    ["1f6fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f6fc.png",
    ["1f7e0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f7e0.png",
    ["1f7e1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f7e1.png",
    ["1f7e2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f7e2.png",
    ["1f7e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f7e3.png",
    ["1f7e4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f7e4.png",
    ["1f7e5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f7e5.png",
    ["1f7e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f7e6.png",
    ["1f7e7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f7e7.png",
    ["1f7e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f7e8.png",
    ["1f7e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f7e9.png",
    ["1f7ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f7ea.png",
    ["1f7eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f7eb.png",
    ["1f90c-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f90c-1f3fb.png",
    ["1f90c-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f90c-1f3fc.png",
    ["1f90c-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f90c-1f3fd.png",
    ["1f90c-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f90c-1f3fe.png",
    ["1f90c-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f90c-1f3ff.png",
    ["1f90c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f90c.png",
    ["1f90d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f90d.png",
    ["1f90e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f90e.png",
    ["1f90f-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f90f-1f3fb.png",
    ["1f90f-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f90f-1f3fc.png",
    ["1f90f-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f90f-1f3fd.png",
    ["1f90f-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f90f-1f3fe.png",
    ["1f90f-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f90f-1f3ff.png",
    ["1f90f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f90f.png",
    ["1f910.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f910.png",
    ["1f911.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f911.png",
    ["1f912.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f912.png",
    ["1f913.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f913.png",
    ["1f914.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f914.png",
    ["1f915.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f915.png",
    ["1f916.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f916.png",
    ["1f917.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f917.png",
    ["1f918-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f918-1f3fb.png",
    ["1f918-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f918-1f3fc.png",
    ["1f918-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f918-1f3fd.png",
    ["1f918-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f918-1f3fe.png",
    ["1f918-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f918-1f3ff.png",
    ["1f918.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f918.png",
    ["1f919-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f919-1f3fb.png",
    ["1f919-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f919-1f3fc.png",
    ["1f919-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f919-1f3fd.png",
    ["1f919-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f919-1f3fe.png",
    ["1f919-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f919-1f3ff.png",
    ["1f919.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f919.png",
    ["1f91a-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91a-1f3fb.png",
    ["1f91a-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91a-1f3fc.png",
    ["1f91a-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91a-1f3fd.png",
    ["1f91a-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91a-1f3fe.png",
    ["1f91a-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91a-1f3ff.png",
    ["1f91a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91a.png",
    ["1f91b-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91b-1f3fb.png",
    ["1f91b-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91b-1f3fc.png",
    ["1f91b-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91b-1f3fd.png",
    ["1f91b-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91b-1f3fe.png",
    ["1f91b-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91b-1f3ff.png",
    ["1f91b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91b.png",
    ["1f91c-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91c-1f3fb.png",
    ["1f91c-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91c-1f3fc.png",
    ["1f91c-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91c-1f3fd.png",
    ["1f91c-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91c-1f3fe.png",
    ["1f91c-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91c-1f3ff.png",
    ["1f91c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91c.png",
    ["1f91d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91d.png",
    ["1f91e-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91e-1f3fb.png",
    ["1f91e-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91e-1f3fc.png",
    ["1f91e-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91e-1f3fd.png",
    ["1f91e-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91e-1f3fe.png",
    ["1f91e-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91e-1f3ff.png",
    ["1f91e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91e.png",
    ["1f91f-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91f-1f3fb.png",
    ["1f91f-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91f-1f3fc.png",
    ["1f91f-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91f-1f3fd.png",
    ["1f91f-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91f-1f3fe.png",
    ["1f91f-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91f-1f3ff.png",
    ["1f91f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f91f.png",
    ["1f920.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f920.png",
    ["1f921.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f921.png",
    ["1f922.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f922.png",
    ["1f923.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f923.png",
    ["1f924.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f924.png",
    ["1f925.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f925.png",
    ["1f926-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3fb-2640.png",
    ["1f926-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3fb-2642.png",
    ["1f926-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3fb.png",
    ["1f926-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3fc-2640.png",
    ["1f926-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3fc-2642.png",
    ["1f926-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3fc.png",
    ["1f926-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3fd-2640.png",
    ["1f926-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3fd-2642.png",
    ["1f926-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3fd.png",
    ["1f926-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3fe-2640.png",
    ["1f926-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3fe-2642.png",
    ["1f926-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3fe.png",
    ["1f926-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3ff-2640.png",
    ["1f926-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3ff-2642.png",
    ["1f926-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-1f3ff.png",
    ["1f926-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-2640.png",
    ["1f926-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926-2642.png",
    ["1f926.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f926.png",
    ["1f927.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f927.png",
    ["1f928.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f928.png",
    ["1f929.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f929.png",
    ["1f92a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f92a.png",
    ["1f92b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f92b.png",
    ["1f92c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f92c.png",
    ["1f92d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f92d.png",
    ["1f92e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f92e.png",
    ["1f92f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f92f.png",
    ["1f930-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f930-1f3fb.png",
    ["1f930-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f930-1f3fc.png",
    ["1f930-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f930-1f3fd.png",
    ["1f930-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f930-1f3fe.png",
    ["1f930-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f930-1f3ff.png",
    ["1f930.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f930.png",
    ["1f931-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f931-1f3fb.png",
    ["1f931-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f931-1f3fc.png",
    ["1f931-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f931-1f3fd.png",
    ["1f931-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f931-1f3fe.png",
    ["1f931-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f931-1f3ff.png",
    ["1f931.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f931.png",
    ["1f932-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f932-1f3fb.png",
    ["1f932-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f932-1f3fc.png",
    ["1f932-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f932-1f3fd.png",
    ["1f932-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f932-1f3fe.png",
    ["1f932-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f932-1f3ff.png",
    ["1f932.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f932.png",
    ["1f933-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f933-1f3fb.png",
    ["1f933-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f933-1f3fc.png",
    ["1f933-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f933-1f3fd.png",
    ["1f933-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f933-1f3fe.png",
    ["1f933-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f933-1f3ff.png",
    ["1f933.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f933.png",
    ["1f934-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f934-1f3fb.png",
    ["1f934-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f934-1f3fc.png",
    ["1f934-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f934-1f3fd.png",
    ["1f934-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f934-1f3fe.png",
    ["1f934-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f934-1f3ff.png",
    ["1f934.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f934.png",
    ["1f935-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3fb-2640.png",
    ["1f935-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3fb-2642.png",
    ["1f935-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3fb.png",
    ["1f935-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3fc-2640.png",
    ["1f935-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3fc-2642.png",
    ["1f935-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3fc.png",
    ["1f935-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3fd-2640.png",
    ["1f935-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3fd-2642.png",
    ["1f935-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3fd.png",
    ["1f935-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3fe-2640.png",
    ["1f935-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3fe-2642.png",
    ["1f935-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3fe.png",
    ["1f935-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3ff-2640.png",
    ["1f935-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3ff-2642.png",
    ["1f935-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-1f3ff.png",
    ["1f935-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-2640.png",
    ["1f935-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935-2642.png",
    ["1f935.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f935.png",
    ["1f936-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f936-1f3fb.png",
    ["1f936-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f936-1f3fc.png",
    ["1f936-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f936-1f3fd.png",
    ["1f936-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f936-1f3fe.png",
    ["1f936-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f936-1f3ff.png",
    ["1f936.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f936.png",
    ["1f937-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3fb-2640.png",
    ["1f937-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3fb-2642.png",
    ["1f937-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3fb.png",
    ["1f937-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3fc-2640.png",
    ["1f937-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3fc-2642.png",
    ["1f937-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3fc.png",
    ["1f937-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3fd-2640.png",
    ["1f937-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3fd-2642.png",
    ["1f937-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3fd.png",
    ["1f937-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3fe-2640.png",
    ["1f937-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3fe-2642.png",
    ["1f937-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3fe.png",
    ["1f937-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3ff-2640.png",
    ["1f937-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3ff-2642.png",
    ["1f937-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-1f3ff.png",
    ["1f937-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-2640.png",
    ["1f937-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937-2642.png",
    ["1f937.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f937.png",
    ["1f938-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3fb-2640.png",
    ["1f938-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3fb-2642.png",
    ["1f938-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3fb.png",
    ["1f938-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3fc-2640.png",
    ["1f938-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3fc-2642.png",
    ["1f938-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3fc.png",
    ["1f938-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3fd-2640.png",
    ["1f938-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3fd-2642.png",
    ["1f938-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3fd.png",
    ["1f938-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3fe-2640.png",
    ["1f938-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3fe-2642.png",
    ["1f938-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3fe.png",
    ["1f938-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3ff-2640.png",
    ["1f938-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3ff-2642.png",
    ["1f938-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-1f3ff.png",
    ["1f938-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-2640.png",
    ["1f938-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938-2642.png",
    ["1f938.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f938.png",
    ["1f939-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3fb-2640.png",
    ["1f939-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3fb-2642.png",
    ["1f939-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3fb.png",
    ["1f939-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3fc-2640.png",
    ["1f939-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3fc-2642.png",
    ["1f939-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3fc.png",
    ["1f939-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3fd-2640.png",
    ["1f939-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3fd-2642.png",
    ["1f939-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3fd.png",
    ["1f939-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3fe-2640.png",
    ["1f939-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3fe-2642.png",
    ["1f939-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3fe.png",
    ["1f939-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3ff-2640.png",
    ["1f939-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3ff-2642.png",
    ["1f939-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-1f3ff.png",
    ["1f939-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-2640.png",
    ["1f939-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939-2642.png",
    ["1f939.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f939.png",
    ["1f93a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93a.png",
    ["1f93c-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93c-2640.png",
    ["1f93c-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93c-2642.png",
    ["1f93c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93c.png",
    ["1f93d-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3fb-2640.png",
    ["1f93d-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3fb-2642.png",
    ["1f93d-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3fb.png",
    ["1f93d-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3fc-2640.png",
    ["1f93d-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3fc-2642.png",
    ["1f93d-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3fc.png",
    ["1f93d-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3fd-2640.png",
    ["1f93d-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3fd-2642.png",
    ["1f93d-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3fd.png",
    ["1f93d-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3fe-2640.png",
    ["1f93d-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3fe-2642.png",
    ["1f93d-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3fe.png",
    ["1f93d-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3ff-2640.png",
    ["1f93d-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3ff-2642.png",
    ["1f93d-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-1f3ff.png",
    ["1f93d-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-2640.png",
    ["1f93d-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d-2642.png",
    ["1f93d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93d.png",
    ["1f93e-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3fb-2640.png",
    ["1f93e-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3fb-2642.png",
    ["1f93e-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3fb.png",
    ["1f93e-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3fc-2640.png",
    ["1f93e-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3fc-2642.png",
    ["1f93e-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3fc.png",
    ["1f93e-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3fd-2640.png",
    ["1f93e-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3fd-2642.png",
    ["1f93e-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3fd.png",
    ["1f93e-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3fe-2640.png",
    ["1f93e-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3fe-2642.png",
    ["1f93e-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3fe.png",
    ["1f93e-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3ff-2640.png",
    ["1f93e-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3ff-2642.png",
    ["1f93e-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-1f3ff.png",
    ["1f93e-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-2640.png",
    ["1f93e-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e-2642.png",
    ["1f93e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93e.png",
    ["1f93f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f93f.png",
    ["1f940.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f940.png",
    ["1f941.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f941.png",
    ["1f942.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f942.png",
    ["1f943.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f943.png",
    ["1f944.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f944.png",
    ["1f945.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f945.png",
    ["1f947.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f947.png",
    ["1f948.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f948.png",
    ["1f949.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f949.png",
    ["1f94a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f94a.png",
    ["1f94b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f94b.png",
    ["1f94c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f94c.png",
    ["1f94d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f94d.png",
    ["1f94e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f94e.png",
    ["1f94f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f94f.png",
    ["1f950.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f950.png",
    ["1f951.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f951.png",
    ["1f952.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f952.png",
    ["1f953.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f953.png",
    ["1f954.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f954.png",
    ["1f955.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f955.png",
    ["1f956.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f956.png",
    ["1f957.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f957.png",
    ["1f958.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f958.png",
    ["1f959.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f959.png",
    ["1f95a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f95a.png",
    ["1f95b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f95b.png",
    ["1f95c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f95c.png",
    ["1f95d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f95d.png",
    ["1f95e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f95e.png",
    ["1f95f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f95f.png",
    ["1f960.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f960.png",
    ["1f961.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f961.png",
    ["1f962.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f962.png",
    ["1f963.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f963.png",
    ["1f964.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f964.png",
    ["1f965.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f965.png",
    ["1f966.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f966.png",
    ["1f967.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f967.png",
    ["1f968.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f968.png",
    ["1f969.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f969.png",
    ["1f96a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f96a.png",
    ["1f96b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f96b.png",
    ["1f96c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f96c.png",
    ["1f96d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f96d.png",
    ["1f96e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f96e.png",
    ["1f96f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f96f.png",
    ["1f970.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f970.png",
    ["1f971.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f971.png",
    ["1f972.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f972.png",
    ["1f973.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f973.png",
    ["1f974.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f974.png",
    ["1f975.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f975.png",
    ["1f976.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f976.png",
    ["1f977-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f977-1f3fb.png",
    ["1f977-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f977-1f3fc.png",
    ["1f977-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f977-1f3fd.png",
    ["1f977-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f977-1f3fe.png",
    ["1f977-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f977-1f3ff.png",
    ["1f977.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f977.png",
    ["1f978.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f978.png",
    ["1f97a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f97a.png",
    ["1f97b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f97b.png",
    ["1f97c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f97c.png",
    ["1f97d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f97d.png",
    ["1f97e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f97e.png",
    ["1f97f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f97f.png",
    ["1f980.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f980.png",
    ["1f981.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f981.png",
    ["1f982.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f982.png",
    ["1f983.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f983.png",
    ["1f984.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f984.png",
    ["1f985.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f985.png",
    ["1f986.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f986.png",
    ["1f987.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f987.png",
    ["1f988.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f988.png",
    ["1f989.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f989.png",
    ["1f98a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f98a.png",
    ["1f98b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f98b.png",
    ["1f98c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f98c.png",
    ["1f98d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f98d.png",
    ["1f98e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f98e.png",
    ["1f98f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f98f.png",
    ["1f990.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f990.png",
    ["1f991.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f991.png",
    ["1f992.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f992.png",
    ["1f993.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f993.png",
    ["1f994.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f994.png",
    ["1f995.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f995.png",
    ["1f996.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f996.png",
    ["1f997.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f997.png",
    ["1f998.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f998.png",
    ["1f999.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f999.png",
    ["1f99a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f99a.png",
    ["1f99b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f99b.png",
    ["1f99c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f99c.png",
    ["1f99d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f99d.png",
    ["1f99e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f99e.png",
    ["1f99f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f99f.png",
    ["1f9a0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9a0.png",
    ["1f9a1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9a1.png",
    ["1f9a2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9a2.png",
    ["1f9a3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9a3.png",
    ["1f9a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9a4.png",
    ["1f9a5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9a5.png",
    ["1f9a6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9a6.png",
    ["1f9a7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9a7.png",
    ["1f9a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9a8.png",
    ["1f9a9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9a9.png",
    ["1f9aa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9aa.png",
    ["1f9ab.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ab.png",
    ["1f9ac.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ac.png",
    ["1f9ad.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ad.png",
    ["1f9ae.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ae.png",
    ["1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9af.png",
    ["1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b0.png",
    ["1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b1.png",
    ["1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b2.png",
    ["1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b3.png",
    ["1f9b4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b4.png",
    ["1f9b5-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b5-1f3fb.png",
    ["1f9b5-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b5-1f3fc.png",
    ["1f9b5-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b5-1f3fd.png",
    ["1f9b5-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b5-1f3fe.png",
    ["1f9b5-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b5-1f3ff.png",
    ["1f9b5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b5.png",
    ["1f9b6-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b6-1f3fb.png",
    ["1f9b6-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b6-1f3fc.png",
    ["1f9b6-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b6-1f3fd.png",
    ["1f9b6-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b6-1f3fe.png",
    ["1f9b6-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b6-1f3ff.png",
    ["1f9b6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b6.png",
    ["1f9b7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b7.png",
    ["1f9b8-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3fb-2640.png",
    ["1f9b8-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3fb-2642.png",
    ["1f9b8-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3fb.png",
    ["1f9b8-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3fc-2640.png",
    ["1f9b8-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3fc-2642.png",
    ["1f9b8-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3fc.png",
    ["1f9b8-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3fd-2640.png",
    ["1f9b8-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3fd-2642.png",
    ["1f9b8-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3fd.png",
    ["1f9b8-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3fe-2640.png",
    ["1f9b8-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3fe-2642.png",
    ["1f9b8-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3fe.png",
    ["1f9b8-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3ff-2640.png",
    ["1f9b8-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3ff-2642.png",
    ["1f9b8-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-1f3ff.png",
    ["1f9b8-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-2640.png",
    ["1f9b8-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8-2642.png",
    ["1f9b8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b8.png",
    ["1f9b9-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3fb-2640.png",
    ["1f9b9-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3fb-2642.png",
    ["1f9b9-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3fb.png",
    ["1f9b9-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3fc-2640.png",
    ["1f9b9-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3fc-2642.png",
    ["1f9b9-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3fc.png",
    ["1f9b9-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3fd-2640.png",
    ["1f9b9-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3fd-2642.png",
    ["1f9b9-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3fd.png",
    ["1f9b9-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3fe-2640.png",
    ["1f9b9-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3fe-2642.png",
    ["1f9b9-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3fe.png",
    ["1f9b9-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3ff-2640.png",
    ["1f9b9-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3ff-2642.png",
    ["1f9b9-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-1f3ff.png",
    ["1f9b9-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-2640.png",
    ["1f9b9-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9-2642.png",
    ["1f9b9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9b9.png",
    ["1f9ba.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ba.png",
    ["1f9bb-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9bb-1f3fb.png",
    ["1f9bb-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9bb-1f3fc.png",
    ["1f9bb-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9bb-1f3fd.png",
    ["1f9bb-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9bb-1f3fe.png",
    ["1f9bb-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9bb-1f3ff.png",
    ["1f9bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9bb.png",
    ["1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9bc.png",
    ["1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9bd.png",
    ["1f9be.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9be.png",
    ["1f9bf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9bf.png",
    ["1f9c0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9c0.png",
    ["1f9c1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9c1.png",
    ["1f9c2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9c2.png",
    ["1f9c3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9c3.png",
    ["1f9c4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9c4.png",
    ["1f9c5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9c5.png",
    ["1f9c6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9c6.png",
    ["1f9c7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9c7.png",
    ["1f9c8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9c8.png",
    ["1f9c9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9c9.png",
    ["1f9ca.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ca.png",
    ["1f9cb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cb.png",
    ["1f9cd-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3fb-2640.png",
    ["1f9cd-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3fb-2642.png",
    ["1f9cd-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3fb.png",
    ["1f9cd-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3fc-2640.png",
    ["1f9cd-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3fc-2642.png",
    ["1f9cd-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3fc.png",
    ["1f9cd-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3fd-2640.png",
    ["1f9cd-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3fd-2642.png",
    ["1f9cd-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3fd.png",
    ["1f9cd-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3fe-2640.png",
    ["1f9cd-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3fe-2642.png",
    ["1f9cd-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3fe.png",
    ["1f9cd-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3ff-2640.png",
    ["1f9cd-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3ff-2642.png",
    ["1f9cd-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-1f3ff.png",
    ["1f9cd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-2640.png",
    ["1f9cd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd-2642.png",
    ["1f9cd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cd.png",
    ["1f9ce-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3fb-2640.png",
    ["1f9ce-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3fb-2642.png",
    ["1f9ce-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3fb.png",
    ["1f9ce-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3fc-2640.png",
    ["1f9ce-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3fc-2642.png",
    ["1f9ce-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3fc.png",
    ["1f9ce-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3fd-2640.png",
    ["1f9ce-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3fd-2642.png",
    ["1f9ce-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3fd.png",
    ["1f9ce-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3fe-2640.png",
    ["1f9ce-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3fe-2642.png",
    ["1f9ce-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3fe.png",
    ["1f9ce-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3ff-2640.png",
    ["1f9ce-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3ff-2642.png",
    ["1f9ce-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-1f3ff.png",
    ["1f9ce-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-2640.png",
    ["1f9ce-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce-2642.png",
    ["1f9ce.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ce.png",
    ["1f9cf-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3fb-2640.png",
    ["1f9cf-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3fb-2642.png",
    ["1f9cf-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3fb.png",
    ["1f9cf-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3fc-2640.png",
    ["1f9cf-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3fc-2642.png",
    ["1f9cf-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3fc.png",
    ["1f9cf-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3fd-2640.png",
    ["1f9cf-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3fd-2642.png",
    ["1f9cf-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3fd.png",
    ["1f9cf-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3fe-2640.png",
    ["1f9cf-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3fe-2642.png",
    ["1f9cf-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3fe.png",
    ["1f9cf-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3ff-2640.png",
    ["1f9cf-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3ff-2642.png",
    ["1f9cf-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-1f3ff.png",
    ["1f9cf-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-2640.png",
    ["1f9cf-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf-2642.png",
    ["1f9cf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9cf.png",
    ["1f9d0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d0.png",
    ["1f9d1-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f33e.png",
    ["1f9d1-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f373.png",
    ["1f9d1-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f37c.png",
    ["1f9d1-1f384.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f384.png",
    ["1f9d1-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f393.png",
    ["1f9d1-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3a4.png",
    ["1f9d1-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3a8.png",
    ["1f9d1-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3eb.png",
    ["1f9d1-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ed.png",
    ["1f9d1-1f3fb-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f33e.png",
    ["1f9d1-1f3fb-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f373.png",
    ["1f9d1-1f3fb-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f37c.png",
    ["1f9d1-1f3fb-1f384.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f384.png",
    ["1f9d1-1f3fb-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f393.png",
    ["1f9d1-1f3fb-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f3a4.png",
    ["1f9d1-1f3fb-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f3a8.png",
    ["1f9d1-1f3fb-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f3eb.png",
    ["1f9d1-1f3fb-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f3ed.png",
    ["1f9d1-1f3fb-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f4bb.png",
    ["1f9d1-1f3fb-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f4bc.png",
    ["1f9d1-1f3fb-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f527.png",
    ["1f9d1-1f3fb-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f52c.png",
    ["1f9d1-1f3fb-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f680.png",
    ["1f9d1-1f3fb-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f692.png",
    ["1f9d1-1f3fb-1f91d-1f9d1-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f91d-1f9d1-1f3fb.png",
    ["1f9d1-1f3fb-1f91d-1f9d1-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f91d-1f9d1-1f3fc.png",
    ["1f9d1-1f3fb-1f91d-1f9d1-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f91d-1f9d1-1f3fd.png",
    ["1f9d1-1f3fb-1f91d-1f9d1-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f91d-1f9d1-1f3fe.png",
    ["1f9d1-1f3fb-1f91d-1f9d1-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f91d-1f9d1-1f3ff.png",
    ["1f9d1-1f3fb-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f9af.png",
    ["1f9d1-1f3fb-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f9b0.png",
    ["1f9d1-1f3fb-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f9b1.png",
    ["1f9d1-1f3fb-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f9b2.png",
    ["1f9d1-1f3fb-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f9b3.png",
    ["1f9d1-1f3fb-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f9bc.png",
    ["1f9d1-1f3fb-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-1f9bd.png",
    ["1f9d1-1f3fb-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-2695.png",
    ["1f9d1-1f3fb-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-2696.png",
    ["1f9d1-1f3fb-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-2708.png",
    ["1f9d1-1f3fb-2764-1f48b-1f9d1-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-2764-1f48b-1f9d1-1f3fc.png",
    ["1f9d1-1f3fb-2764-1f48b-1f9d1-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-2764-1f48b-1f9d1-1f3fd.png",
    ["1f9d1-1f3fb-2764-1f48b-1f9d1-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-2764-1f48b-1f9d1-1f3fe.png",
    ["1f9d1-1f3fb-2764-1f48b-1f9d1-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-2764-1f48b-1f9d1-1f3ff.png",
    ["1f9d1-1f3fb-2764-1f9d1-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-2764-1f9d1-1f3fc.png",
    ["1f9d1-1f3fb-2764-1f9d1-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-2764-1f9d1-1f3fd.png",
    ["1f9d1-1f3fb-2764-1f9d1-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-2764-1f9d1-1f3fe.png",
    ["1f9d1-1f3fb-2764-1f9d1-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb-2764-1f9d1-1f3ff.png",
    ["1f9d1-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fb.png",
    ["1f9d1-1f3fc-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f33e.png",
    ["1f9d1-1f3fc-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f373.png",
    ["1f9d1-1f3fc-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f37c.png",
    ["1f9d1-1f3fc-1f384.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f384.png",
    ["1f9d1-1f3fc-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f393.png",
    ["1f9d1-1f3fc-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f3a4.png",
    ["1f9d1-1f3fc-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f3a8.png",
    ["1f9d1-1f3fc-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f3eb.png",
    ["1f9d1-1f3fc-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f3ed.png",
    ["1f9d1-1f3fc-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f4bb.png",
    ["1f9d1-1f3fc-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f4bc.png",
    ["1f9d1-1f3fc-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f527.png",
    ["1f9d1-1f3fc-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f52c.png",
    ["1f9d1-1f3fc-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f680.png",
    ["1f9d1-1f3fc-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f692.png",
    ["1f9d1-1f3fc-1f91d-1f9d1-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f91d-1f9d1-1f3fb.png",
    ["1f9d1-1f3fc-1f91d-1f9d1-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f91d-1f9d1-1f3fc.png",
    ["1f9d1-1f3fc-1f91d-1f9d1-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f91d-1f9d1-1f3fd.png",
    ["1f9d1-1f3fc-1f91d-1f9d1-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f91d-1f9d1-1f3fe.png",
    ["1f9d1-1f3fc-1f91d-1f9d1-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f91d-1f9d1-1f3ff.png",
    ["1f9d1-1f3fc-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f9af.png",
    ["1f9d1-1f3fc-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f9b0.png",
    ["1f9d1-1f3fc-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f9b1.png",
    ["1f9d1-1f3fc-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f9b2.png",
    ["1f9d1-1f3fc-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f9b3.png",
    ["1f9d1-1f3fc-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f9bc.png",
    ["1f9d1-1f3fc-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-1f9bd.png",
    ["1f9d1-1f3fc-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-2695.png",
    ["1f9d1-1f3fc-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-2696.png",
    ["1f9d1-1f3fc-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-2708.png",
    ["1f9d1-1f3fc-2764-1f48b-1f9d1-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-2764-1f48b-1f9d1-1f3fb.png",
    ["1f9d1-1f3fc-2764-1f48b-1f9d1-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-2764-1f48b-1f9d1-1f3fd.png",
    ["1f9d1-1f3fc-2764-1f48b-1f9d1-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-2764-1f48b-1f9d1-1f3fe.png",
    ["1f9d1-1f3fc-2764-1f48b-1f9d1-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-2764-1f48b-1f9d1-1f3ff.png",
    ["1f9d1-1f3fc-2764-1f9d1-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-2764-1f9d1-1f3fb.png",
    ["1f9d1-1f3fc-2764-1f9d1-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-2764-1f9d1-1f3fd.png",
    ["1f9d1-1f3fc-2764-1f9d1-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-2764-1f9d1-1f3fe.png",
    ["1f9d1-1f3fc-2764-1f9d1-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc-2764-1f9d1-1f3ff.png",
    ["1f9d1-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fc.png",
    ["1f9d1-1f3fd-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f33e.png",
    ["1f9d1-1f3fd-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f373.png",
    ["1f9d1-1f3fd-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f37c.png",
    ["1f9d1-1f3fd-1f384.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f384.png",
    ["1f9d1-1f3fd-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f393.png",
    ["1f9d1-1f3fd-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f3a4.png",
    ["1f9d1-1f3fd-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f3a8.png",
    ["1f9d1-1f3fd-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f3eb.png",
    ["1f9d1-1f3fd-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f3ed.png",
    ["1f9d1-1f3fd-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f4bb.png",
    ["1f9d1-1f3fd-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f4bc.png",
    ["1f9d1-1f3fd-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f527.png",
    ["1f9d1-1f3fd-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f52c.png",
    ["1f9d1-1f3fd-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f680.png",
    ["1f9d1-1f3fd-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f692.png",
    ["1f9d1-1f3fd-1f91d-1f9d1-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f91d-1f9d1-1f3fb.png",
    ["1f9d1-1f3fd-1f91d-1f9d1-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f91d-1f9d1-1f3fc.png",
    ["1f9d1-1f3fd-1f91d-1f9d1-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f91d-1f9d1-1f3fd.png",
    ["1f9d1-1f3fd-1f91d-1f9d1-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f91d-1f9d1-1f3fe.png",
    ["1f9d1-1f3fd-1f91d-1f9d1-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f91d-1f9d1-1f3ff.png",
    ["1f9d1-1f3fd-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f9af.png",
    ["1f9d1-1f3fd-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f9b0.png",
    ["1f9d1-1f3fd-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f9b1.png",
    ["1f9d1-1f3fd-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f9b2.png",
    ["1f9d1-1f3fd-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f9b3.png",
    ["1f9d1-1f3fd-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f9bc.png",
    ["1f9d1-1f3fd-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-1f9bd.png",
    ["1f9d1-1f3fd-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-2695.png",
    ["1f9d1-1f3fd-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-2696.png",
    ["1f9d1-1f3fd-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-2708.png",
    ["1f9d1-1f3fd-2764-1f48b-1f9d1-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-2764-1f48b-1f9d1-1f3fb.png",
    ["1f9d1-1f3fd-2764-1f48b-1f9d1-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-2764-1f48b-1f9d1-1f3fc.png",
    ["1f9d1-1f3fd-2764-1f48b-1f9d1-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-2764-1f48b-1f9d1-1f3fe.png",
    ["1f9d1-1f3fd-2764-1f48b-1f9d1-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-2764-1f48b-1f9d1-1f3ff.png",
    ["1f9d1-1f3fd-2764-1f9d1-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-2764-1f9d1-1f3fb.png",
    ["1f9d1-1f3fd-2764-1f9d1-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-2764-1f9d1-1f3fc.png",
    ["1f9d1-1f3fd-2764-1f9d1-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-2764-1f9d1-1f3fe.png",
    ["1f9d1-1f3fd-2764-1f9d1-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd-2764-1f9d1-1f3ff.png",
    ["1f9d1-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fd.png",
    ["1f9d1-1f3fe-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f33e.png",
    ["1f9d1-1f3fe-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f373.png",
    ["1f9d1-1f3fe-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f37c.png",
    ["1f9d1-1f3fe-1f384.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f384.png",
    ["1f9d1-1f3fe-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f393.png",
    ["1f9d1-1f3fe-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f3a4.png",
    ["1f9d1-1f3fe-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f3a8.png",
    ["1f9d1-1f3fe-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f3eb.png",
    ["1f9d1-1f3fe-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f3ed.png",
    ["1f9d1-1f3fe-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f4bb.png",
    ["1f9d1-1f3fe-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f4bc.png",
    ["1f9d1-1f3fe-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f527.png",
    ["1f9d1-1f3fe-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f52c.png",
    ["1f9d1-1f3fe-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f680.png",
    ["1f9d1-1f3fe-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f692.png",
    ["1f9d1-1f3fe-1f91d-1f9d1-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f91d-1f9d1-1f3fb.png",
    ["1f9d1-1f3fe-1f91d-1f9d1-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f91d-1f9d1-1f3fc.png",
    ["1f9d1-1f3fe-1f91d-1f9d1-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f91d-1f9d1-1f3fd.png",
    ["1f9d1-1f3fe-1f91d-1f9d1-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f91d-1f9d1-1f3fe.png",
    ["1f9d1-1f3fe-1f91d-1f9d1-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f91d-1f9d1-1f3ff.png",
    ["1f9d1-1f3fe-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f9af.png",
    ["1f9d1-1f3fe-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f9b0.png",
    ["1f9d1-1f3fe-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f9b1.png",
    ["1f9d1-1f3fe-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f9b2.png",
    ["1f9d1-1f3fe-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f9b3.png",
    ["1f9d1-1f3fe-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f9bc.png",
    ["1f9d1-1f3fe-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-1f9bd.png",
    ["1f9d1-1f3fe-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-2695.png",
    ["1f9d1-1f3fe-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-2696.png",
    ["1f9d1-1f3fe-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-2708.png",
    ["1f9d1-1f3fe-2764-1f48b-1f9d1-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-2764-1f48b-1f9d1-1f3fb.png",
    ["1f9d1-1f3fe-2764-1f48b-1f9d1-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-2764-1f48b-1f9d1-1f3fc.png",
    ["1f9d1-1f3fe-2764-1f48b-1f9d1-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-2764-1f48b-1f9d1-1f3fd.png",
    ["1f9d1-1f3fe-2764-1f48b-1f9d1-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-2764-1f48b-1f9d1-1f3ff.png",
    ["1f9d1-1f3fe-2764-1f9d1-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-2764-1f9d1-1f3fb.png",
    ["1f9d1-1f3fe-2764-1f9d1-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-2764-1f9d1-1f3fc.png",
    ["1f9d1-1f3fe-2764-1f9d1-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-2764-1f9d1-1f3fd.png",
    ["1f9d1-1f3fe-2764-1f9d1-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe-2764-1f9d1-1f3ff.png",
    ["1f9d1-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3fe.png",
    ["1f9d1-1f3ff-1f33e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f33e.png",
    ["1f9d1-1f3ff-1f373.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f373.png",
    ["1f9d1-1f3ff-1f37c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f37c.png",
    ["1f9d1-1f3ff-1f384.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f384.png",
    ["1f9d1-1f3ff-1f393.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f393.png",
    ["1f9d1-1f3ff-1f3a4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f3a4.png",
    ["1f9d1-1f3ff-1f3a8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f3a8.png",
    ["1f9d1-1f3ff-1f3eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f3eb.png",
    ["1f9d1-1f3ff-1f3ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f3ed.png",
    ["1f9d1-1f3ff-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f4bb.png",
    ["1f9d1-1f3ff-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f4bc.png",
    ["1f9d1-1f3ff-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f527.png",
    ["1f9d1-1f3ff-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f52c.png",
    ["1f9d1-1f3ff-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f680.png",
    ["1f9d1-1f3ff-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f692.png",
    ["1f9d1-1f3ff-1f91d-1f9d1-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f91d-1f9d1-1f3fb.png",
    ["1f9d1-1f3ff-1f91d-1f9d1-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f91d-1f9d1-1f3fc.png",
    ["1f9d1-1f3ff-1f91d-1f9d1-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f91d-1f9d1-1f3fd.png",
    ["1f9d1-1f3ff-1f91d-1f9d1-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f91d-1f9d1-1f3fe.png",
    ["1f9d1-1f3ff-1f91d-1f9d1-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f91d-1f9d1-1f3ff.png",
    ["1f9d1-1f3ff-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f9af.png",
    ["1f9d1-1f3ff-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f9b0.png",
    ["1f9d1-1f3ff-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f9b1.png",
    ["1f9d1-1f3ff-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f9b2.png",
    ["1f9d1-1f3ff-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f9b3.png",
    ["1f9d1-1f3ff-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f9bc.png",
    ["1f9d1-1f3ff-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-1f9bd.png",
    ["1f9d1-1f3ff-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-2695.png",
    ["1f9d1-1f3ff-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-2696.png",
    ["1f9d1-1f3ff-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-2708.png",
    ["1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fb.png",
    ["1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fc.png",
    ["1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fd.png",
    ["1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-2764-1f48b-1f9d1-1f3fe.png",
    ["1f9d1-1f3ff-2764-1f9d1-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-2764-1f9d1-1f3fb.png",
    ["1f9d1-1f3ff-2764-1f9d1-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-2764-1f9d1-1f3fc.png",
    ["1f9d1-1f3ff-2764-1f9d1-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-2764-1f9d1-1f3fd.png",
    ["1f9d1-1f3ff-2764-1f9d1-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff-2764-1f9d1-1f3fe.png",
    ["1f9d1-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f3ff.png",
    ["1f9d1-1f4bb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f4bb.png",
    ["1f9d1-1f4bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f4bc.png",
    ["1f9d1-1f527.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f527.png",
    ["1f9d1-1f52c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f52c.png",
    ["1f9d1-1f680.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f680.png",
    ["1f9d1-1f692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f692.png",
    ["1f9d1-1f91d-1f9d1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f91d-1f9d1.png",
    ["1f9d1-1f9af.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f9af.png",
    ["1f9d1-1f9b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f9b0.png",
    ["1f9d1-1f9b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f9b1.png",
    ["1f9d1-1f9b2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f9b2.png",
    ["1f9d1-1f9b3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f9b3.png",
    ["1f9d1-1f9bc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f9bc.png",
    ["1f9d1-1f9bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-1f9bd.png",
    ["1f9d1-2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-2695.png",
    ["1f9d1-2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-2696.png",
    ["1f9d1-2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1-2708.png",
    ["1f9d1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d1.png",
    ["1f9d2-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d2-1f3fb.png",
    ["1f9d2-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d2-1f3fc.png",
    ["1f9d2-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d2-1f3fd.png",
    ["1f9d2-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d2-1f3fe.png",
    ["1f9d2-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d2-1f3ff.png",
    ["1f9d2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d2.png",
    ["1f9d3-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d3-1f3fb.png",
    ["1f9d3-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d3-1f3fc.png",
    ["1f9d3-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d3-1f3fd.png",
    ["1f9d3-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d3-1f3fe.png",
    ["1f9d3-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d3-1f3ff.png",
    ["1f9d3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d3.png",
    ["1f9d4-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3fb-2640.png",
    ["1f9d4-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3fb-2642.png",
    ["1f9d4-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3fb.png",
    ["1f9d4-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3fc-2640.png",
    ["1f9d4-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3fc-2642.png",
    ["1f9d4-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3fc.png",
    ["1f9d4-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3fd-2640.png",
    ["1f9d4-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3fd-2642.png",
    ["1f9d4-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3fd.png",
    ["1f9d4-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3fe-2640.png",
    ["1f9d4-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3fe-2642.png",
    ["1f9d4-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3fe.png",
    ["1f9d4-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3ff-2640.png",
    ["1f9d4-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3ff-2642.png",
    ["1f9d4-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-1f3ff.png",
    ["1f9d4-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-2640.png",
    ["1f9d4-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4-2642.png",
    ["1f9d4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d4.png",
    ["1f9d5-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d5-1f3fb.png",
    ["1f9d5-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d5-1f3fc.png",
    ["1f9d5-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d5-1f3fd.png",
    ["1f9d5-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d5-1f3fe.png",
    ["1f9d5-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d5-1f3ff.png",
    ["1f9d5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d5.png",
    ["1f9d6-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3fb-2640.png",
    ["1f9d6-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3fb-2642.png",
    ["1f9d6-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3fb.png",
    ["1f9d6-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3fc-2640.png",
    ["1f9d6-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3fc-2642.png",
    ["1f9d6-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3fc.png",
    ["1f9d6-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3fd-2640.png",
    ["1f9d6-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3fd-2642.png",
    ["1f9d6-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3fd.png",
    ["1f9d6-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3fe-2640.png",
    ["1f9d6-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3fe-2642.png",
    ["1f9d6-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3fe.png",
    ["1f9d6-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3ff-2640.png",
    ["1f9d6-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3ff-2642.png",
    ["1f9d6-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-1f3ff.png",
    ["1f9d6-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-2640.png",
    ["1f9d6-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6-2642.png",
    ["1f9d6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d6.png",
    ["1f9d7-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3fb-2640.png",
    ["1f9d7-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3fb-2642.png",
    ["1f9d7-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3fb.png",
    ["1f9d7-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3fc-2640.png",
    ["1f9d7-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3fc-2642.png",
    ["1f9d7-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3fc.png",
    ["1f9d7-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3fd-2640.png",
    ["1f9d7-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3fd-2642.png",
    ["1f9d7-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3fd.png",
    ["1f9d7-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3fe-2640.png",
    ["1f9d7-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3fe-2642.png",
    ["1f9d7-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3fe.png",
    ["1f9d7-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3ff-2640.png",
    ["1f9d7-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3ff-2642.png",
    ["1f9d7-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-1f3ff.png",
    ["1f9d7-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-2640.png",
    ["1f9d7-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7-2642.png",
    ["1f9d7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d7.png",
    ["1f9d8-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3fb-2640.png",
    ["1f9d8-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3fb-2642.png",
    ["1f9d8-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3fb.png",
    ["1f9d8-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3fc-2640.png",
    ["1f9d8-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3fc-2642.png",
    ["1f9d8-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3fc.png",
    ["1f9d8-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3fd-2640.png",
    ["1f9d8-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3fd-2642.png",
    ["1f9d8-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3fd.png",
    ["1f9d8-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3fe-2640.png",
    ["1f9d8-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3fe-2642.png",
    ["1f9d8-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3fe.png",
    ["1f9d8-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3ff-2640.png",
    ["1f9d8-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3ff-2642.png",
    ["1f9d8-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-1f3ff.png",
    ["1f9d8-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-2640.png",
    ["1f9d8-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8-2642.png",
    ["1f9d8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d8.png",
    ["1f9d9-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3fb-2640.png",
    ["1f9d9-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3fb-2642.png",
    ["1f9d9-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3fb.png",
    ["1f9d9-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3fc-2640.png",
    ["1f9d9-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3fc-2642.png",
    ["1f9d9-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3fc.png",
    ["1f9d9-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3fd-2640.png",
    ["1f9d9-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3fd-2642.png",
    ["1f9d9-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3fd.png",
    ["1f9d9-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3fe-2640.png",
    ["1f9d9-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3fe-2642.png",
    ["1f9d9-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3fe.png",
    ["1f9d9-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3ff-2640.png",
    ["1f9d9-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3ff-2642.png",
    ["1f9d9-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-1f3ff.png",
    ["1f9d9-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-2640.png",
    ["1f9d9-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9-2642.png",
    ["1f9d9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9d9.png",
    ["1f9da-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3fb-2640.png",
    ["1f9da-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3fb-2642.png",
    ["1f9da-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3fb.png",
    ["1f9da-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3fc-2640.png",
    ["1f9da-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3fc-2642.png",
    ["1f9da-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3fc.png",
    ["1f9da-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3fd-2640.png",
    ["1f9da-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3fd-2642.png",
    ["1f9da-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3fd.png",
    ["1f9da-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3fe-2640.png",
    ["1f9da-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3fe-2642.png",
    ["1f9da-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3fe.png",
    ["1f9da-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3ff-2640.png",
    ["1f9da-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3ff-2642.png",
    ["1f9da-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-1f3ff.png",
    ["1f9da-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-2640.png",
    ["1f9da-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da-2642.png",
    ["1f9da.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9da.png",
    ["1f9db-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3fb-2640.png",
    ["1f9db-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3fb-2642.png",
    ["1f9db-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3fb.png",
    ["1f9db-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3fc-2640.png",
    ["1f9db-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3fc-2642.png",
    ["1f9db-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3fc.png",
    ["1f9db-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3fd-2640.png",
    ["1f9db-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3fd-2642.png",
    ["1f9db-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3fd.png",
    ["1f9db-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3fe-2640.png",
    ["1f9db-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3fe-2642.png",
    ["1f9db-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3fe.png",
    ["1f9db-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3ff-2640.png",
    ["1f9db-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3ff-2642.png",
    ["1f9db-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-1f3ff.png",
    ["1f9db-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-2640.png",
    ["1f9db-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db-2642.png",
    ["1f9db.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9db.png",
    ["1f9dc-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3fb-2640.png",
    ["1f9dc-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3fb-2642.png",
    ["1f9dc-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3fb.png",
    ["1f9dc-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3fc-2640.png",
    ["1f9dc-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3fc-2642.png",
    ["1f9dc-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3fc.png",
    ["1f9dc-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3fd-2640.png",
    ["1f9dc-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3fd-2642.png",
    ["1f9dc-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3fd.png",
    ["1f9dc-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3fe-2640.png",
    ["1f9dc-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3fe-2642.png",
    ["1f9dc-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3fe.png",
    ["1f9dc-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3ff-2640.png",
    ["1f9dc-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3ff-2642.png",
    ["1f9dc-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-1f3ff.png",
    ["1f9dc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-2640.png",
    ["1f9dc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc-2642.png",
    ["1f9dc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dc.png",
    ["1f9dd-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3fb-2640.png",
    ["1f9dd-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3fb-2642.png",
    ["1f9dd-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3fb.png",
    ["1f9dd-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3fc-2640.png",
    ["1f9dd-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3fc-2642.png",
    ["1f9dd-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3fc.png",
    ["1f9dd-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3fd-2640.png",
    ["1f9dd-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3fd-2642.png",
    ["1f9dd-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3fd.png",
    ["1f9dd-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3fe-2640.png",
    ["1f9dd-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3fe-2642.png",
    ["1f9dd-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3fe.png",
    ["1f9dd-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3ff-2640.png",
    ["1f9dd-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3ff-2642.png",
    ["1f9dd-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-1f3ff.png",
    ["1f9dd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-2640.png",
    ["1f9dd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd-2642.png",
    ["1f9dd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9dd.png",
    ["1f9de-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9de-2640.png",
    ["1f9de-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9de-2642.png",
    ["1f9de.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9de.png",
    ["1f9df-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9df-2640.png",
    ["1f9df-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9df-2642.png",
    ["1f9df.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9df.png",
    ["1f9e0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9e0.png",
    ["1f9e1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9e1.png",
    ["1f9e2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9e2.png",
    ["1f9e3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9e3.png",
    ["1f9e4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9e4.png",
    ["1f9e5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9e5.png",
    ["1f9e6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9e6.png",
    ["1f9e7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9e7.png",
    ["1f9e8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9e8.png",
    ["1f9e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9e9.png",
    ["1f9ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ea.png",
    ["1f9eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9eb.png",
    ["1f9ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ec.png",
    ["1f9ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ed.png",
    ["1f9ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ee.png",
    ["1f9ef.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ef.png",
    ["1f9f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9f0.png",
    ["1f9f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9f1.png",
    ["1f9f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9f2.png",
    ["1f9f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9f3.png",
    ["1f9f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9f4.png",
    ["1f9f5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9f5.png",
    ["1f9f6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9f6.png",
    ["1f9f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9f7.png",
    ["1f9f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9f8.png",
    ["1f9f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9f9.png",
    ["1f9fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9fa.png",
    ["1f9fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9fb.png",
    ["1f9fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9fc.png",
    ["1f9fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9fd.png",
    ["1f9fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9fe.png",
    ["1f9ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1f9ff.png",
    ["1fa70.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa70.png",
    ["1fa71.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa71.png",
    ["1fa72.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa72.png",
    ["1fa73.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa73.png",
    ["1fa74.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa74.png",
    ["1fa78.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa78.png",
    ["1fa79.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa79.png",
    ["1fa7a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa7a.png",
    ["1fa80.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa80.png",
    ["1fa81.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa81.png",
    ["1fa82.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa82.png",
    ["1fa83.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa83.png",
    ["1fa84.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa84.png",
    ["1fa85.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa85.png",
    ["1fa86.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa86.png",
    ["1fa90.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa90.png",
    ["1fa91.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa91.png",
    ["1fa92.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa92.png",
    ["1fa93.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa93.png",
    ["1fa94.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa94.png",
    ["1fa95.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa95.png",
    ["1fa96.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa96.png",
    ["1fa97.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa97.png",
    ["1fa98.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa98.png",
    ["1fa99.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa99.png",
    ["1fa9a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa9a.png",
    ["1fa9b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa9b.png",
    ["1fa9c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa9c.png",
    ["1fa9d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa9d.png",
    ["1fa9e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa9e.png",
    ["1fa9f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fa9f.png",
    ["1faa0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1faa0.png",
    ["1faa1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1faa1.png",
    ["1faa2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1faa2.png",
    ["1faa3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1faa3.png",
    ["1faa4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1faa4.png",
    ["1faa5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1faa5.png",
    ["1faa6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1faa6.png",
    ["1faa7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1faa7.png",
    ["1faa8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1faa8.png",
    ["1fab0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fab0.png",
    ["1fab1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fab1.png",
    ["1fab2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fab2.png",
    ["1fab3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fab3.png",
    ["1fab4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fab4.png",
    ["1fab5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fab5.png",
    ["1fab6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fab6.png",
    ["1fac0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fac0.png",
    ["1fac1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fac1.png",
    ["1fac2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fac2.png",
    ["1fad0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fad0.png",
    ["1fad1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fad1.png",
    ["1fad2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fad2.png",
    ["1fad3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fad3.png",
    ["1fad4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fad4.png",
    ["1fad5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fad5.png",
    ["1fad6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/1fad6.png",
    ["203c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/203c.png",
    ["2049.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2049.png",
    ["2122.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2122.png",
    ["2139.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2139.png",
    ["2194.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2194.png",
    ["2195.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2195.png",
    ["2196.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2196.png",
    ["2197.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2197.png",
    ["2198.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2198.png",
    ["2199.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2199.png",
    ["21a9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/21a9.png",
    ["21aa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/21aa.png",
    ["231a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/231a.png",
    ["231b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/231b.png",
    ["2328.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2328.png",
    ["23cf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23cf.png",
    ["23e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23e9.png",
    ["23ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23ea.png",
    ["23eb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23eb.png",
    ["23ec.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23ec.png",
    ["23ed.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23ed.png",
    ["23ee.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23ee.png",
    ["23ef.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23ef.png",
    ["23f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23f0.png",
    ["23f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23f1.png",
    ["23f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23f2.png",
    ["23f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23f3.png",
    ["23f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23f8.png",
    ["23f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23f9.png",
    ["23fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/23fa.png",
    ["24c2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/24c2.png",
    ["25aa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/25aa.png",
    ["25ab.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/25ab.png",
    ["25b6.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/25b6.png",
    ["25c0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/25c0.png",
    ["25fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/25fb.png",
    ["25fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/25fc.png",
    ["25fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/25fd.png",
    ["25fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/25fe.png",
    ["2600.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2600.png",
    ["2601.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2601.png",
    ["2602.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2602.png",
    ["2603.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2603.png",
    ["2604.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2604.png",
    ["260e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/260e.png",
    ["2611.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2611.png",
    ["2614.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2614.png",
    ["2615.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2615.png",
    ["2618.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2618.png",
    ["261d-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/261d-1f3fb.png",
    ["261d-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/261d-1f3fc.png",
    ["261d-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/261d-1f3fd.png",
    ["261d-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/261d-1f3fe.png",
    ["261d-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/261d-1f3ff.png",
    ["261d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/261d.png",
    ["2620.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2620.png",
    ["2622.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2622.png",
    ["2623.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2623.png",
    ["2626.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2626.png",
    ["262a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/262a.png",
    ["262e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/262e.png",
    ["262f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/262f.png",
    ["2638.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2638.png",
    ["2639.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2639.png",
    ["263a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/263a.png",
    ["2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2640.png",
    ["2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2642.png",
    ["2648.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2648.png",
    ["2649.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2649.png",
    ["264a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/264a.png",
    ["264b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/264b.png",
    ["264c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/264c.png",
    ["264d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/264d.png",
    ["264e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/264e.png",
    ["264f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/264f.png",
    ["2650.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2650.png",
    ["2651.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2651.png",
    ["2652.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2652.png",
    ["2653.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2653.png",
    ["265f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/265f.png",
    ["2660.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2660.png",
    ["2663.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2663.png",
    ["2665.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2665.png",
    ["2666.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2666.png",
    ["2668.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2668.png",
    ["267b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/267b.png",
    ["267e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/267e.png",
    ["267f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/267f.png",
    ["2692.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2692.png",
    ["2693.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2693.png",
    ["2694.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2694.png",
    ["2695.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2695.png",
    ["2696.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2696.png",
    ["2697.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2697.png",
    ["2699.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2699.png",
    ["269b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/269b.png",
    ["269c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/269c.png",
    ["26a0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26a0.png",
    ["26a1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26a1.png",
    ["26a7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26a7.png",
    ["26aa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26aa.png",
    ["26ab.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26ab.png",
    ["26b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26b0.png",
    ["26b1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26b1.png",
    ["26bd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26bd.png",
    ["26be.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26be.png",
    ["26c4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26c4.png",
    ["26c5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26c5.png",
    ["26c8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26c8.png",
    ["26ce.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26ce.png",
    ["26cf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26cf.png",
    ["26d1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26d1.png",
    ["26d3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26d3.png",
    ["26d4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26d4.png",
    ["26e9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26e9.png",
    ["26ea.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26ea.png",
    ["26f0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f0.png",
    ["26f1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f1.png",
    ["26f2.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f2.png",
    ["26f3.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f3.png",
    ["26f4.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f4.png",
    ["26f5.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f5.png",
    ["26f7.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f7.png",
    ["26f8.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f8.png",
    ["26f9-1f3fb-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3fb-2640.png",
    ["26f9-1f3fb-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3fb-2642.png",
    ["26f9-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3fb.png",
    ["26f9-1f3fc-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3fc-2640.png",
    ["26f9-1f3fc-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3fc-2642.png",
    ["26f9-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3fc.png",
    ["26f9-1f3fd-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3fd-2640.png",
    ["26f9-1f3fd-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3fd-2642.png",
    ["26f9-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3fd.png",
    ["26f9-1f3fe-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3fe-2640.png",
    ["26f9-1f3fe-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3fe-2642.png",
    ["26f9-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3fe.png",
    ["26f9-1f3ff-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3ff-2640.png",
    ["26f9-1f3ff-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3ff-2642.png",
    ["26f9-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-1f3ff.png",
    ["26f9-2640.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-2640.png",
    ["26f9-2642.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9-2642.png",
    ["26f9.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26f9.png",
    ["26fa.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26fa.png",
    ["26fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/26fd.png",
    ["2702.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2702.png",
    ["2705.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2705.png",
    ["2708.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2708.png",
    ["2709.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2709.png",
    ["270a-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270a-1f3fb.png",
    ["270a-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270a-1f3fc.png",
    ["270a-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270a-1f3fd.png",
    ["270a-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270a-1f3fe.png",
    ["270a-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270a-1f3ff.png",
    ["270a.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270a.png",
    ["270b-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270b-1f3fb.png",
    ["270b-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270b-1f3fc.png",
    ["270b-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270b-1f3fd.png",
    ["270b-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270b-1f3fe.png",
    ["270b-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270b-1f3ff.png",
    ["270b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270b.png",
    ["270c-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270c-1f3fb.png",
    ["270c-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270c-1f3fc.png",
    ["270c-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270c-1f3fd.png",
    ["270c-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270c-1f3fe.png",
    ["270c-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270c-1f3ff.png",
    ["270c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270c.png",
    ["270d-1f3fb.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270d-1f3fb.png",
    ["270d-1f3fc.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270d-1f3fc.png",
    ["270d-1f3fd.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270d-1f3fd.png",
    ["270d-1f3fe.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270d-1f3fe.png",
    ["270d-1f3ff.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270d-1f3ff.png",
    ["270d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270d.png",
    ["270f.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/270f.png",
    ["2712.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2712.png",
    ["2714.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2714.png",
    ["2716.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2716.png",
    ["271d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/271d.png",
    ["2721.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2721.png",
    ["2728.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2728.png",
    ["2733.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2733.png",
    ["2734.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2734.png",
    ["2744.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2744.png",
    ["2747.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2747.png",
    ["274c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/274c.png",
    ["274e.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/274e.png",
    ["2753.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2753.png",
    ["2754.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2754.png",
    ["2755.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2755.png",
    ["2757.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2757.png",
    ["2763.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2763.png",
    ["2764-1f525.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2764-1f525.png",
    ["2764-1fa79.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2764-1fa79.png",
    ["2764.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2764.png",
    ["2795.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2795.png",
    ["2796.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2796.png",
    ["2797.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2797.png",
    ["27a1.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/27a1.png",
    ["27b0.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/27b0.png",
    ["27bf.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/27bf.png",
    ["2934.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2934.png",
    ["2935.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2935.png",
    ["2b05.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2b05.png",
    ["2b06.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2b06.png",
    ["2b07.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2b07.png",
    ["2b1b.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2b1b.png",
    ["2b1c.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2b1c.png",
    ["2b50.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2b50.png",
    ["2b55.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/2b55.png",
    ["3030.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/3030.png",
    ["303d.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/303d.png",
    ["3297.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/3297.png",
    ["3299.png"]:
      "https://com-tc-dev-talkie-emojis.s3.eu-west-1.amazonaws.com/unicode/3299.png",
  },
};
